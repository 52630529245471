import styled from '@emotion/styled';
import { TabList } from '@mui/lab';
import { Box, Button, TextField, Tooltip } from '@mui/material';
import React from 'react';

export const TextBoxButton = styled(Button)`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #009569;
  background: #009569;
  color: #fff;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: #009569;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;

export const CustomTabList = styled(TabList)`
  & .MuiTabs-indicator {
    background-color: #f5f5f5;
  }

  & .MuiTab-root {
    color: #595d62;
  }

  & .MuiTab-root.Mui-selected {
    color: #f5f5f5;
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #484848;
  margin-left: 10px;
`;

export const PhotoContainer = styled.div`
  height: calc(100vh - 155px);
  overflow-y: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;


export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '4px',
    padding: '12px',
    boxShadow: theme.shadows[1],
  },
  '& .MuiTooltip-arrow': {
    color: '#fff',
  },
}));