import React, { useContext, useState } from 'react';
import { Container, GridBorder, Image } from './styles';
import { Box, Button, Grid, Stack, Switch, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { animateDirection, animateType, animationList } from './config';
import { Spacer } from 'components/shared';

import { StudioContext } from 'pages/studio';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const Animate = () => {
    const dispatch = useDispatch();
    const { selectedElement } = useSelector(state => state.Canvas.present);
    const [selectedAnimate, setSelectedAnimate] = useState('');
    const { setElementType } = useContext(StudioContext);
    const [animateValues, setAnimateValues] = useState({
        type: '',
        direction: '',
        loop: false,
    });

    const handleSelectAnimate = (item) => {
        setSelectedAnimate(item.name);
        dispatch(elementUpdated(selectedElement, item.name, 'add_animation'));
    };

    return (
        <Container>
            <Box display="flex" justifyContent={'space-between'}>
                <Typography>Animate</Typography>
                <CloseIcon onClick={() => setElementType('')} />
            </Box>
            <Spacer x={4} y={4} />
            <Grid container spacing={2} textAlign={'center'}>
                {
                    animationList.map((item, index) => (
                        <Grid item xs={4} key={index} onClick={() => handleSelectAnimate(item)}>
                            <Image width="90px" height="90px" isselected={selectedAnimate === item.name ? true : undefined} src={item.image} />
                            <Typography>{item.name}</Typography>
                        </Grid>
                    ))
                }
            </Grid>
            <Spacer x={4} y={4} />
            {/* {selectedAnimate && (
                <>
                    <Typography>Animate</Typography>
                    <Spacer x={2} y={2} />
                    <Grid container spacing={2}>
                        {animateType.map((item) => (
                            <Grid item xs={6} onClick={() => setAnimateValues((prev) => ({ ...prev, type: item.type }))}>
                                <GridBorder isSelected={item.type === animateValues.type}>
                                    {item.name}
                                </GridBorder>
                            </Grid>
                        ))}
                    </Grid>
                    <Spacer x={4} y={4} />
                    <Typography>Direction</Typography>
                    <Spacer x={2} y={2} />
                    <Grid container spacing={2}>
                        {animateDirection.map((item) => (
                            <Grid item xs={3} onClick={() => setAnimateValues((prev) => ({ ...prev, direction: item.type }))}>
                                <GridBorder isSelected={item.type === animateValues.direction}>
                                    {item.icon}
                                </GridBorder>
                            </Grid>
                        ))}
                    </Grid>
                    <Spacer x={4} y={4} />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography>Loop</Typography>
                        </Grid>
                        <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                            <Switch color="secondary" checked={animateValues.loop} onChange={() => setAnimateValues((prev) => ({ ...prev, loop: !prev.loop }))} />
                        </Grid>
                    </Grid>
                </>
            )} */}

        </Container>
    );
};

export default Animate;
