import React, { useContext } from 'react';
import { BoxContainer } from './style';
import { Box, Button, CircularProgress, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { elementUpdated, handleAddElement } from 'redux-layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StudioContext } from 'pages/studio';

const UploadImages = ({ title, subTitle, PhotosData, elementLoading, elementError, elementLoadMore, elementCount, elementType, setElementType }) => {
  const dispatch = useDispatch();
  const { imageType, setImageType } = useContext(StudioContext);
  const { selectedElement } = useSelector((state) => state.Canvas.present);

  const handleUploadImgAdd = (item) => {
    const { width, height, url: imageUrl } = item || {};
    if (imageType === 'frame' && selectedElement?.type === 'frame') {
      setImageType('');
      setElementType('');
      dispatch(elementUpdated(selectedElement, `${baseUrl}${imageUrl}`, 'zindex-src'));
    } else {
      const defaultImg = { ...appConfig.elemImg };
      const element = {
        ...defaultImg,
        id: newID('element'),
        content: {
          src: `${baseUrl}${imageUrl}`,
        },
        position: {
          ...defaultImg.position,
          height: (height / width) * 20,
          width: 20,
        },
      };
      dispatch(handleAddElement(element));
    }

  };

  return (
    <React.Fragment>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
          {title}
        </Typography>
        <Button variant="seeAll" onClick={() => setElementType('YOURUPLOADS')}>{subTitle}</Button>
      </Stack>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {PhotosData?.length === 0 ? (
                <Box sx={{ padding: '1rem' }}>
                  <Typography variant="body2" color="#fff">There is no uploaded images.</Typography>
                </Box>
              ) : PhotosData.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    {/* <BoxContainer> */}
                    <IconButton onClick={() => handleUploadImgAdd(item)}>
                      <img className="img-contain" src={`${baseUrl}${item?.url}`} alt={`${baseUrl}${item?.url}`} />
                    </IconButton>
                    {/* </BoxContainer> */}
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading && (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default UploadImages;
