import styled from '@emotion/styled';
import { Box, Button, TextField } from '@mui/material';

export const TextInput = styled(TextField)`
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  background: #fff;
  width: 92%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin-left: 13px;
`;

export const ScrollContainer = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #595D62;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #595D62;
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #484848;
  margin-left: 10px;
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;

export const GIFContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;