import { fetchData } from 'helpers/api.services';
import {
  COPY_ELEMENT,
  ELEMENT_ADD,
  ELEMENT_CHANGED,
  ELEMENT_DELETED,
  ELEMENT_UPDATED,
  PROJECT_ERROR,
  PROJECT_TITLE,
  PROJECT_UPDATED,
  REDO,
  SLIDE_ADDED,
  SLIDE_DELETED,
  SLIDE_SELECTION_CHANGED,
  SLIDE_UPDATED,
  SLIDE_REPLACE,
  UNDO,
  NEW_SLIDE_ADD,
  SLIDE_INDEX_UPDATED,
} from './constants';
import _ from 'lodash';
import { newID } from 'helpers/studioApi';
import { localStorageUtils, setHeightWidth } from 'helpers/utils';

export const projectInit = (projectid, projectName) => async (dispatch) => {
  let response;
  let project;
  try {
    if (projectid) {
      switch (projectName) {
        case 'template':
          response = await fetchData(`/templates/${projectid}`);
          project = response.data?.attributes.template;
          break;
        case 'theme':
          response = await fetchData(`/themes/${projectid}`);
          project = response.data?.attributes.theme;
          break;
        default:
          response = await fetchData(`/projects/${projectid}`);
          project = response.data?.attributes.project;
          break;
      }
    }
  } catch (error) {
    dispatch({
      type: PROJECT_ERROR,
      payload: error,
    });
  }
  const dimensions = {};
  let newSlide = {};
  let newProject = _.cloneDeep(project);
  const getSelectedSlide = JSON.parse(localStorageUtils.get('selected_slide'));
  if (getSelectedSlide?.project_id === parseInt(projectid)) {
    let isExistedSlide = newProject.slides.find((slide) => slide.id === getSelectedSlide.slide_id);
    if (isExistedSlide && 'id' in isExistedSlide) {
      newSlide = isExistedSlide;
    } else {
      newSlide = _.cloneDeep(project?.slides?.[0]);
    }
  } else {
    newSlide = _.cloneDeep(project?.slides?.[0]);
  }
  dispatch({
    type: PROJECT_UPDATED,
    payload: {
      project: newProject,
      slides: newProject?.slides,
      title: response?.data?.attributes?.title || '',
      elements: [],
      selectedSlide: newSlide,
      projectId: parseInt(projectid),
      lessonId: response?.data?.attributes?.lwLessonId || '',
      isPublished: response?.data?.attributes?.published?.data ? true : false,
      publishedId: response?.data?.attributes?.published?.data?.id || '',
      dimensions,
    },
  });
};

export const slideadded = (slide, index) => async (dispatch) => {
  dispatch({
    type: SLIDE_ADDED,
    payload: { slide, index },
  });
};

export const slideDeleted = () => async (dispatch) => {
  dispatch({
    type: SLIDE_DELETED,
  });
};

export const slideSelectionChanged = (selectedSlide) => async (dispatch, getState) => {
  const { projectId } = getState().Canvas.present;
  localStorageUtils.set(
    'selected_slide',
    JSON.stringify({
      slide_id: selectedSlide.id,
      project_id: projectId,
    })
  );
  dispatch({
    type: SLIDE_SELECTION_CHANGED,
    payload: {
      selectedSlide,
    },
  });
};

export const elementChanged = (show) => async (dispatch) => {
  dispatch({
    type: ELEMENT_CHANGED,
    payload: { show },
  });
};

export const elementUpdated =
  (element, value, propName, position = 0) =>
    async (dispatch, getState) => {
      const swapElements = (index1, index2) => {
        [slide.elements[index1], slide.elements[index2]] = [slide.elements[index2], slide.elements[index1]];
      };
      const { selectedSlide } = getState().Canvas.present;
      const slide = _.cloneDeep(selectedSlide);
      const elementIndex = _.findIndex(slide.elements, { id: element.id });
      switch (propName) {
        case 'text':
          slide.elements[elementIndex].content.text = value;
          break;
        case 'zindex-src':
          slide.elements[elementIndex].content.src = value;
          break;
        case 'z-index':
          switch (value) {
            case 'bring_forward':
              slide.elements.push(...slide.elements.splice(elementIndex, 1));
              break;
            case 'send_backward':
              slide.elements.unshift(...slide.elements.splice(elementIndex, 1));
              break;
            case 'bring_front':
              if (elementIndex + 1 < slide.elements.length) {
                swapElements(elementIndex, elementIndex + 1);
              }
              break;
            case 'send_back':
              if (elementIndex > 0) {
                swapElements(elementIndex, elementIndex - 1);
              }
              break;
            default:
              break;
          }

          slide.elements = slide.elements.map((item, id) => ({
            ...item,
            style: {
              ...item.style,
              'z-index': id + 1,
            },
          }));
          break;
        case 'font-family':
        case 'font-size':
        case 'text-align':
        case 'text-transform':
        case 'color':
        case 'backgroundColor':
        case 'transformRotate':
        case 'transformSkew':
        case 'line-height':
          slide.elements[elementIndex].style[propName] = value;
          break;
        case 'top':
        case 'left':
        case 'right':
        case 'bottom':
        case 'middle':
          slide.elements[elementIndex].position[propName] = value;
          break;
        case 'svgcolor':
          slide.elements[elementIndex].childrens[0]['fill'] = value;
          slide.elements[elementIndex].style['stroke'] = value;
          slide.elements[elementIndex].style['color'] = value;
          break;
        case 'add_animation':
          if (slide.elements[elementIndex].event?.length > 0 && value) {
            slide.elements[elementIndex].event[3].call = 'addClass';
            slide.elements[elementIndex].event[3].type = 'click';
            slide.elements[elementIndex].styleClasses = ['animate__animated', `animate__${value}`];
          }
          break;
        case 'callBehaviour':
          if (slide.elements[elementIndex].event?.length > 0 && value) {
            slide.elements[elementIndex].event[position].call = value;
          }
          break;
        case 'typeBehaviour':
          if (slide.elements[elementIndex].event?.length > 0 && value) {
            slide.elements[elementIndex].event[position].type = value;
          }

          break;
        case 'paramBehaviour':
          if (slide.elements[elementIndex].event?.length > 0 && value) {
            slide.elements[elementIndex].event[position].param = value;
          }
          break;
        case 'replace-image':
          slide.elements[elementIndex].content.src = value;
          break;
        case 'size':
          slide.elements[elementIndex].position.width = value.width;
          slide.elements[elementIndex].position.height = value.height;
          break;
        case 'ai-key':
          slide.elements[elementIndex].aiKey = value;
          break;
        default:
          break;
      }
      dispatch({
        type: ELEMENT_UPDATED,
        payload: { slide },
      });
    };

export const slideUpdated = (key, value) => async (dispatch, getState) => {
  const { selectedSlide } = getState().Canvas.present;
  const slide = _.cloneDeep(selectedSlide);
  switch (key) {
    case 'background-image':
      slide.style[key] = value;
      slide.style['background-color'] = '';
      slide.style['object-fit'] = 'cover';
      break;
    case 'background-color':
      slide.style['background-image'] = '';
      slide.style[key] = value;
      slide.style['object-fit'] = 'cover';
      break;
    default:
      break;
  }

  dispatch({
    type: SLIDE_UPDATED,
    payload: { slide },
  });
};

export const slideReplace = (getSlide) => async (dispatch, getState) => {
  const slide = _.cloneDeep(getSlide);
  // slide?.id = newID('slide');
  dispatch({
    type: SLIDE_REPLACE,
    payload: { slide },
  });
};

export const addNewSlide = (getSlide) => async (dispatch, getState) => {
  const slide = _.cloneDeep(getSlide);
  // slide?.id = newID('slide');
  dispatch({
    type: NEW_SLIDE_ADD,
    payload: { slide },
  });
};

export const handleAddElement = (element) => async (dispatch, getState) => {
  const { selectedSlide } = getState().Canvas.present;
  const slide = _.cloneDeep(selectedSlide);
  slide.elements.push(element);
  dispatch({
    type: ELEMENT_ADD,
    payload: { slide, element },
  });
};

export const handleRemoveElement = (element) => async (dispatch) => {
  dispatch({
    type: ELEMENT_DELETED,
    payload: { element },
  });
};

export const ProjectUpdate = (value) => async (dispatch) => {
  dispatch({
    type: PROJECT_TITLE,
    payload: { value },
  });
};

export const handleUndo = () => ({
  type: UNDO,
});

export const handleRedo = () => ({
  type: REDO,
});

export const copyElement = (element) => async (dispatch) => {
  dispatch({
    type: COPY_ELEMENT,
    payload: { element },
  });
};

export const pasteElement = () => async (dispatch, getState) => {
  const { selectedSlide } = getState().Canvas.present;
  const slide = _.cloneDeep(selectedSlide);
  const { copiedElement } = getState().Canvas.present;
  const element = _.cloneDeep(copiedElement);
  element.id = newID('element');
  slide.elements.push(element);
  dispatch({
    type: ELEMENT_ADD,
    payload: { slide, element },
  });
};

export const slideIndexChanged = (sourceIndex, destinationIndex) => async (dispatch) => {
  dispatch({
    type: SLIDE_INDEX_UPDATED,
    payload: { sourceIndex, destinationIndex },
  });
};
