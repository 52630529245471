import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { BoxContainer, ErrorMessage, ScrollContainer } from './styles';
import { baseUrl } from 'helpers/apiCalls';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { palette } from 'config/theme';
import Loader from 'pages/studio/Components/Loader';
import { handleAddElement } from 'redux-layout/actions';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { useDispatch } from 'react-redux';

const ButtonElement = ({ data, elementLoadMore, elementLoading, elementError, elementCount }) => {
  const dispatch = useDispatch();

  const buttonAdd = (item) => {
    const { width, height, url: imageUrl } = item || {};
    const defaultImg = { ...appConfig.elemImg };
    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        src: `${baseUrl}${imageUrl}`,
      },
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
    };
    dispatch(handleAddElement(element));
  };

  // ?.attributes?.asset?.data[0]?.attributes?.url
  return (
    <ScrollContainer>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {data.map((item, index) => {
                const imageUrl = item?.url;
                return (
                  <Grid item key={index} xs={6}>
                    <BoxContainer>
                      <IconButton onClick={() => buttonAdd(item)}>
                        <img className="img-contain" src={`${baseUrl}${imageUrl}`} alt={imageUrl} />
                      </IconButton>
                    </BoxContainer>
                  </Grid>
                );
              })}
            </Grid>

            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {data?.length === elementCount ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={elementLoadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </ScrollContainer>
  );
};

export default ButtonElement;
