import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { PhotoContainer } from './styles';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LifewiseLibrary from './LifewiseLibrary';
import UploadImages from './UploadImages';
import Upload from './Upload';
import ColorPicker from './ColoPicker';
import appConfig from 'config';
import { slideUpdated } from 'redux-layout/actions';
import { useDispatch } from 'react-redux';
import { newID } from 'helpers/studioApi';
import { baseUrl } from 'helpers/apiCalls';

const API_CONFIGS = {
  BACKGROUND: {
    upload: { api: 'assets', params: { contenttype: { $eq: 'background' }, type: { $eq: 'user' } } },
    background: { api: 'assets', params: { contenttype: { $eq: 'background' }, type: { $eq: 'system' } } },
  },
};

const Background = ({ elementType, setElementType }) => {
  const dispatch = useDispatch();
  const [buttonSearch, setButtonSearch] = useState('');

  const { api: apiUpload, params: apiUploadParams } = API_CONFIGS[elementType]?.upload || { api: '', params: {} };
  const { api: apiBackground, params: apiBackgroundParams } = API_CONFIGS[elementType]?.background || {
    api: '',
    params: {},
  };

  const uploadApi = usePaginatedApi(apiUpload, 4, apiUploadParams);
  const libraryApi = usePaginatedApi(apiBackground, 16, apiBackgroundParams);

  const handleBackgroundImgAdd = (item, index) => {
    console.log('🚀 ~ handleBackgroundImgAdd ~ item:', item);
    const url = 'url("' + baseUrl + (item?.url || '') + '")';
    dispatch(slideUpdated('background-image', url));
  };

  const clearSearch = () => {
    setButtonSearch('');
  };

  const handleSearch = (value) => {
    setButtonSearch(value);
  };



  return (
    <PhotoContainer>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ gap: '10px', p: 0 }}>
        <LeftNavSearch
          searchValue={buttonSearch}
          clearSearch={clearSearch}
          placeholder={'Search Backgrounds'}
          handleSearch={handleSearch}
        />
        <ColorPicker />
        <Upload refreshData={uploadApi.refreshData} />
        <UploadImages
          title={'Your uploads'}
          subTitle={'See all'}
          PhotosData={uploadApi.data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
          elementLoadMore={uploadApi.loadMore}
          elementLoading={uploadApi.loading}
          elementError={uploadApi.error}
          elementCount={uploadApi.count}
          elementType={elementType}
          setElementType={setElementType}
          handleBackgroundImgAdd={handleBackgroundImgAdd}
        />
        <LifewiseLibrary
          title={'LifeWise library'}
          PhotosData={libraryApi.data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
          elementLoadMore={libraryApi.loadMore}
          elementLoading={libraryApi.loading}
          elementError={libraryApi.error}
          elementCount={libraryApi.count}
          handleBackgroundImgAdd={handleBackgroundImgAdd}
        />
      </Stack>
    </PhotoContainer>
  );
};

export default Background;
