import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { palette } from 'config/theme';
import { ElementContainer } from './styles';
import ElementShapes from './elementShapes';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import Contents from './Contents';



const Elements = (props) => {
  const { elementType, setElementType } = props;
  const [elementsSearch, setElementsSearch] = useState('');

  const clearSearch = () => {
    setElementsSearch('');
  };

  const handleSearch = (value) => {
    setElementsSearch(value);
  };

  return (
    <ElementContainer>
      <LeftNavSearch
        searchValue={elementsSearch}
        handleSearch={handleSearch}
        placeholder={'Search Elements'}
        clearSearch={clearSearch}
      />
      <Contents
        elementsSearch={elementsSearch}
        key={elementsSearch}
      />
    </ElementContainer>
  );
};

export default Elements;
