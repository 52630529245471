import React, { useState } from 'react';
import { Container, Modal } from './styles';
import { useNavigate } from 'react-router-dom';
import { Stack, Tab, Tabs, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateManual from './CreateManual';
import CreateAi from './CreateAi';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Create = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(1);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Modal>
                <Stack direction="row" justifyContent={'space-between'} padding="1rem">
                    <Typography variant="h3">Create Lesson</Typography>
                    <CloseIcon onClick={() => navigate(-1)} />
                </Stack>
                <Tabs value={value} onChange={handleTabChange}>
                    <Tab sx={{ width: '50%' }} label="Create Manually" {...a11yProps(0)} />
                    <Tab sx={{ width: '50%' }} label="Create with AI" {...a11yProps(1)} />
                </Tabs>
                {value === 0 ? <CreateManual /> : <CreateAi />}

            </Modal>
        </Container>
    );
};

export default Create;
