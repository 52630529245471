import React, { useMemo, useRef } from 'react';
import { Rnd } from '../Rnd';
import { useDispatch } from 'react-redux';
import { elementChanged } from 'redux-layout/actions';
import { useStore } from 'Socket';
import images from 'config/images';

const Image = (props) => {
    const { el, index, POS, getElemStyle, getRNDPOS, handleContextMenu, commonRNDProps, isCanvas = false } = props;
    const dispatch = useDispatch();
    const { emit, events, } = useStore();
    const isImageSent = useRef(false);

    const imageSource = useMemo(() => {
        if (el.aiKey && el.aiKey === 'image') {
            if (isImageSent.current) {
                let image = events.find((event) =>
                    event?.event === 'pipeline:execute:success'
                    && JSON.parse(event?.payload)?.elemId === el.id);
                console.log('image', image);
                if (image) {
                    let updatedElement = {
                        ...el,
                        content: {
                            src: JSON.parse(image.payload).imageUrl,
                        },
                        aiKey: ''
                    };
                    dispatch(elementChanged(updatedElement));
                }
            } else {
                try {
                    let payload = {
                        'image': el?.imageDescription || '',
                        'theme': el?.themeName || '',
                        'elemId': el?.id,
                    };
                    emit(
                        'pipeline:execute',
                        JSON.stringify({
                            slug: 'genrate-image',
                            payload,
                        })
                    );
                    isImageSent.current = true;
                } catch (error) {
                    console.log('error', error);
                }
            }
            return images.studio.ai_image_loader.image;
        } else {
            return el.content?.src;
        }
    }, [el, events]);



    if (el.content.src) {
        return (
            <Rnd {...commonRNDProps(POS)}>
                <img
                    alt={el.name}
                    onClick={() => dispatch(elementChanged(el, 'img'))}
                    id={el.id}
                    style={{ ...getElemStyle(el, index, 'indiv', false), borderRadius: '1rem' }}
                    src={imageSource}
                />
            </Rnd>
        );
    } else {
        const { x, y } = getRNDPOS(el);
        return (
            <div
                className="image-placeholder"
                style={{
                    ...getElemStyle(el, index, 'all'),
                    transform: `translate(${x}px, ${y}px)`,
                    width: parseFloat(el.position.width) + '%',
                    height: parseFloat(el.position.height) + '%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                onContextMenu={(e) => handleContextMenu(e, el)}>
                <img
                    alt={el.name}
                    onClick={() => dispatch(elementChanged(el, 'img'))}
                    id={el.id}
                    style={{ ...getElemStyle(el, index, 'indiv', false), borderRadius: '1rem', objectFit: 'contain' }}
                    src={el.content.placeholder}
                />
            </div>
        );
    }
};

export default Image;
