import { Box, Stack, Typography, Tooltip, styled } from '@mui/material';
import React from 'react';
import { addElements } from './config';
import { CustomStack, CustomTooltip, Img, LeftElements } from './styles';
import { ComingSoonIcon } from 'config/svgs';

const LeftAddElements = ({ elementType, setElementType }) => {
  const handleSelect = (item) => {
    if (item.name !== 'Audio' && item.name !== 'Videos') {
      setElementType(item.route);
    }
  };

  return (
    <LeftElements>
      {addElements
        .filter((item) => !item?.name.includes('toolbar'))
        .map((item, index) => (
          <CustomStack
            direction="column"
            isselected={elementType === item.route ? true : undefined}
            width="100%"
            alignItems="center"
            spacing={2}
            key={index}
            padding="0.7rem 0"
            onClick={() => handleSelect(item)}
            style={{
              cursor: item.name === 'Audio' || item.name === 'Videos' ? 'pointer' : 'pointer',
              opacity: item.name === 'Audio' || item.name === 'Videos' ? 1 : 1,
            }}>
            <Stack direction="row" alignItems="center" position="relative">
              {item.name === 'Audio' || item.name === 'Videos' ? (
                <CustomTooltip title="Coming Soon" arrow>
                  <Box>
                    <Img src={item.icon} alt={item.name} />
                    <Box position="absolute" top={'-15px'} right={'-22px'}>
                      <ComingSoonIcon />
                    </Box>
                  </Box>
                </CustomTooltip>
              ) : (
                <Img src={item.icon} alt={item.name} />
              )}
            </Stack>

            <Typography variant="small" textAlign="center">
              {item.name}
            </Typography>
          </CustomStack>
        ))}
    </LeftElements>
  );
};

export default LeftAddElements;
