import { Box, Button, IconButton, Typography } from '@mui/material';
import { NotFoundEmoji } from 'config/svgs';
import { palette } from 'config/theme';
import React from 'react';
import { NotFoundContainer } from './style';

const PhotoNotFound = ({ title = '', handleClear = () => { } }) => {
    return (
        <NotFoundContainer>
            <IconButton>
                <NotFoundEmoji />
            </IconButton>
            <Typography variant="body2" color={palette.text.grey6}>
                {`No results found for “${title}”`}
            </Typography>
            <Button variant="backBtn" color={palette.text.grey6} onClick={handleClear} sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'underline' } }}>
                Clear search
            </Button>
        </NotFoundContainer>
    );
};

export default PhotoNotFound;
