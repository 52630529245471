import styled from '@emotion/styled';
import { List, ListItem, LinearProgress, Button } from '@mui/material';

export const UploadWrapper = styled('div')`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const FilesWrapper = styled(List)`
  margin-top: 10px !important;
  ${'' /* border: 1px solid rgba(0, 0, 0, 0.12); */}
  border-radius: 4px;
  padding: 0; /* Ensures padding is removed, matching disablePadding: true */
`;

export const FileItem = styled(ListItem)`
  position: relative;
  opacity: ${(props) => (props.loading ? 0.4 : 1)}; /* Directly use numeric opacity */
`;

export const Progress = styled(LinearProgress)`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
`;

export const AttachmentButton = styled(Button)`
  border: 1px solid #595d62;
  border-radius: 8px;
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px
`;

export const CustomButton = styled(Button)`
  background-color: #e2e2e2;
  border-radius: 4px;
  color: #000000;
  margin-right: 10px;
  padding: 10px;
`;