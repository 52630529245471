import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { BoxAlign, TopbarContainer, TopbarTab } from './styles';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
  const navigate = useNavigate();

  return (
    <TopbarContainer>
      <TopbarTab>
        <Button variant="activeTab">Library</Button>
        <Button variant="tab" onClick={() => navigate('/assets')}>
          Assets
        </Button>
      </TopbarTab>
      <BoxAlign>
        <Box>
          <Typography variant="body3">
            Choose from hundreds of templates designed specifically for children or build your own
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" color="secondary" onClick={() => navigate('/create')}>
            Create new
          </Button>
        </Box>
      </BoxAlign>
    </TopbarContainer>
  );
};

export default Topbar;
