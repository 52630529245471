import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PhotoContainer } from './style';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LifewiseLibrary from './LifewiseLibrary';
import UploadImages from './UploadImages';
import Upload from './Upload';
import { palette } from 'config/theme';
import { sortAssetList } from 'config/constants';
import CustomCarousel from './LibraryItem';

const API_CONFIGS = {
  PHOTOS: {
    upload: { api: 'assets', params: {} },
    library: { api: 'assets', params: { type: { $eq: 'system' } } },
  },
};

const Photos = ({ elementType, setElementType, email }) => {
  const [buttonSearch, setButtonSearch] = useState('');
  const [imageList, setImageList] = useState(sortAssetList);
  const { api: apiUpload, params: apiUploadParams } = API_CONFIGS[elementType]?.upload || { api: '', params: {} };
  const { api: apiLibrary, params: apiLibraryParams } = API_CONFIGS[elementType]?.library || { api: '', params: {} };

  const uploadApi = usePaginatedApi(apiUpload, 4, {
    ...apiUploadParams,
    username: { $eq: email }
  });
  const libraryApi = usePaginatedApi(apiLibrary, 16, apiLibraryParams);

  const handleSearch = (value) => {
    libraryApi.searchItems(value, 'name');
    setButtonSearch(value);
    let filteredList = sortAssetList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    setImageList(filteredList);
  };

  const clearSearch = () => {
    setButtonSearch('');
    setImageList(sortAssetList);
  };

  return (
    <PhotoContainer>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ gap: '10px', p: 0 }}>
        <LeftNavSearch
          searchValue={buttonSearch}
          handleSearch={handleSearch}
          placeholder={'Search Photos'}
          clearSearch={clearSearch}
        />
        <Upload refreshData={uploadApi.refreshData} />
        <UploadImages
          title={'Your uploads'}
          subTitle={'See all'}
          PhotosData={uploadApi.data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
          elementLoadMore={uploadApi.loadMore}
          elementLoading={uploadApi.loading}
          elementError={uploadApi.error}
          elementCount={uploadApi.count}
          elementType={elementType}
          setElementType={setElementType}
        />
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
            {'LifeWise library'}
          </Typography>
        </Stack>
        {imageList.length !== 0 && (
          <Box sx={{ width: '100%' }}>
            <CustomCarousel
              items={imageList}
            />
          </Box>
        )}
        <LifewiseLibrary
          noTitle={true}
          title={'LifeWise library'}
          PhotosData={libraryApi.data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
          elementLoadMore={libraryApi.loadMore}
          elementLoading={libraryApi.loading}
          elementError={libraryApi.error}
          elementCount={libraryApi.count}
        />
      </Stack>
    </PhotoContainer>
  );
};

export default Photos;
