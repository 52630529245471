import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logoutUser } from 'redux-layout/actions';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import { useStore } from 'Socket';

const Logout = (props) => {
    const dispatch = useDispatch();
    const { disconnect } = useStore();
    useEffect(() => {
        console.log('THIS PROPS', props);

        let path = 'app' + props?.location?.pathname;
        ReactGA.pageview(path);
        disconnect();
        dispatch(logoutUser(null));
    }, []);

    return (
        <React.Fragment>
            <Navigate to="/account/login" replace={true} />
        </React.Fragment>
    );
};

export default Logout;
