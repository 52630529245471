import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

const ResponsiveBox = ({ children }) => {
    // Define breakpoints
    const isXs = useMediaQuery('(max-width:600px)');
    const isSm = useMediaQuery('(min-width:600px) and (max-width:960px)');
    const isMd = useMediaQuery('(min-width:960px) and (max-width:1280px)');
    const isLg = useMediaQuery('(min-width:1280px) and (max-width:1920px)');
    const isXl = useMediaQuery('(min-width:1920px)');

    // Determine the width based on the breakpoints
    const getWidth = () => {
        if (isXs) { return '100%'; }
        if (isSm) { return '80%'; }
        if (isMd) { return '60%'; }
        if (isLg) { return '50%'; }
        if (isXl) { return '40%'; }
        return '100%';
    };

    return (
        <Box
            sx={{
                width: getWidth(),
                margin: '0 auto',
                padding: '16px',
                boxSizing: 'border-box',
            }}
        >
            {children}
        </Box>
    );
};

export default ResponsiveBox;
