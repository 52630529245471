import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import ReactGA from 'react-ga';
import { configureFakeBackend } from 'helpers';
import { isUserAuthenticated } from 'helpers/authUtils';
import { Provider } from 'react-redux';
import { configureStore } from 'redux-layout/store';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from 'config/theme';
import 'css/app.css';
import 'react-contexify/ReactContexify.css';
import { SocketProvider } from 'Socket';


ReactGA.initialize('UA-171648221-1', {
  debug: false,
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

// configure fake backend
configureFakeBackend();

const store = configureStore();

const App = () => {
  const [isAuthorized] = useState(isUserAuthenticated());

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SocketProvider>
            <BrowserRouter>
              <Routes isAuthorized={isAuthorized} />
            </BrowserRouter>
          </SocketProvider>
        </Provider>
      </ThemeProvider>

    </StyledEngineProvider>
  );
};
export default App;
