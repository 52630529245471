import styled from '@emotion/styled';
import { Box, Button, Switch, TextField } from '@mui/material';
import { palette } from 'config/theme';

export const Container = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  background: #ffffff
    ${
      '' /* &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  } */
    };
`;

export const CustomButton = styled(Button)`
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 999px;
  border: 1px solid #595d62;
  background: transparent;
  color: #000;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const ParagraphBox = styled(Box)`
  width: 100%;
  background: #f5f5f5;
  border-radius: 12px;
  height: 45px;
`;

export const ParagraphBoxButton = styled(Button)`
  background: ${({ active }) => (active ? '#FFF' : '#f5f5f5')};
  border-radius: 8px;
  padding: 6px 30px;
  transition: background-color 0.3s ease;
  ${
    '' /* &:hover {
    background: ${({ active }) => (active ? '#E0E0E0' : '#F9F9F9')};
  } */
  }
`;

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 6,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#FFFFFF',
      '& + .MuiSwitch-track': {
        backgroundColor: '#139569',
        opacity: 1,
        // border: '2px solid #139569',
      },
      '& .MuiSwitch-thumb': {
        color: '#FFFFFF',
        border: 'none',
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#E2E2E2',
        opacity: 1,
        border: '2px solid #E2E2E2',
      },
      '& .MuiSwitch-thumb': {
        color: '#FFFFFF',
        border: 'none',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    color: '#FFFFFF',
    borderRadius: 12,
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: '#E2E2E2',
    // border: '2px solid #79747E',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export const TextInput = styled(TextField)`
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;

  .MuiInputBase-input {
    height: 0px;
    min-height: 79px;
    padding: 8px 12px;
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
`;

export const PreviewBox = styled(Box)`
  width: 100%;
  aspect-ratio: 16 / 9;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${'' /* text-align: center; */}
`;
