import React, { useState } from 'react';
import ButtonElement from './ButtonElement';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import { ButtonContainer } from './styles';
import { palette } from 'config/theme';
import { Typography } from '@mui/material';

const Buttons = (props) => {
  const { elementType, setElementType } = props;
  const [buttonSearch, setButtonSearch] = useState('');

  const elementConfigs = {
    BUTTONS: { api: 'assets', params: { contenttype: { $eq: 'button' } } },
  };
  const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };
  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams);
  const handleSearch = (value) => {
    searchItems(value, 'name');
    setButtonSearch(value);
  };
  const handleBack = () => {
    setElementType('ELEMENTS');
  };
  const clearSearch = () => {
    setButtonSearch('');
    searchItems('', 'name');
  };
  return (
    <ButtonContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <LeftNavSearch
        searchValue={buttonSearch}
        placeholder={'Search Buttons'}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
      />
      {data?.length ? (
        <ButtonElement
          data={data && data.map((item) => item?.attributes?.asset?.data?.[0]?.attributes)}
          elementLoadMore={loadMore}
          elementLoading={loading}
          elementError={error}
          elementCount={count}
        />
      ) : (
        <Typography variant="large" sx={{ color: palette?.text.grey3 }}>
          No buttons found
        </Typography>
      )}
    </ButtonContainer>
  );
};

export default Buttons;
