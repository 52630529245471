import React from 'react';
import PropTypes from 'prop-types';
import { MuiAvatar } from './styles';
import PersonIcon from '@mui/icons-material/Person';

const propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
};

const defaultProps = {
    className: undefined,
    src: null,
    name: '',
    size: 32,
};

const Avatar = ({ className, src, name, size, onClick, ...otherProps }) => {
    const sharedProps = {
        className,
        size,
        'data-testid': name ? `avatar:${name}` : 'avatar',
        ...otherProps,
    };

    if (src && src !== '') {
        return (
            <MuiAvatar src={src} alt={name} name={name} {...sharedProps} onClick={onClick} size={size} />
        );
    } else if (name && name !== '') {
        name = name.split(' ').slice(0, 2).join(' ');
        return (
            <MuiAvatar {...sharedProps} alt={name} name={name} onClick={onClick} size={size}>
                <span>
                    {name
                        .match(/\b(\w)/g)
                        ?.join('')
                        .toUpperCase()}
                </span>
            </MuiAvatar>
        );
    } else {
        return (
            <MuiAvatar {...sharedProps} onClick={onClick} size={size}>
                <PersonIcon sx={{ width: 20, height: 20 }} />
            </MuiAvatar>
        );
    }
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
