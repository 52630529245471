import { Box, Typography } from '@mui/material';
import { palette } from 'config/theme';
import React from 'react';

const ContextItem = ({ icon, text, shortcut = '' }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="250px" height="35px">
            <Box display="flex" gap="10px">
                {icon}
                <Typography>{text}</Typography>
            </Box>
            <Box>
                <Typography>{shortcut}</Typography>
            </Box>
        </Box>
    );
};

export default ContextItem;
