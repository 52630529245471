import React, {
    createContext,
    useEffect,
    useState,
    useContext,
    useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useStore = () => {
    const {
        socket,
        events,
        isConnected,
        emit,
        socketUrl,
        setSocketUrl,
        disconnect,
        setEvents,
    } = useContext(SocketContext);
    return {
        socket,
        events,
        isConnected,
        emit,
        socketUrl,
        setSocketUrl,
        disconnect,
        setEvents,
    };
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [events, setEvents] = useState([]);
    const [socketUrl, setSocketUrl] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const { user } = useSelector((state) => state.Auth);

    useEffect(() => {
        console.log('socketuser:::::', user);
        if (user?.socketUrl) {
            setSocketUrl(user?.socketUrl);
        }
    }, [user]);

    const initSocket = useCallback(() => {
        if (user?.socketToken) {
            const socketClient = io(socketUrl, {
                withCredentials: true,
                transports: ['websocket'],
                query: {
                    token: user.socketToken || '',
                },
            });
            console.log('socketClient', socketClient, 'token', user, socketUrl);
            setSocket(socketClient);
        }

    }, [socketUrl]);

    const handleGoogleAuth = () => { };

    useEffect(() => {
        if (socket) {
            socket.onAny((event, payload) => {
                console.log('event::websocket', event, payload);
                if (event === 'user:login:success') {
                    // setSession('sockettoken', payload.token);
                    // setTimeout(() => {
                    //     socket.disconnect();
                    //     initSocket();
                    // }, 100);
                }

                if (event === 'oauth:google:url') {
                    window.location.href = payload.url;
                    // location.push("/");
                }
                setEvents((prevEvents) => [
                    {
                        message: `⬇️ Received - ${event}`,
                        time: new Date(),
                        event: event,
                        payload: JSON.stringify(payload),
                    },
                    ...prevEvents,
                ]);
            });

            socket.on('connect', () => {
                setIsConnected(true);
                setEvents((prevEvents) => [
                    {
                        message: '🚀 Connected to server',
                        time: new Date(),
                        payload: socketUrl,
                    },
                    ...prevEvents,
                ]);
            });

            socket.on('disconnect', () => {
                setIsConnected(false);
                setEvents((prevEvents) => [
                    { message: '🔌 Disconnected!', time: new Date() },
                    ...prevEvents,
                ]);
            });
        }
    }, [socket]);

    const reinitializeSocket = () => {
        if (socket) {
        }
        setSocket(null);
    };

    useEffect(() => {
        if (socketUrl && socketUrl !== '') {
            initSocket();
            return () => {
                // socket.close();
            };
        }
    }, [socketUrl]);

    const emit = (event, payload) => {
        console.log('emit::event', event, payload, 'isConnected', socket.connected);
        if (socket) {
            socket.emit(event, payload);
            setEvents((prevEvents) => [
                { message: `⬆️ Sent - ${event}`, payload, time: new Date(), event },
                ...prevEvents,
            ]);
        }

    };

    const disconnect = () => {
        socket.disconnect();
        setSocketUrl('');
        setIsConnected(false);
    };

    return (
        <SocketContext.Provider
            value={{
                events,
                socket,
                isConnected,
                emit,
                socketUrl,
                setSocketUrl,
                disconnect,
                setEvents,
            }}
        >
            {children}
        </SocketContext.Provider>
    );
};

export const withStore = (Component) => {
    const WrappedComponent = (props) => (
        <SocketProvider>
            <Component {...props} />
        </SocketProvider>
    );

    WrappedComponent.displayName = `withStore(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
};
