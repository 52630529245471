import React, { useState } from 'react';
import { CustomTooltip, LeftbarContainer } from './styles';
import { Divider, Typography } from '@mui/material';
import { leftbarOptions } from './config';
import Spacer from 'components/shared/Spacer';
import { CheckBox } from 'components/shared';

const Leftbar = ({ searchParams }) => {
  const [options, setOptions] = useState(leftbarOptions);
  const handleFilter = (checked, e) => {
    let value = e.target.name;
    let category = e.target?.parentElement?.getAttribute('category');
    console.log('name', value, category, e.target, e.target.parentElement.getAttribute('category'));
    if (checked) {
      searchParams(value, 'assetType');
    } else {
      searchParams('', 'assetType');
    }
    setOptions((prev) => {
      let newOptions = { ...prev };
      Object.keys(newOptions).forEach((key) => {
        newOptions[key] = newOptions[key].map((item) => {
          if (item.value === value) {
            item.checked = checked;
          } else {
            item.checked = false;
          }
          return item;
        });
      });
      return newOptions;
    });
  };
  return (
    <LeftbarContainer>
      {Object.keys(options).map((option, index, arr) => (
        <React.Fragment key={index}>
          <Typography variant="body1">{option}</Typography>
          {options[option].map((item) => {
            const isAudioOrVideo = item.name === 'Audio' || item.name === 'Video';
            return (
              <CustomTooltip title={isAudioOrVideo ? 'Coming Soon' : ''} arrow disableHoverListener={!isAudioOrVideo}>
                <div key={item.id}>
                  <CheckBox
                    label={item.name}
                    onChange={handleFilter}
                    value={item.checked}
                    name={item.value}
                    category={option}
                    disabled={isAudioOrVideo}
                  />
                </div>
              </CustomTooltip>
            );
          })}
          {arr.length - 1 !== index && (
            <React.Fragment>
              <Spacer x={1} y={1} />
              <Divider />
              <Spacer x={1} y={1} />
            </React.Fragment>
          )}
        </React.Fragment>
      ))}
    </LeftbarContainer>
  );
};

export default Leftbar;
