import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  FormControl,
  TextField,
  Chip,
  CircularProgress,
  createFilterOptions,
  MenuItem,
  ListItemIcon,
  InputAdornment,
  Checkbox,
  Typography,
  Box,
} from '@mui/material';
import { isNull, isEmpty } from 'lodash';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Avatar from 'components/shared/Avatar';
import Spacer from 'components/shared/Spacer';
import { TagIcon } from 'config/svgs';
import { CustomButton } from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  remoteMethod: PropTypes.func,
  autocomplete: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showAvatar: PropTypes.bool,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  optAvatar: PropTypes.string,
  disabled: PropTypes.bool,
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  allowAdd: PropTypes.bool,
  inputProps: PropTypes.any,
  color: PropTypes.string,
  checkboxes: PropTypes.bool,
  freesolo: PropTypes.bool,
  renderOption: PropTypes.func,
};

const InputAutocomplete = ({
  className,
  variant,
  label,
  name,
  defaultValue,
  placeholder,
  options,
  onChange,
  multiple,
  autocomplete,
  fullWidth,
  showAvatar,
  optLabel,
  optValue,
  optAvatar,
  value,
  disabled,
  remoteMethod,
  allowAdd,
  inputProps,
  error,
  addNew,
  addNewLabel,
  helperText,
  color,
  checkboxes,
  onSelectCustomFn = () => { }, // custom callback
  onNewAdd = () => { },
  freesolo,
  InputLabelProps,
  renderOption,
  formSx,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [remoteOptions, setRemoteOptions] = useState([]);
  const filter = createFilterOptions();

  const onType = useCallback(
    debounce(async (val) => {
      if (typeof remoteMethod !== 'undefined' && typeof remoteMethod === 'function') {
        setRemoteOptions([]);
        setLoading(true);
        const data = await getRemoteData(val);
        setRemoteOptions(data);
        setLoading(false);
      }
    }, 250),
    [remoteMethod]
  );

  const getRemoteData = async (val) => {
    return new Promise((resolve, reject) => {
      remoteMethod(val && typeof val !== 'undefined' ? val : '')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const onClose = () => {
    // setRemoteOptions([]);
  };

  let optionsList = [];
  if (typeof remoteMethod !== 'undefined' && typeof remoteMethod === 'function' && remoteOptions?.length > 0) {
    optionsList = remoteOptions;
  } else {
    optionsList = options;
  }

  const handleChange = (event, newValue) => {
    // setSelectedItems(newValue);
    onChange(newValue, event);
  };

  return (
    <FormControl fullWidth={fullWidth} margin="normal" sx={formSx || {}}>
      <Autocomplete
        multiple={multiple}
        options={optionsList}
        getOptionLabel={(option) => option?.[optLabel]}
        onChange={handleChange} // Update this line
        value={value || []}
        className={className}
        onClose={onClose}
        disableCloseOnSelect={checkboxes === true}
        onOpen={(event) => {
          onType(event?.target?.value);
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderTags={() => null}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);
          let { inputValue } = params;
          const isExisting = options.some((option) => inputValue?.trim() === option?.[optLabel]?.trim());
          filtered = [
            ...(!addNew && allowAdd && inputValue !== '' && !isExisting && !loading
              ? [
                {
                  [optValue]: undefined,
                  [optLabel]: inputValue,
                  'x-add-new-label': `+ Add ${inputValue}`,
                },
              ]
              : []),
            ...(addNew && inputValue !== ''
              ? [
                {
                  [optValue]: undefined,
                  [optLabel]: inputValue,
                  'x-add-new-label': addNewLabel,
                },
              ]
              : []),
            ...filtered,
          ];
          return filtered;
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...inputProps}
              autoComplete="off"
              label={label}
              placeholder={placeholder}
              variant={variant}
              fullWidth={fullWidth}
              error={error}
              helperText={helperText}
              style={props.inputStyles ? props.inputStyles : {}}
              onChange={(e) => {
                e.persist();
                onType(e.target.value);
              }}
              color={color}
              disabled={disabled}
              InputLabelProps={InputLabelProps}
              InputProps={{
                ...params.InputProps,
                ...(showAvatar &&
                  !multiple &&
                  value &&
                  value[optAvatar] && {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Avatar size={32} name={value[optLabel]} src={value[optAvatar]} />
                    </InputAdornment>
                  ),
                }),
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                autoComplete: 'none',
                ...props?.InputProps,
              }}
            />
          );
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <MenuItem {...props} key={`${props.id}-${option[optValue]}`}>
              <Typography sx={{ whiteSpace: 'break-spaces' }}>{option?.[optLabel]}</Typography>
            </MenuItem>
          );
        }}
        name={props.name}
        {...props}
      />
      <Spacer x={1} y={1} />
      <Box display={'flex'} flexWrap={'wrap'} gap={1}>
        {value && value.map((item, index) => (
          <CustomButton
            key={index}
            onClick={() => {
              const updatedItems = value.filter((_, i) => i !== index);
              // setSelectedItems(updatedItems);
              onChange(updatedItems);
            }}
            startIcon={<TagIcon />}
            endIcon={<CloseIcon />}>
            {item?.[optLabel]}
          </CustomButton>
        ))}
      </Box>
    </FormControl>
  );
};

InputAutocomplete.propTypes = propTypes;

export default InputAutocomplete;
