import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { palette } from 'config/theme';
import { ElementBox, NavButtonStyles, SwipeButtonLeft, SwipeButtonRight } from './styles';
import Skeleton from '@mui/material/Skeleton';
import { baseUrl } from 'helpers/apiCalls';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import appConfig from 'config';
import { useDispatch } from 'react-redux';
import { handleAddElement } from 'redux-layout/actions';
import { newID } from 'helpers/studioApi';
import { FramePlaceholder } from 'config/svgs';

export default function ElementShapes({
  elements = [],
  boxWidth,
  boxHeight,
  loading = false,
  loadMore,
  elementType = '',
  setElementType = () => { },
  searchItems = () => { },
  elementsSearch = '',
}) {
  const dispatch = useDispatch();

  const chunkSize = 3;
  const totalChunks = Math.ceil(elements.length / chunkSize);

  const getChunk = (chunkIndex) => {
    const start = chunkIndex * chunkSize;
    return elements.slice(start, start + chunkSize);
  };

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleChange = (index) => {
    setCurrentIndex(index);
  };

  const showPrevArrow = currentIndex > 0;

  const elementAdd = (item) => {
    const { width, height, url: imageUrl } = item || {};
    const srcUrl = imageUrl ? `${baseUrl}${imageUrl}` : '';
    const defaultImg = { ...appConfig.elemImg };
    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        src: srcUrl,
      },
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
    };

    dispatch(handleAddElement(element));
  };

  const handleSVGAdd = (item) => {
    const { code } = item || {};
    const defaultImg = { ...appConfig.elemSvg };

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(code, 'image/svg+xml');

    const svgElement = svgDoc.querySelector('svg');
    const attributes = {};
    let width = 0;
    let height = 0;
    if (svgElement) {
      Array.from(svgElement.attributes).forEach((attr) => {
        attributes[attr.name] = attr.value;
        if (attr.name === 'width') {
          width = parseFloat(attr.value);
        }
        if (attr.name === 'height') {
          height = parseFloat(attr.value);
        }
      });
    }

    const children = [];
    svgDoc.querySelectorAll('path').forEach((path) => {
      const d = path.getAttribute('d');
      const fill = path.getAttribute('fill') || 'none';
      children.push({ fill, d });
    });

    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        d: children.length > 0 ? children[0].d : '',
      },
      attributes,
      childrens: children,
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
      type: item?.type || 'svg',
    };

    dispatch(handleAddElement(element));
  };

  // useEffect(() => {
  //   console.log('elementsSearch', elementsSearch);
  //   searchItems(elementsSearch, 'name');
  // }, [elementsSearch]);

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" width="100%">
        <Box flexGrow={1}>
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible
            indicators={false}
            animation="slide"
            cycleNavigation={false}
            NextIcon={<SwipeButtonRight />}
            PrevIcon={<SwipeButtonLeft />}
            navButtonsProps={{ style: NavButtonStyles }}
            onChange={handleChange}>
            {Array.from({ length: totalChunks }).map((_, idx) => (
              <Grid container spacing={2} key={idx}>
                {loading
                  ? Array.from({ length: 5 }).map((__, skeletonIdx) => (
                    <Skeleton
                      key={skeletonIdx}
                      variant="rectangular"
                      width={boxWidth}
                      height={boxHeight}
                      sx={{ backgroundColor: palette?.primary?.shapeBg }}
                    />
                  ))
                  : getChunk(idx).map((item, itemIdx) => (
                    <Grid item xs={4} key={itemIdx}>
                      {item.path ? (
                        <IconButton onClick={() => handleSVGAdd(item)}>
                          <ElementBox
                            width={boxWidth}
                            height={boxHeight}
                            backgroundColor={item?.code ? 'transparent' : palette?.primary?.shapeBg}
                          >
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d={item.path} fill={`url(#${item.pattern_id})`} />
                              <FramePlaceholder id={item.pattern_id} />
                            </svg>
                          </ElementBox>
                        </IconButton>
                      ) : item?.code ? (
                        <IconButton onClick={() => handleSVGAdd(item)}>
                          <ElementBox
                            width={boxWidth}
                            height={boxHeight}
                            backgroundColor={item?.code ? 'transparent' : palette?.primary?.shapeBg}
                            dangerouslySetInnerHTML={{ __html: item?.code || '' }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => elementAdd(item)}>
                          <ElementBox
                            width={boxWidth}
                            height={boxHeight}
                            backgroundColor={item ? 'transparent' : palette?.primary?.shapeBg}>
                            <img
                              className="img-contain"
                              src={`${baseUrl}${item?.url}`}
                              alt={`${baseUrl}${item?.url}`}
                            />
                          </ElementBox>
                        </IconButton>
                      )}
                    </Grid>
                  ))}
                {/* {idx === totalChunks - 1 && (
                  <Grid item xs={4} display="flex">
                    <IconButton onClick={() => setElementType(elementType)}>
                      <ArrowCircleRightOutlinedIcon sx={{ color: palette?.background?.default }} />
                    </IconButton>
                  </Grid>
                )} */}
              </Grid>
            ))}
          </Carousel>
        </Box>
      </Box>
    </React.Fragment>
  );
}
