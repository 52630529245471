import { Box, Grid, InputAdornment, Card, CardContent, Typography, Button } from '@mui/material';
import React, { useEffect } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import images from 'config/images';
import LockIcon from '@mui/icons-material/Lock';
import Spacer from 'components/shared/Spacer';
import ResponsiveBox from 'components/App/ResponsiveBox';
import { connect } from 'react-redux';
import { loginUser } from 'redux-layout/actions';
import ReactGA from 'react-ga';
import { isUserAuthenticated } from 'helpers/authUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'components/shared';

const Login = (props) => {
    // const user = useSelector(state => state.auth);
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    console.log('userrr::', props.user);

    useEffect(() => {
        let path = 'app' + location?.pathname;
        ReactGA.pageview(path);
        document.body.classList.add('authentication-bg');
        return () => {
            document.body.classList.remove('authentication-bg');
        };
    }, [location?.pathname]);

    const renderRedirectToRoot = () => {
        const isAuthTokenValid = isUserAuthenticated();
        console.log('isAuthTokenValid', isAuthTokenValid);
        if (isAuthTokenValid) {
            navigate('/library');
        }
    };

    useEffect(() => {
        renderRedirectToRoot();
    }, [props.user]);



    const handleSubmit = async (values) => {
        // dispatch(loginUser(values.email, values.password));
        props.loginUser(values.email, values.password);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ResponsiveBox>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{ padding: '3rem' }}>
                                <Box>
                                    <img src={images.auth.loginlogo} alt="logo" height="50" />
                                </Box>
                                <Spacer x={3} y={3} />
                                <Box display={'flex'} gap="1rem" flexDirection={'column'}>
                                    <Typography variant="h6">
                                        Welcome back!
                                    </Typography>
                                    <Typography variant="body1">
                                        Enter your email address and password to access your account.
                                    </Typography>
                                </Box>
                                <Form
                                    initialValues={{
                                        email: '',
                                        password: ''
                                    }}
                                    onSubmit={async (values, form) => {
                                        handleSubmit(values);
                                    }}
                                    enableReinitialize={true}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                >
                                    {({ values, ...props }) => {
                                        return (
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    props.submitForm();
                                                }}
                                                noValidate
                                            >
                                                <Box
                                                    display="flex"
                                                    flex={1}
                                                    flexDirection="column"
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                    height="100%"
                                                >
                                                    <Box width="100%">
                                                        <Form.Field.Input
                                                            fullWidth
                                                            variant="outlined"
                                                            name="email"
                                                            placeholder="Enter your email"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <EmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <Form.Field.Input
                                                            fullWidth
                                                            variant="outlined"
                                                            name="password"
                                                            placeholder="Enter your password"
                                                            type="password"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <LockIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={24}
                                                            sm={24}
                                                        >
                                                            <Box mt={2}>
                                                                <Button variant="contained" type="submit">login</Button>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </form>
                                        );
                                    }}
                                </Form>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ backgroundImage: `url(${images.auth.loginbg})`, objectFit: 'cover', width: '100%', height: '100%', backgroundSize: 'cover' }}></Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </ResponsiveBox>
        </Box>

    );
};

// export default Login;


const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(mapStateToProps, { loginUser })(Login);
