import React from 'react';

export const AIIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1143 12.0723C18.3744 12.9605 16.2267 15.1082 15.3384 17.8493C14.4501 15.1082 12.3024 12.9605 9.56252 12.0723C12.3024 11.184 14.4501 9.03627 15.3384 6.29639C16.2267 9.03627 18.3744 11.184 21.1143 12.0723Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98341 5.56871C8.02757 5.87858 7.27833 6.62783 6.96843 7.58409C6.65853 6.62783 5.90928 5.87858 4.95344 5.56871C5.90928 5.2588 6.65853 4.50955 6.96843 3.55371C7.27833 4.50955 8.02757 5.2588 8.98341 5.56871Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09278 17.4563C7.67478 17.916 6.56328 19.0275 6.10354 20.4462C5.64379 19.0275 4.53227 17.916 3.11426 17.4563C4.53227 16.9966 5.64379 15.885 6.10354 14.467C6.56328 15.885 7.67478 16.9966 9.09278 17.4563Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ImproveIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4998 7.556L13.3387 8.1066C12.6801 8.41933 11.8965 8.2838 11.3812 7.76847L8.73133 5.1186C8.216 4.6033 8.08053 3.81977 8.3932 3.16047L8.9438 2M11.3021 7.68967L10.453 12.813C10.3965 13.1539 10.1015 13.401 9.75553 13.3918C8.51247 13.3601 5.57813 13.3629 3.37507 13.9749C2.86471 14.1168 2.38329 13.6353 2.52518 13.125C3.13718 10.9219 3.14001 7.9876 3.10824 6.74453C3.09977 6.39863 3.34613 6.10357 3.68707 6.04709L8.8104 5.19792M6.14666 10.3529L2.70313 13.7961M6.14666 10.3529C5.71889 9.92513 5.71858 9.23227 6.14635 8.8052C6.57341 8.37747 7.2666 8.37747 7.69433 8.8052C8.12213 9.23227 8.12213 9.92547 7.69433 10.3532C7.2666 10.7803 6.57372 10.7799 6.14666 10.3529Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SimplifyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6317 13.6983H13.4201M7.48107 13.6205L13.5093 7.59228C14.1636 6.93794 14.1636 5.87682 13.5093 5.22248L11.0796 2.79285C10.4253 2.13851 9.36487 2.13851 8.71047 2.79285L2.52935 8.97401C1.82355 9.67981 1.82355 10.8243 2.52935 11.5301L4.15652 13.1713C4.47986 13.4973 4.92004 13.6809 5.37968 13.6817L13.9946 13.6983M5.57292 5.9297L10.3723 10.7291"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ShortenIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6987 11.8946L7.20054 5.86979M13.6987 4.10416L7.20054 10.1289M5.97923 6.46366C6.84355 6.46366 7.5444 5.76282 7.5444 4.8985C7.5444 4.03418 6.84355 3.33333 5.97923 3.33333C5.11491 3.33333 4.41406 4.03418 4.41406 4.8985C4.41406 5.76282 5.11491 6.46366 5.97923 6.46366ZM5.97923 9.53633C6.84355 9.53633 7.5444 10.2372 7.5444 11.1015C7.5444 11.9658 6.84355 12.6667 5.97923 12.6667C5.11491 12.6667 4.41406 11.9658 4.41406 11.1015C4.41406 10.2372 5.11491 9.53633 5.97923 9.53633Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AdjustIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.84635 6.13637V6.10199M10.1538 6.13637V6.10199M6.20313 9.42707C6.64032 9.9966 7.28447 10.3514 7.99993 10.3514C8.7154 10.3514 9.35947 9.9966 9.79667 9.42707M5.47213 13.4429C7.69313 14.4704 10.4097 14.0741 12.2428 12.2403C14.5838 9.89933 14.5877 6.1008 12.2428 3.75526C9.9018 1.41491 6.09873 1.41491 3.75773 3.75526C1.92463 5.58901 1.52766 8.30493 2.55577 10.5259C2.68745 10.8548 2.78994 11.1188 2.78994 11.375C2.78994 12.0911 2.09912 12.9779 2.55967 13.4384C3.02021 13.8989 3.90693 13.2081 4.61915 13.2036C4.87472 13.2042 5.14391 13.3106 5.47213 13.4429ZM5.81601 6C5.73334 6 5.66601 6.06733 5.66667 6.15C5.66667 6.23267 5.73401 6.3 5.81667 6.3C5.89934 6.3 5.96667 6.23267 5.96667 6.15C5.96667 6.06667 5.89934 6 5.81601 6ZM10.1493 6C10.0667 6 9.99933 6.06733 10 6.15C10 6.23267 10.0673 6.3 10.15 6.3C10.2327 6.3 10.3 6.23267 10.3 6.15C10.3 6.06667 10.2327 6 10.1493 6Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TextLeftIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0133 5.53499H4.65332M20.0133 14.4946H4.65332M16.5996 10.0125H4.65332M16.5996 18.9731H4.65332"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TextNumberIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.31982 16.0953C4.31982 15.0351 5.17908 14.175 6.24008 14.175C7.30026 14.175 8.15952 15.0351 8.15952 16.0953C8.15952 16.5568 7.95446 17.2609 7.24796 17.8968C6.54145 18.5319 4.61621 19.935 4.61621 19.935H8.15952M6.24022 10.3341V4.575L4.46607 6.00378M11.3605 6.495H19.6799M11.3605 12.2545H19.6799M11.3605 18.0141H19.6799"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TextBulletPointIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66699 6H21.667M8.66699 12H21.667M8.66699 18H21.667M3.66699 6H3.67699M3.66699 12H3.67699M3.66699 18H3.67699"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InsertIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.793 12.7764L11.9985 15.58L9.20701 12.7764M12 15.5802V8.41895M14.8117 19.7708L19.77 14.8116C21.5029 13.0787 21.3589 10.7767 19.627 9.04476L14.9547 4.37254C13.2238 2.64162 10.9218 2.49664 9.18793 4.23049L4.22965 9.18876C2.49678 10.9216 2.64176 13.2237 4.37268 14.9546L9.0449 19.6268C10.7768 21.3587 13.0837 21.4988 14.8117 19.7708Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
