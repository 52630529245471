import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { CustomButton } from './styles';
import { AdjustIcon, ImproveIcon, ShortenIcon, SimplifyIcon } from 'pages/studio/Components/SVGs';

const ButtonGroup = ({ emit, text, loading, setLoading, setOpenSnackbar, setSelectedButton }) => {
  const [loadingIndex, setLoadingIndex] = useState(null);

  const buttonsData = [
    { name: 'Improve it', icon: <ImproveIcon /> },
    { name: 'Simplify it', icon: <SimplifyIcon /> },
    { name: 'Shorten it', icon: <ShortenIcon /> },
    { name: 'Adjust', icon: <AdjustIcon /> },
  ];

  const handleTextGenerate = (button, index) => {
    setSelectedButton(button?.name.toLowerCase())
    setLoading(true);
    setLoadingIndex(index);

    try {
      emit(
        'pipeline:execute',
        JSON.stringify({
        slug: 'improve-text',
          payload: {
            instructions: button?.name.toLowerCase(),
            text: text,
          },
        })
      );
    } catch (e) {
      setLoading(false);
      setLoadingIndex(null);
      setError('server error');
      setOpenSnackbar(true);
      console.log('regeneration text ERROR::', e);
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {buttonsData.map((button, index) => (
        <Grid item xs={6} key={index}>
          <CustomButton startIcon={button.icon} onClick={() => handleTextGenerate(button, index)}>
            {loading && loadingIndex === index ? 'Loading...' : button.name}
          </CustomButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default ButtonGroup;
