

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isUserAuthenticated } from 'helpers/authUtils';

const ProtectedRoute = ({ isPublic }) => {
    return isPublic || isUserAuthenticated() ? <Outlet /> : <Navigate to="/account/login" />;
};

export default ProtectedRoute;
