import React from 'react';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { palette } from 'config/theme';
import {TextBoxButton, TextInput, PhotoContainer } from './style';
import SearchIcon from '@mui/icons-material/Search';
import Video from './Video';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';

const Videos = (props) => {
  const { elementType, setElementType } = props;

  return (
    <PhotoContainer>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ gap: '10px' }}>
        <LeftNavSearch placeholder={'Search Videos'} />
        <TextBoxButton variant="btnText4">Upload</TextBoxButton>
        <Video title={'Your uploads'} subTitle={'see all'} videosData={[1,2,3,4]} />
        <Video title={'LifeWise library'} videosData={[1,2,3,4,5,6,7,8,9,10]} />
      </Stack>
    </PhotoContainer>
  );
};

export default Videos;
