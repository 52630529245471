

export const template = [
    {
        id: 'no_template_1',
        name: 'No Template',
        background: '#fff',
        slides: [
            {
                id: 'sld1',

                Meta: {
                    Name: '1',
                    Background: {},
                },
                style: {
                    'background-color': '#ffffff',
                },
                elements: [],
            },
        ]
    },
    // {
    //     id: 2,
    //     name: 'Template 2',
    //     background: '#968F8F'
    // },
    // {
    //     id: 3,
    //     name: 'Template 3',
    //     background: '#968F8F'
    // },
    // {
    //     id: 4,
    //     name: 'Template 4',
    //     background: '#968F8F'
    // },
    // {
    //     id: 5,
    //     name: 'Template 5',
    //     background: '#968F8F'
    // }

];

export const yearGroupsOptions = [
    {
        planId: '1',
        name: '3 - 4',
    },
    {
        planId: '2',
        name: '4 - 5',
    },
    {
        planId: '3',
        name: '5 - 6',
    },
    {
        planId: '4',
        name: '6 - 7',
    },
    {
        planId: '5',
        name: '7 - 8',
    },
    {
        planId: '6',
        name: '8 - 9',
    },
    {
        planId: '7',
        name: '9 - 10',
    },
    {
        planId: '8',
        name: '10 - 11',
    },
    {
        planId: '9',
        name: '11 - 12',
    },
    {
        planId: '10',
        name: '12 - 13',
    },
    {
        planId: '11',
        name: '13 - 14',
    },
    {
        planId: '12',
        name: '14 - 15',
    },
    {
        planId: '13',
        name: '15 - 16',
    },
];

export const difficultyOptions = [
    {
        planId: 'easy',
        name: 'Easy',
    },
    {
        planId: 'medium',
        name: 'Medium',
    },
    {
        planId: 'hard',
        name: 'Hard',
    }
];

export const slideOptions = [
    {
        planId: '1',
        name: '1',
    },
    {
        planId: '2',
        name: '2',
    },
    {
        planId: '3',
        name: '3',
    },
    {
        planId: '4',
        name: '4',
    },
    {
        planId: '5',
        name: '5',
    },
    {
        planId: '6',
        name: '6',
    },
    {
        planId: '7',
        name: '7',
    },
    {
        planId: '8',
        name: '8',
    },
    {
        planId: '9',
        name: '9',
    },
    {
        planId: '10',
        name: '10',
    },
    {
        planId: '11',
        name: '11',
    },
    {
        planId: '12',
        name: '12',
    },
    {
        planId: '13',
        name: '13',
    },
    {
        planId: '14',
        name: '14',
    },
    {
        planId: '15',
        name: '15',
    },
    {
        planId: '16',
        name: '16',
    },
    {
        planId: '17',
        name: '17',
    },
    {
        planId: '18',
        name: '18',
    },
    {
        planId: '19',
        name: '19',
    },
    {
        planId: '20',
        name: '20',
    },

];


