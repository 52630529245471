import React from 'react';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { TextButton, TextContainer, TextBoxButton } from './style';
import TextBoxes from './TextBoxes';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { handleAddElement } from 'redux-layout/actions';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from 'helpers/apiCalls';

const Text = (props) => {
  const dispatch = useDispatch();
  const { elementType, setElementType } = props;
  const { project, selectedSlide, selectedElement } = useSelector((state) => state.Canvas.present);
  // console.log('🚀 ~ Text ~ selectedSlide:', selectedSlide, selectedElement, project);

  const elementConfigs = {
    FRAMES: { api: 'assets', params: { contenttype: { $eq: 'textboxes' } } },
  };
  const { api, params: apiParams } = elementConfigs[elementType === 'TEXT' ? 'FRAMES' : ''] || { api: '', params: {} };
  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams);

  const handleTextAdd = (subType, textSize) => {
    const defaultImg = { ...appConfig?.elemText };
    const element = {
      ...defaultImg,
      style: {
        ...defaultImg.style,
        'font-size': textSize,
      },
      subType: subType,
      id: newID('element'),
      content: {
        placeholder: 'Double click to edit',
        text: '',
      },
      position: {
        ...defaultImg.position,
        height: 20,
        width: 50,
      },
    };
    console.log('🚀 ~ handleTextAdd ~ element:', element);
    dispatch(handleAddElement(element));
  };

 
  const handleTextBoxImgAdd = (item) => {
    const { width, height, url: imageUrl } = item || {};
    const defaultImg = { ...appConfig.elemComplexText };
    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        image: `${baseUrl}${imageUrl}`,
        placeholder: 'Double click to edit',
        text: '',
      },
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
    };
    dispatch(handleAddElement(element));
  };

  return (
    <TextContainer>
      <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} sx={{ gap: '16px' }}>
        <TextButton variant="btnText1" onClick={() => handleTextAdd('heading', 76)}>
          Add a heading
        </TextButton>
        <TextButton variant="btnText2" onClick={() => handleTextAdd('subHeading', 48)}>
          Add a Subheading
        </TextButton>
        <TextButton variant="btnText3" onClick={() => handleTextAdd('body text', 32)}>
          Add a body text
        </TextButton>
        <TextBoxButton variant="btnText4" onClick={() => handleTextAdd('body text', 28)}>Add a text box</TextBoxButton>
      </Stack>
      <TextBoxes
        title={'Text Boxes'}
        textBoxData={data && data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
        elementLoadMore={loadMore}
        elementLoading={loading}
        elementError={error}
        elementCount={count}
        handleTextBoxImgAdd={handleTextBoxImgAdd}
      />
    </TextContainer>
  );
};

export default Text;
