// @flow

import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Studio from './studio/reducers';
import Canvas from './canvas/reducer';


export default combineReducers({
    Auth,
    AppMenu,
    Canvas,
    Studio
});
