import React, { useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Form } from 'components/shared';
import { AttachmentButton, CustomButton, GridContainer, PublishModalContainer } from 'layouts/styles';
import { ModalPublishIcon, TagIcon, UploadIcon } from 'config/svgs';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { palette } from 'config/theme';
import { keywordOptions, subjectOptions, yearGroupsOptions } from './config';

const Publish = ({ onPublishModelClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [keyword, setKeyword] = useState('');
  const [keywordsList, setKeywordsList] = useState([]);

  return (
    <PublishModalContainer>
      <Form
        initialValues={{
          title: '',
          'age-range': '',
          description: '',
          subject: '',
          keyword: '',
          attachment: '',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('This field is required!'),
          'age-range': Yup.string().required('This field is required!'),
          subject: Yup.string().required('This field is required!'),
          keyword: Yup.string().required('This field is required!'),
        })}
        onSubmit={async (values, form) => {
          handleSubmit(values);
        }}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}>
        {({ values, ...props }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
            style={{ height: '100%' }}>
            <GridContainer container spacing={2}>
              <Grid item xs={2} display="flex" alignItems={'center'}>
                <label>
                  <Typography variant="body2">Title*</Typography>
                </label>
              </Grid>
              <Grid item xs={10}>
                <Form.Field.Input
                  optValue="title"
                  optLabel="title"
                  variant="outlined"
                  placeholder="Title"
                  name="title"
                  fullWidth
                  onChange={(e) => setError('')}
                  InputProps={{ style: { height: '55px' } }}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'}>
                <label>
                  <Typography variant="body2">
                    Description
                    <br />
                    (Optional)
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={10}>
                <Form.Field.Textarea
                  minRows={3}
                  variant="outlined"
                  name="description"
                  placeholder="(Optional)"
                  fullWidth
                  onChange={(e) => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'}>
                <label>
                  <Typography variant="body2">Year group</Typography>
                </label>
              </Grid>
              <Grid item xs={10}>
                <Form.Field.Select
                  options={yearGroupsOptions}
                  optValue="planId"
                  optLabel="name"
                  variant="outlined"
                  placeholder="Select one"
                  name="year-group"
                  fullWidth
                  onChange={(e) => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'}>
                <label>
                  <Typography variant="body2">Subject*</Typography>
                </label>
              </Grid>
              <Grid item xs={10}>
                <Form.Field.Select
                  options={subjectOptions}
                  optValue="subjectId"
                  optLabel="name"
                  variant="outlined"
                  placeholder="Select one"
                  name="subject"
                  fullWidth
                  onChange={(e) => setError('')}
                />
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'}>
                <label>
                  <Typography variant="body2">Keywords*</Typography>
                </label>
              </Grid>
              <Grid item xs={10}>
                <Form.Field.AutoComplete
                  multiple={true}
                  options={keywordOptions}
                  optValue="keywordId"
                  optLabel="name"
                  variant="outlined"
                  placeholder="Select"
                  name="keywords"
                  fullWidth
                  showNone={false}
                  onChange={(e) => {
                    // handleCloseSnackbar();
                    // setError('');
                  }}
                />
                <Stack direction="row" spacing={1}>
                  {keywordsList.map((kw, index) => (
                    <CustomButton key={index} startIcon={<TagIcon />} endIcon={<CloseIcon />}>
                      {kw}
                    </CustomButton>
                  ))}
                </Stack>
              </Grid>
              <Grid item xs={2} display="flex" alignItems={'center'}>
                <label>
                  <Typography variant="body2">
                    Attachment
                    <br />
                    (optional)
                  </Typography>
                </label>
              </Grid>
              <Grid item xs={10}>
                <Form.Field.File size="small" fullWidth rows={4} variant="outlined" name="files" maxSize={100} />
                {/* <AttachmentButton>
                  <UploadIcon />
                  Upload
                </AttachmentButton> */}
                <Stack direction="row" spacing={1}>
                  {keywordsList.map((kw, index) => (
                    <CustomButton key={index} startIcon={<TagIcon />} endIcon={<CloseIcon />}>
                      {kw}
                    </CustomButton>
                  ))}
                </Stack>
              </Grid>
            </GridContainer>
            <Stack
              direction="row"
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                background: palette.background.paper,
                justifyContent: 'flex-end',
                width: '100%',
              }}
              spacing={2}
              padding={'1rem'}>
              <LoadingButton variant="text" onClick={onPublishModelClose} loading={loading}>
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                startIcon={<ModalPublishIcon />}
                loading={loading}>
                Publish
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Form>
    </PublishModalContainer>
  );
};

export default Publish;
