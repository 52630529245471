import React from 'react';
import Grid from '@mui/material/Grid';
import { ErrorMessage, ScrollContainer } from './styles';
import { Button, IconButton, Stack } from '@mui/material';
import Loader from 'pages/studio/Components/Loader';
import { useDispatch } from 'react-redux';
import { handleAddElement } from 'redux-layout/actions';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { FramePlaceholder } from 'config/svgs';

const FrameElement = ({ data, elementLoadMore, elementLoading, elementError, elementCount }) => {
  const dispatch = useDispatch();

  const handleFrameAdd = (item) => {
    const { code } = item || {};
    const defaultImg = { ...appConfig.elemSvg };

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(code, 'image/svg+xml');

    const svgElement = svgDoc.querySelector('svg');
    const attributes = {};
    let width = 0;
    let height = 0;
    if (svgElement) {
      Array.from(svgElement.attributes).forEach((attr) => {
        attributes[attr.name] = attr.value;
        if (attr.name === 'width') { width = parseFloat(attr.value); }
        if (attr.name === 'height') { height = parseFloat(attr.value); }
      });
    }

    const children = [];
    svgDoc.querySelectorAll('path').forEach((path) => {
      const d = path.getAttribute('d');
      const fill = path.getAttribute('fill') || 'none';
      children.push({ fill, d });
    });

    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        d: children.length > 0 ? children[0].d : '',
      },
      attributes,
      childrens: children,
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
      type: 'frame'
    };

    dispatch(handleAddElement(element));
  };

  return (
    <ScrollContainer>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {data.map(({ attributes }, index) => {
                return (
                  <Grid item key={index} xs={3}>
                    <IconButton onClick={() => handleFrameAdd(attributes)}>
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d={attributes.path} fill={`url(#${attributes.pattern_id})`} />
                        <FramePlaceholder id={attributes.pattern_id} />
                      </svg>
                    </IconButton>
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {data?.length === elementCount ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={elementLoadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </ScrollContainer>
  );
};

export default FrameElement;
