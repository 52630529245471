import React, { useState } from 'react';
import { Box, Grid, Snackbar, Stack, Typography } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { palette } from 'config/theme';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { useStore } from 'Socket';
import * as Yup from 'yup';
import { GridContainer, ModalContainer } from 'layouts/styles';
import { difficultyOptions, yearGroupsOptions } from './config';
import { addNewSlide, slideReplace } from 'redux-layout/actions';
import { Form, Spacer } from 'components/shared';

const RegenerateLesson = ({ handleRegenerateLessonClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slides } = useSelector((state) => state.Canvas.present);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { emit, events, setEvents } = useStore();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (values) => {
    handleCloseSnackbar();
    setError('');
    setLoading(true);
  
    // Track successful responses and errors
    const totalSlides = slides.length;
    const slideResponses = [];
  
    const processSlide = async (slideItem, index) => {
      return new Promise((resolve, reject) => {
        const payload = {
          slug: 'regenrate-slide',
          payload: {
            instructions: values.guidelines,
            json: slideItem,
          },
        };
  
        emit('pipeline:execute', JSON.stringify(payload));
  
        const checkEvent = () => {
          const successEvent = events.find((item) => item.event === 'pipeline:execute:success');
          if (successEvent?.event === 'pipeline:execute:success') {
            const response = JSON.parse(successEvent.payload);
            slideResponses[index] = response;
  
            const updatedSlide = {
              id: slideItem.id,
              ...response?.json?.project?.slides[0],
            };
  
            dispatch(slideReplace(updatedSlide));
            resolve();
          } else {
            setError('AI generation failed to generate presentation');
            setLoading(false);
            setOpenSnackbar(true);
            setEvents([]);
            reject('Error');
          }
        };
  
        setTimeout(checkEvent, 1000);
      });
    };
  
    try {
      // Process all slides
      await Promise.all(slides.map((slideItem, index) => processSlide(slideItem, index)));
  
      if (slideResponses.length === totalSlides) {
        // handleRegenerateLessonClose();
      } else {
        setError('Not all slides were processed successfully');
        setOpenSnackbar(true);
      }
    } catch (e) {
      setLoading(false);
      setError('Server error');
      setOpenSnackbar(true);
      console.log('regeneration text ERROR::', e);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <ModalContainer>
      <Box padding={'20px'}>
        <Typography variant="body1">Templates</Typography>
        <Spacer x={1} y={1} />
        <Form
          initialValues={{
            ['year-group']: '',
            ['guidelines']: '',
            ['difficulty']: '',
          }}
          validationSchema={Yup.object().shape({
            ['year-group']: Yup.string().required('This field is required!'),
            ['guidelines']: Yup.string().required('This field is required!'),
            ['difficulty']: Yup.string().required('This field is required!'),
          })}
          onSubmit={async (values, form) => {
            handleSubmit(values);
          }}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}>
          {({ values, ...props }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
                style={{ height: '100%' }}>
                <GridContainer container spacing={2}>
                  <Grid item xs={4} display="flex" alignItems={'center'}>
                    <label>
                      <Typography variant="body2">Year group</Typography>
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <Form.Field.Select
                      options={yearGroupsOptions}
                      optValue="name"
                      optLabel="name"
                      variant="outlined"
                      placeholder="Select"
                      name="year-group"
                      fullWidth
                      onChange={(e) => {
                        handleCloseSnackbar();
                        setError('');
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>
                      <Typography variant="body2">Lesson guidelines</Typography>
                    </label>
                    <Form.Field.Textarea
                      minRows={3}
                      variant="outlined"
                      name={'guidelines'}
                      placeholder={'(Optional)'}
                      fullWidth
                      onKeyDown={(e) => {
                        console.log("🚀 ~ e:onKeyDown", e.key)
                      }}
                      onChange={(e) => {
                        // handleCloseSnackbar();
                        // setError('');
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} display="flex" alignItems={'center'}>
                    <label>
                      <Typography variant="body2">Difficulty</Typography>
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <Form.Field.Select
                      options={difficultyOptions}
                      optValue="planId"
                      optLabel="name"
                      variant="outlined"
                      placeholder="Please Select"
                      name="difficulty"
                      fullWidth
                      onChange={(e) => {
                        handleCloseSnackbar();
                        setError('');
                      }}
                    />
                  </Grid>
                </GridContainer>
                <Stack
                  direction="row"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    background: palette.background.paper,
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                  spacing={2}
                  padding={'1rem'}>
                  <LoadingButton variant="text" onClick={handleRegenerateLessonClose} loading={loading}>
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<AutoAwesomeOutlinedIcon />}
                    loading={loading}>
                    Recreate with AI
                  </LoadingButton>
                </Stack>
              </form>
            );
          }}
        </Form>
      </Box>
      <Snackbar open={openSnackbar} handleClose={handleCloseSnackbar} message={error} severity={'error'} />
    </ModalContainer>
  );
};

export default RegenerateLesson;
