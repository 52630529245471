import { Box } from '@mui/material';
import React from 'react';

export const ResizeHandles = ({
    wrapperClassName = '',
    wrapperStyle = {},
}) => {
    return (
        <Box className={`parent-marker ${wrapperClassName}`} style={wrapperStyle}>
            <Box className="left-top"></Box>
            <Box className="right-top"></Box>
            <Box className="right-bottom"></Box>
            <Box className="left-bottom"></Box>
            <Box className="left-center"></Box>
            <Box className="right-center"></Box>
            <Box className="top-center"></Box>
            <Box className="bottom-center"></Box>
        </Box>
    );
};
