import styled from '@emotion/styled';
import { palette } from 'config/theme';


export const Container = styled.div`
    background: ${palette.background.paper};
    padding: 0.5rem;
    height:4.5rem;
    width: 100%;
    position: relative;
    z-index: 100;
    display:flex;
    gap: 0.5rem;
`;

export const Sizebox = styled.div`
    background: ${palette.background.paper};
    border: 1px solid ${palette.border.main};
    border-radius: ${props => props.position === 'left' ? '4px 0 0 4px' : '0 4px 4px 0'};
    padding: 16.5px 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height:50px;
`;

export const Underline = styled.div`
    width: 100%;
    height: 5px;
    background: ${(props) => props.color};
    border-radius: 20px;
`;

export const BackgroundPick = styled.div`
    background: ${(props) => props.background};
    border-radius: 2px;
    width:20px;
    height:20px;
    cursor: pointer;
`;

export const StyleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width:27px;
    cursor: pointer;
    padding: 5px;
    background: ${({ isTrue }) => isTrue ? palette.background.light : palette.background.paper};
    user-select: none;
`;
