import React, { useContext, useMemo, useState } from 'react';
import { BackgroundPick, Container, Sizebox, StyleBox, Underline } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, FormControl, Menu, MenuItem, Select, Switch, Typography } from '@mui/material';
import { fontFamily, fontSize } from './config';
import { elementUpdated } from 'redux-layout/actions';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { AiIcon, AnimatePlayIcon, LinkIcon } from 'config/svgs';
import ColorPicker from '../Components/ColorPicker';
import { StudioContext } from '..';
import { PHOTOS } from '../LeftAddElements/config';
import LinkItem from './LinkItem';

const Toolbar = () => {
    const { selectedElement } = useSelector(state => state.Canvas.present);
    const dispatch = useDispatch();
    const { formatting, setFormatting, setElementType, setImageType } = useContext(StudioContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const style = useMemo(() => {
        const getStyle = {
            backgroundColor: selectedElement.style?.backgroundColor ? selectedElement.style?.backgroundColor : '#ffffff',
            textDisplay: 'block',
            fontSize: selectedElement.style?.['font-size'] ? selectedElement.style?.['font-size'] : 0,
            fontFamily: selectedElement.style?.['font-family'] ? selectedElement.style?.['font-family'] : '',
            fontWeight: selectedElement.style?.['font-weight'] ? selectedElement.style?.['font-weight'] : 400,
            fontStyle: selectedElement.style?.['font-style'] ? selectedElement.style?.['font-style'] : 'normal',
            textDecoration: selectedElement.style?.['text-decoration'] ? selectedElement.style?.['text-decoration'] : 'none',
            color: selectedElement.style?.color ? selectedElement.style?.color : '#000000',
            textAlign: selectedElement.style?.['text-align'] ? selectedElement.style?.['text-align'] : 'left',
            srcImage: selectedElement.type === 'img' ? selectedElement.content?.src : '',
            text: selectedElement.content?.text,
            textTranform: selectedElement.style?.['text-transform'] ? selectedElement.style?.['text-transform'] : 'inherit',
        };

        return getStyle;
    }, [selectedElement]);


    const handleFontFamily = (event) => {
        dispatch(elementUpdated(selectedElement, event.target.value, 'font-family'));
    };

    const handleFontSize = (event, type) => {
        switch (type) {
            case 'select':
                dispatch(elementUpdated(selectedElement, event.target.value, 'font-size'));
                break;
            case 'increase':
                if (style.fontSize < 91) {
                    dispatch(elementUpdated(selectedElement, style.fontSize + 1, 'font-size'));
                }
                break;
            case 'decrease':
                if (style.fontSize > 8) {
                    dispatch(elementUpdated(selectedElement, style.fontSize - 1, 'font-size'));
                }
                break;
            default:
                break;
        }
    };

    const handleFontWeight = () => {
        setFormatting((prev) => ({ ...prev, bold: !prev.bold, isFormattingChanged: true }));
    };

    const handleFontStyle = (value) => {
        setFormatting((prev) => ({ ...prev, italic: !prev.italic, isFormattingChanged: true }));
    };

    const handleTextDecoration = (value) => {
        setFormatting((prev) => ({ ...prev, underline: !prev.underline, isFormattingChanged: true }));
    };

    const handleTextTransform = (value) => {
        dispatch(elementUpdated(selectedElement, value === 'inherit' ? 'uppercase' : 'inherit', 'text-transform'));
    };

    const handleTextAlign = (value) => {
        switch (value) {
            case 'left':
                dispatch(elementUpdated(selectedElement, 'center', 'text-align'));
                break;
            case 'center':
                dispatch(elementUpdated(selectedElement, 'right', 'text-align'));
                break;
            case 'right':
                dispatch(elementUpdated(selectedElement, 'justify', 'text-align'));
                break;
            case 'justify':
                dispatch(elementUpdated(selectedElement, 'left', 'text-align'));
                break;
            default:
                break;
        }
    };

    const textAlignIcon = (type) => {
        switch (type) {
            case 'center':
                return <FormatAlignCenterIcon />;
            case 'justify':
                return <FormatAlignJustifyIcon />;
            case 'left':
                return <FormatAlignLeftIcon />;
            case 'right':
                return <FormatAlignRightIcon />;
            default:
                return <FormatAlignLeftIcon />;
        }
    };

    const textListIcon = () => {
        if (formatting.list === 'ul') {
            return <FormatListBulletedIcon />;
        } else if (formatting.list === 'ol') {
            return <FormatListNumberedIcon />;
        } else {
            return <FormatListBulletedIcon />;
        }
    };

    const handleList = () => {
        const selection = window.getSelection();
        const selectedText = selection.toString();
        if (!selectedText) { return; }
        if (formatting.list === '') {
            setFormatting((prev) => ({ ...prev, list: 'ul', isFormattingChanged: true }));
        } else if (formatting.list === 'ul') {
            setFormatting((prev) => ({ ...prev, list: 'ol', isFormattingChanged: true }));
        } else if (formatting.list === 'ol') {
            setFormatting((prev) => ({ ...prev, list: '', isFormattingChanged: true }));
        }
    };


    return (
        <Container>
            {selectedElement && (selectedElement.type === 'text' || selectedElement.type === 'complex_text') && (
                <Box display="flex" gap="2rem" alignItems={'center'}>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={style.fontFamily}
                            onChange={handleFontFamily}
                            sx={{ width: '160px', height: '50px' }}
                        >
                            {fontFamily.map((font, index) => (
                                <MenuItem key={index} value={font.value}>{font.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Box display="flex">
                            <Sizebox position="left" onClick={(e) => handleFontSize(e, 'decrease')}>
                                <RemoveIcon />
                            </Sizebox>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={style.fontSize}
                                onChange={(e) => handleFontSize(e, 'select')}
                                sx={{ height: '50px', width: '50px', '& .MuiSelect-select': { paddingRight: '0 !important' } }}
                                IconComponent={() => <div />}
                            >
                                {fontSize.map((font, index) => (
                                    <MenuItem key={index} value={font.value}>{font.label}</MenuItem>
                                ))}
                            </Select>
                            <Sizebox position="right" onClick={(e) => handleFontSize(e, 'increase')}>
                                <AddIcon />
                            </Sizebox>
                        </Box>
                    </FormControl>
                    <StyleBox width="27px">
                        <ColorPicker elemId={selectedElement} type="color">
                            <Typography variant="large">A</Typography>
                            <Underline color={style.color}></Underline>
                        </ColorPicker>
                    </StyleBox>
                    <StyleBox onClick={handleFontWeight} isTrue={formatting.bold}>
                        <Typography variant="large" sx={{ fontWeight: '700' }}>B</Typography>
                    </StyleBox>
                    <StyleBox onClick={handleFontStyle} isTrue={formatting.italic}>
                        <Typography variant="large" sx={{ fontStyle: 'italic' }}>I</Typography>
                    </StyleBox>
                    <StyleBox onClick={handleTextDecoration} isTrue={formatting.underline}>
                        <Typography variant="large" sx={{ textDecoration: 'underline' }}>U</Typography>
                    </StyleBox>
                    <StyleBox onClick={() => handleTextTransform(style.textTranform)}>
                        <Typography variant="large" >Aa</Typography>
                    </StyleBox>
                    <Divider orientation="vertical" flexItem />
                    <StyleBox onClick={() => handleTextAlign(style.textAlign)}>
                        {textAlignIcon(style.textAlign)}
                    </StyleBox>
                    <StyleBox onClick={handleList} isTrue={formatting.list}>
                        {textListIcon()}
                    </StyleBox>
                    <Divider orientation="vertical" flexItem />
                </Box>
            )}
            {selectedElement && (selectedElement.type === 'svg') && (
                <Box display="flex" gap="1rem" alignItems={'center'}>
                    <ColorPicker elemId={selectedElement} type="svgcolor">
                        <BackgroundPick background={style.color} />
                    </ColorPicker>
                </Box>
            )}
            {selectedElement && (selectedElement.type === 'iframe' || selectedElement.type === 'svg' || selectedElement.type === 'img' || selectedElement.type === 'text' || selectedElement.type === 'complex_text') && (
                <Box display="flex" gap="1rem" alignItems={'center'}>
                    <Box onClick={() => setElementType('TOOLBAR_ANIMATION')} sx={{ cursor: 'pointer', userSelect: 'none' }} display={'flex'} justifyContent={'center'} alignItems={'center'} gap="0.5rem">
                        <AnimatePlayIcon />
                        <Typography variant="body3" >Animate</Typography>
                    </Box>
                    <Box
                        sx={{ cursor: 'pointer', userSelect: 'none' }}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width="27px"
                        onClick={handleClick}
                    >
                        <LinkIcon />
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ borderRadius: '8px' }}
                    >
                        <LinkItem />
                    </Menu>
                    <Divider orientation="vertical" flexItem />
                </Box>
            )}
            {selectedElement && (selectedElement.type === 'text' || selectedElement.type === 'complex_text') && (
                <Box sx={{ cursor: 'pointer', userSelect: 'none' }} display={'flex'} justifyContent={'center'} alignItems={'center'} gap="0.5rem" onClick={() => setElementType('TOOLBAR_REGENERATESLIDE')}>
                    <AiIcon />
                    <Typography variant="body3" >Recreate with AI</Typography>
                </Box>
            )}
            {selectedElement && (selectedElement.type === 'frame' && selectedElement?.content?.src) && (
                <Box display="flex" gap="1rem" alignItems={'center'}>
                    <Box onClick={() => {
                        setElementType(PHOTOS);
                        setImageType('frame');
                    }} sx={{ cursor: 'pointer', userSelect: 'none' }} display={'flex'} justifyContent={'center'} alignItems={'center'} gap="0.5rem">
                        <Typography variant="body3" >Replace image</Typography>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default Toolbar;
