// @flow
import { cookieUtils } from './utils';
/**
 * Returns the logged in user
 * admin user orgAdmin || schoolAdmin teacher student
 */
const getLoggedInUser = () => {
    const user = cookieUtils.get('user');
    // console.log('user::', user);
    const userObj = user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
    if (
        userObj &&
        (userObj.user.type === 'schoolAdmin' || userObj.user.type === 'teacher' || userObj.user.type === 'student')
    ) {
        window.location.replace('https://classroom.lauk.in/');
    }
    return userObj;
};

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    console.log('getLoggedInUser', user);
    if (!user) {
        return false;
    } else {
        return true;
    }
    // const decoded = jwtDecode(user.jwt);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     console.warn('access token expired');
    //     return false;
    // } else {
    //     return true;
    // }
};

// const setSession = (key, payload) => {
//     let cookies = new Cookies();
//     if (payload) {
//         cookies.set(key, JSON.stringify(payload), { domain: appConfig.cookiesDoamin });
//     } else {
//         cookies.remove(key, { domain: appConfig.cookiesDoamin });
//     }
// };

// const getSession = (type) => {
//     let cookies = new Cookies();
//     return cookies.get(type, { domain: appConfig.cookiesDoamin });
// };
export { isUserAuthenticated, getLoggedInUser };
