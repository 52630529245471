import React, { useContext, useRef, useEffect, forwardRef } from 'react';
import { SetBg, ToolbarContainer } from './Styles';
import { Box, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { AiIcon, MoveIcon } from 'config/svgs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import { StudioContext } from 'pages/studio';
import { handleRemoveElement, elementChanged } from 'redux-layout/actions';

const TextToolbar = forwardRef(
  ({ isVisible, element, handleContextMenu, handleMouseDownDrag, handleMouseDownRotate }, ref) => {
    const dispatch = useDispatch();
    const { setElementType } = useContext(StudioContext);
    // const pos = useRef({ x: element.position.x, y: element.position.y });
    // const startPos = useRef({ x: element.position.x, y: element.position.y });
    // const isDragging = useRef(false);

    // useEffect(() => {
    //     const handleMouseMove = (e) => {
    //         if (isDragging.current) {
    //             const dx = e.clientX - startPos.current.x;
    //             const dy = e.clientY - startPos.current.y;

    //             pos.current.x += dx;
    //             pos.current.y += dy;

    //             startPos.current = { x: e.clientX, y: e.clientY };
    //             console.log('reference', ref.current);
    //             ref.current.style.transform = `translate(${pos.current.x}px, ${pos.current.y}px)`;
    //         }
    //     };

    //     const handleMouseUp = () => {
    //         if (isDragging.current) {
    //             isDragging.current = false;
    //             setEl((prev) => ({
    //                 ...prev,
    //                 position: {
    //                     ...prev.position,
    //                     x: pos.current.x,
    //                     y: pos.current.y,
    //                 },
    //             }));
    //             // Update the element's position in the Redux state or context here
    //             dispatch(elementChanged({
    //                 ...element,
    //                 position: {
    //                     ...element.position,
    //                     x: pos.current.x,
    //                     y: pos.current.y,
    //                 },
    //             }));
    //         }
    //     };

    //     document.addEventListener('mousemove', handleMouseMove);
    //     document.addEventListener('mouseup', handleMouseUp);

    //     return () => {
    //         document.removeEventListener('mousemove', handleMouseMove);
    //         document.removeEventListener('mouseup', handleMouseUp);
    //     };
    // }, [dispatch, element]);

    // const handleMouseDown = (e) => {
    //     isDragging.current = true;
    //     startPos.current = { x: e.clientX, y: e.clientY };
    //     pos.current = { x: element.position.x, y: element.position.y };
    // };

    const handleRegenerateTextWithAI = (event) => {
      console.log("🚀 ~ handleRegenerateTextWithAI ~ event:", event)
      setElementType('TOOLBAR_RECREATEWITHAI');
    };

    return (
      <ToolbarContainer isVisible={isVisible}>
        <SetBg single={true} sx={{ cursor: 'rotate' }} onClick={handleMouseDownRotate} className="text-rotate">
          <AutorenewIcon />
        </SetBg>
        <SetBg single={false}>
          <Box
            display="flex"
            alignItems={'center'}
            gap="10px"
            sx={{ cursor: 'pointer' }}
            onClick={handleRegenerateTextWithAI}>
            <AiIcon />
            <Typography variant="body2">Recreate with AI Text</Typography>
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={() => dispatch(handleRemoveElement(element))}>
            <DeleteOutlineIcon />
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={(e) => handleContextMenu(e, element)}>
            <MoreHorizIcon />
          </Box>
        </SetBg>
        <SetBg single={true} sx={{ cursor: 'move' }} onMouseDown={handleMouseDownDrag} className="text-move">
          <MoveIcon className="text-move" />
        </SetBg>
      </ToolbarContainer>
    );
  }
);

TextToolbar.displayName = 'TextToolbar';

export default TextToolbar;
