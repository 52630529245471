import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { palette } from 'config/theme';

export const TextInput = styled(TextField)`
  background: #fff;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  .MuiOutlinedInput-root {
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    color: #595d62;
  }

  .MuiInputBase-input {
    height: 30px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
  .MuiInputBase-input::placeholder {
    color: #595d62;
    opacity: 1; /* Make sure the opacity is set if needed */
  }
`;

export const DropdownInput = styled(Select)`
  background: #fff;
  color: #595d62;
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  .MuiOutlinedInput-root {
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    color: #595d62;
  }

  .MuiSelect-select {
    padding: 10px 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
`;
