import {
  COPY_ELEMENT,
  ELEMENT_ADD,
  ELEMENT_CHANGED,
  ELEMENT_DELETED,
  ELEMENT_UPDATED,
  NEW_SLIDE_ADD,
  PROJECT_ERROR,
  PROJECT_TITLE,
  PROJECT_UPDATED,
  REDO,
  SLIDE_ADDED,
  SLIDE_DELETED,
  SLIDE_REPLACE,
  SLIDE_SELECTION_CHANGED,
  SLIDE_UPDATED,
  UNDO,
} from './constants';
import { produce } from 'immer';
import _ from 'lodash';

const INIT_STATE = {
  past: [],
  present: {
    loading: false,
    project: {},
    selectedElement: {},
    selectedSlide: {},
    slides: [],
    elements: [],
    isError: false,
    error: null,
    title: '',
    projectId: null,
    copiedElement: {},
  },
  future: [],
};

const applyChange = (state, callback) => {
  let { past, present, future } = state;
  let newPresent = produce(present, callback);
  return {
    past: [...past, present],
    present: _.cloneDeep(newPresent),
    future: [],
  };
};

const Canvas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROJECT_UPDATED:
      return applyChange(state, (draft) => {
        draft.project = action.payload.project;
        if (action.payload.slides) {
          draft.slides = action.payload.slides;
        }
        if (action.payload.title) {
          draft.title = action.payload.title;
        }
        if (action.payload.elements) {
          draft.elements = action.payload.elements;
        }
        if (action.payload.selectedSlide) {
          draft.selectedSlide = action.payload.selectedSlide;
        }
        if (action.payload.projectId) {
          draft.projectId = action.payload.projectId;
        }
        draft.selectedElement = {};
      });
    case SLIDE_SELECTION_CHANGED:
      return applyChange(state, (draft) => {
        draft.selectedSlide = action.payload.selectedSlide;
        draft.selectedElement = {};
      });
    case SLIDE_ADDED:
      return applyChange(state, (draft) => {
        if (action.payload.index === -1) {
          draft.slides.push(...action.payload.slide);
        } else {
          let slideIndex = _.findIndex(draft.slides, { id: draft.selectedSlide.id });
          draft.slides.splice(slideIndex + 1, 0, action.payload.slide);
        }
        draft.project.slides = draft.slides;
      });
    case SLIDE_DELETED:
      return applyChange(state, (draft) => {
        let slideIndex = _.findIndex(draft.slides, { id: draft.selectedSlide.id });
        draft.slides.splice(slideIndex, 1);
        draft.project.slides = draft.slides;
        draft.selectedSlide = draft.slides[0];
      });
    case ELEMENT_CHANGED:
      return applyChange(state, (draft) => {
        draft.selectedElement = action.payload.show;
        let elemIndex = _.findIndex(draft.selectedSlide.elements, { id: action.payload.show.id });
        if (elemIndex !== -1) {
          draft.selectedSlide.elements[elemIndex] = action.payload.show;
        }
        let slideIndex = _.findIndex(draft.project.slides, { id: draft.selectedSlide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = draft.selectedSlide;
        }
        let slidesIndex = _.findIndex(draft.slides, { id: draft.selectedSlide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = draft.selectedSlide;
        }
      });
    case ELEMENT_UPDATED:
      return applyChange(state, (draft) => {
        draft.selectedSlide = action.payload.slide;
        let slideIndex = _.findIndex(draft.project.slides, { id: action.payload.slide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = action.payload.slide;
        }
        let elementIndex = _.findIndex(draft.selectedSlide.elements, { id: draft.selectedElement.id });
        if (elementIndex !== -1) {
          draft.selectedElement = draft.selectedSlide.elements[elementIndex];
        }
        let slidesIndex = _.findIndex(draft.slides, { id: action.payload.slide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = action.payload.slide;
        }
      });
    case ELEMENT_ADD:
      return applyChange(state, (draft) => {
        draft.selectedSlide = action.payload.slide;
        let slideIndex = _.findIndex(draft.project.slides, { id: action.payload.slide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = action.payload.slide;
        }
        let slidesIndex = _.findIndex(draft.slides, { id: action.payload.slide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = action.payload.slide;
        }
        draft.selectedElement = action.payload.element;
      });
    case SLIDE_UPDATED:
      return applyChange(state, (draft) => {
        draft.selectedSlide = action.payload.slide;
        let slideIndex = _.findIndex(draft.project.slides, { id: action.payload.slide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = action.payload.slide;
        }

        let slidesIndex = _.findIndex(draft.slides, { id: action.payload.slide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = action.payload.slide;
        }
      });
    case SLIDE_REPLACE:
      return applyChange(state, (draft) => {
        let slideIndex = _.findIndex(draft.project.slides, { id: draft?.selectedSlide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = action.payload.slide;
        }

        let slidesIndex = _.findIndex(draft.slides, { id: draft?.selectedSlide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = action.payload.slide;
        }
        draft.selectedSlide = action.payload.slide;
      });
    case NEW_SLIDE_ADD:
      return applyChange(state, (draft) => {
        const slide = action.payload.slide;

        const projectSlideIndex = _.findIndex(draft.project.slides, { id: draft?.selectedSlide.id });
        if (projectSlideIndex !== -1) {
          draft.project.slides[projectSlideIndex] = slide;
        }

        const slidesIndex = _.findIndex(draft.slides, { id: draft?.selectedSlide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = slide;
        }

        draft.selectedSlide = slide;
      });

    case ELEMENT_DELETED:
      return applyChange(state, (draft) => {
        // draft.selectedSlide = action.payload.slide;
        let elementIndex = _.findIndex(draft.selectedSlide.elements, { id: action.payload.element.id });
        if (elementIndex !== -1) {
          draft.selectedSlide.elements.splice(elementIndex, 1);
        }
        let slideIndex = _.findIndex(draft.project.slides, { id: draft.selectedSlide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = draft.selectedSlide;
        }
        let slidesIndex = _.findIndex(draft.slides, { id: draft.selectedSlide.id });
        if (slidesIndex !== -1) {
          draft.slides[slidesIndex] = draft.selectedSlide;
        }
      });
    case PROJECT_ERROR:
      return applyChange(state, (draft) => {
        draft.error = action.payload;
        draft.isError = true;
      });
    case PROJECT_TITLE:
      return applyChange(state, (draft) => {
        draft.title = action.payload?.value;
      });
    case UNDO:
      if (state.past.length > 1) {
        let newPresent = state.past[state.past.length - 1];
        return {
          past: state.past.slice(0, state.past.length - 1),
          present: { ..._.cloneDeep(newPresent) },
          future: [state.present, ...state.future],
        };
      }
      return state;

    case REDO:
      if (state.future.length > 0) {
        let newPresent = state.future[0];
        return {
          past: [...state.past, state.present],
          present: { ..._.cloneDeep(newPresent) },
          future: state.future.slice(1),
        };
      }
      return state;
    case COPY_ELEMENT:
      return applyChange(state, (draft) => {
        draft.copiedElement = action.payload.element;
      });
    default:
      return state;
  }
};

export default Canvas;
