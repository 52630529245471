import React, { useState } from 'react';
import { AddSlideContainer, Preview, ScrollableStackContainer, SlideContainer } from './styles';
import { Box, Button, Divider, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Spacer } from 'components/shared';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import SlidePreview from '../Components/SlidePreview';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { palette } from 'config/theme';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import AITemplate from './AITemplate';
import { slideadded } from 'redux-layout/actions';

const params = {
  isDisplay: {
    $eq: true,
  },
};
const AddSlide = ({ onClose, setOpen }) => {
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState('');
  const { data, count, error, loading, loadMore, searchItems } = usePaginatedApi(
    'slide-templates',
    '100',
    params,
    'default'
  );
  const [selectedSlide, setSelectedSlide] = useState({});
  const handleSearchItem = (e) => {
    searchItems(e.target.value, 'name');
    setSearchItem(e.target.value);
  };

  const handleAddSlide = () => {
    if (selectedSlide?.attributes?.slide?.children?.length > 0) {
      dispatch(slideadded(selectedSlide?.attributes?.slide?.children, -1));
    } else {
      dispatch(slideadded([selectedSlide?.attributes?.slide], -1));
    }
    onClose();
  };
  return (
    <AddSlideContainer>
      <Grid container height="85%">
        <Grid item xs={3.65} padding={'1rem'} height="100%" display={'flex'} flexDirection={'column'}>
          {/* <AITemplate setOpen={setOpen} /> */}
          <TextField
            id="outlined-basic"
            placeholder="Search"
            value={searchItem}
            onChange={handleSearchItem}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Spacer x={1} y={1} />
          <ScrollableStackContainer>
            <Stack direction={'column'} spacing={2}>
              {error ? (
                <Typography variant="body2">Error fetching slides</Typography>
              ) : (
                data
                  .sort((a, b) => a?.attributes?.order - b?.attributes?.order)
                  .map((slide, index) => (
                    <Box key={index}>
                      <SlideContainer
                        isselected={selectedSlide.id === slide.id ? true : undefined}
                        onClick={() => setSelectedSlide(slide)}>
                        <SlidePreview slide={slide?.attributes?.slide} />
                      </SlideContainer>
                      <Spacer x={1} y={1} />
                      <Typography variant="body2">{slide?.attributes?.name}</Typography>
                    </Box>
                  ))
              )}
              {loading ? (
                <Typography variant="body2">Loading...</Typography>
              ) : count < data.length ? null : (
                <Stack direction={'row'} spacing={2} justifyContent={'center'} alignItems={'center'} onClick={loadMore}>
                  <KeyboardArrowDownIcon />
                </Stack>
              )}
            </Stack>
          </ScrollableStackContainer>
        </Grid>
        <Grid
          item
          xs={8.35}
          sx={{ background: palette.tab.background }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}>
          {selectedSlide?.attributes?.slide?.elements?.length > 0 && (
            <Preview>
              <SlidePreview slide={selectedSlide?.attributes?.slide} />
            </Preview>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={2} padding="1rem">
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleAddSlide}>
          Add
        </Button>
      </Box>
    </AddSlideContainer>
  );
};

export default AddSlide;
