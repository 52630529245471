import styled from '@emotion/styled';


export const ToolbarContainer = styled.div`
    display: ${props => props.isVisible ? 'flex' : 'none'};
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    position:absolute;
    top: -75px;
`;

export const SetBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-radius: ${props => props.single ? '50%' : '5px'};
    padding: 5px;
    height:40px;
    min-width: 40px;
    gap:${props => props.single ? '0' : '10px'};
`;
