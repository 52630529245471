import React, { forwardRef } from 'react';
import { Card as MuiCard, CardContent, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Spacer from 'components/shared/Spacer';
import { palette } from 'config/theme';

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageBox = styled.div`
  width: 100%;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || '1/1'};
  position: relative;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  border-radius: 4px;
`;

const Tag = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 4px;
  background-color: #e2e2e2;
  color: rgba(0, 0, 0, 1);
  font-size: 10px;
  font-weight: 400;
  border-radius: 4px;
`;

const MuiCardRoot = styled(MuiCard)`
  box-shadow: none;

  .MuiCardContent-root {
    padding-bottom: 0 !important;
    ${({ padding }) => padding && `padding: ${padding} !important;`}
  }
`;

const Card = forwardRef(({ tag, image, name, ratio, bgColor, padding, isDate = false, timeAgo = '' }, ref) => {
  return (
    <MuiCardRoot padding={padding}>
      <CardContent>
        <ImageBox aspectRatio={ratio} bgColor={bgColor}>
          <CardImg src={image} alt={name} />
          {tag && (
            <Tag>
                {tag}
            </Tag>
          )}
        </ImageBox>
        <Spacer x={1} y={1} />
        <Box>
          <Typography variant="body3">{name}</Typography>
        </Box>
        {isDate ? (
          <Box>
            <Typography variant="small" sx={{ color: palette.text.grey3 }}>
              {timeAgo}
            </Typography>
          </Box>
        ) : null}
      </CardContent>
    </MuiCardRoot>
  );
});

Card.propTypes = {
  tag: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  ratio: PropTypes.string,
  bgColor: PropTypes.string,
  padding: PropTypes.string,
};

Card.displayName = 'Card';

export default Card;
