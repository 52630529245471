import React from 'react';
import { BoxContainer, PhotoContainer } from './styles';
import { Button, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import { slideUpdated } from 'redux-layout/actions';
import { useDispatch } from 'react-redux';

const AllBackgroundUploadsAssets = ({ elementType, setElementType }) => {
  const dispatch = useDispatch();
  const elementConfigs = {
    BACKGROUNDUPLOADS: { api: 'assets', params: { contenttype: { $eq: 'background' }, type: { $eq: 'user' } } },
  };
  const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };
  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams);
  const handleBack = () => {
    setElementType('BACKGROUND');
  };
  const handleBackgroundImgAdd = (item, index) => {
    const url = 'url("' + baseUrl + (item?.url || '') + '")';
    dispatch(slideUpdated('background-image', url));
  };
  return (
    <PhotoContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <Grid container spacing={2} sx={{ p: 2 }}>
        {error ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {data.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    <BoxContainer>
                      <IconButton
                        onClick={() => handleBackgroundImgAdd(item?.attributes?.asset?.data[0]?.attributes, index)}>
                        <img
                          className="img-contain"
                          src={`${baseUrl}${item?.attributes?.asset?.data[0]?.attributes?.url}`}
                          alt={`${baseUrl}${item?.attributes?.asset?.data[0]?.attributes?.url}`}
                        />
                      </IconButton>
                    </BoxContainer>
                  </Grid>
                );
              })}
            </Grid>
            {loading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {data?.length === count ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={loadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </PhotoContainer>
  );
};

export default AllBackgroundUploadsAssets;
