import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { CustomSwitch, ParagraphBox, ParagraphBoxButton, PreviewBox, TextInput } from './styles';
import { TextLeftIcon, TextNumberIcon, TextBulletPointIcon } from 'pages/studio/Components/SVGs';
import { palette } from 'config/theme';

const ParagraphOptions = ({ previewText, activeButton, setActiveButton, text, setText, handleRegenerateText }) => {
  const [isTextInputVisible, setIsTextInputVisible] = useState(false);

  const handleSwitchChange = (event) => {
    setIsTextInputVisible(event.target.checked);
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const formatText = () => {
    const lines = previewText && previewText?.split('\n').filter((line) => line.trim() !== '');

    if (activeButton === 1) {
      // Numbered list
      return (
        <ol>
          {lines.map((line, i) => (
            <li key={i}>{line}</li>
          ))}
        </ol>
      );
    } else if (activeButton === 2) {
      // Bulleted list
      return (
        <ul>
          {lines.map((line, i) => (
            <li key={i}>{line}</li>
          ))}
        </ul>
      );
    } else {
      // Default (plain text)
      return (
        <Typography
          variant="body2"
          sx={{
            color: palette?.background?.dark,
            whiteSpace: 'pre-line',
          }}>
          {previewText}
        </Typography>
      );
    }
  };

  return (
    <React.Fragment>
      <Typography variant="body2" sx={{ color: palette?.text?.grey3, mt: 1, mb: 1 }}>
        Paragraph Options
      </Typography>
      <ParagraphBox>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'} sx={{ height: '45px' }}>
          <ParagraphBoxButton onClick={() => handleButtonClick(0)} active={activeButton === 0}>
            <TextLeftIcon />
          </ParagraphBoxButton>
          <ParagraphBoxButton onClick={() => handleButtonClick(1)} active={activeButton === 1}>
            <TextNumberIcon />
          </ParagraphBoxButton>
          <ParagraphBoxButton onClick={() => handleButtonClick(2)} active={activeButton === 2}>
            <TextBulletPointIcon />
          </ParagraphBoxButton>
        </Stack>
      </ParagraphBox>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ width: '100%', mt: 1, mb: 1 }}>
        <Typography variant="body2" sx={{ color: palette?.text?.grey3 }}>
          Advanced guidelines
        </Typography>
        <CustomSwitch checked={isTextInputVisible} onChange={handleSwitchChange} />
      </Stack>
      {isTextInputVisible && (
        <>
          <TextInput
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="example: make it funnier"
            variant="outlined"
            multiline
            sx={{ mt: 2 }}
          />
          <Button
            onClick={handleRegenerateText}
            variant="contained"
            color="secondary"
            sx={{ width: '100%', mt: 1, mb: 1 }}>
            Regenerate Text
          </Button>
        </>
      )}

      <Typography variant="body2" sx={{ color: palette?.text?.grey3 }}>
        Preview
      </Typography>
      <PreviewBox
        sx={{
          textAlign: activeButton === 0 ? 'left' : 'inherit',
        }}>
        <Typography
          variant="body2"
          sx={{
            color: palette?.background?.dark,
            whiteSpace: 'pre-line',
          }}>
          {formatText()}
        </Typography>
      </PreviewBox>
    </React.Fragment>
  );
};

export default ParagraphOptions;
