import styled from '@emotion/styled';
import { Box, Button, Checkbox, TextField } from '@mui/material';

export const EmbedContainer = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  ${'' /* gap: 16px; */}
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;

export const TextBoxButton = styled(Button)`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #009569;
  background: #009569;
  color: #fff;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: #009569;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;

export const PreviewBox = styled(Box)`
  background-color: #595d62;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const TextInput = styled(TextField)`
  border-radius: 8px;
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  .MuiInputBase-input {
    min-height: 49px;
    padding: 8px 12px;
  }
  .MuiInputBase-input::placeholder {
    color: #595d62;
    opacity: 1;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;
