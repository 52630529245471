import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm, Field as FormikField } from 'formik';
import { get, mapValues } from 'lodash';
import Field from './field';
import { RadFormik } from './useFormik';

const propTypes = {
  validate: PropTypes.func,
  validations: PropTypes.object,
  validateOnBlur: PropTypes.bool,
};

const Form = ({
  validate = undefined,
  validations = undefined,
  validationSchema,
  ...otherProps
}) => {
  return (
    <RadFormik validationSchema={validationSchema}>
      <Formik
        {...otherProps}
        validationSchema={validationSchema}
        validate={(values) => {
          if (validate) {
            return validate(values);
          }
          return false;
        }}
      />
    </RadFormik>
  );
};

Form.displayName = 'Form';

Form.Element = (props) => <FormikForm noValidate {...props} />;
Form.Element.displayName = 'Form.Element';

Form.Field = mapValues(Field, (FieldComponent) => {
  const FieldWrapper = ({ name, validate, ...props }) => {
    return (
      <FormikField name={name} validate={validate}>
        {({ field, form: { touched, errors, setFieldValue, setFieldTouched, ...otherProp } }) => {
          return (
            <FieldComponent
              {...field}
              {...props}
              name={name}
              error={get(touched, name) && get(errors, name)}
              onChange={(value) => {
                if (props?.onChange && typeof props.onChange === 'function') {
                  props.onChange(value);
                }
                setFieldValue(name, value);
              }}
              onBlur={(e) => {
                if (props?.onBlur && typeof props.onBlur === 'function') {
                  props.onBlur(e.target.value);
                }
                setFieldTouched(name, true);
              }}
            />
          );
        }}
      </FormikField>
    );
  };

  FieldWrapper.displayName = `Form.Field(${FieldComponent.displayName || FieldComponent.name || 'Component'})`;
  return FieldWrapper;
});

Form.initialValues = (data, getFieldValues) =>
  getFieldValues((key, defaultValue = '') => {
    const value = get(data, key);
    return value === undefined || value === null ? defaultValue : value;
  });

Form.handleAPIError = (error, form) => {
  if (error.data.fields) {
    form.setErrors(error.data.fields);
  }
};

Form.propTypes = propTypes;

export default Form;
