import { CircularProgress, Stack } from '@mui/material';
import { palette } from 'config/theme';
import React from 'react';

const Loader = () => {
  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress sx={{ color: palette?.background?.default }} />
    </Stack>
  );
};

export default Loader;
