import React, { useCallback, useEffect, useRef } from 'react';
import { Container, CanvasBox } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import { copyElement, elementChanged, elementUpdated, handleRedo, handleRemoveElement, handleUndo, pasteElement } from 'redux-layout/actions';
import CanvasElements from './CanvasElement';
import { Item, Menu as LolMenu, Submenu } from 'react-contexify';
import { COMPLEX_TEXT, MENU_ID, TEXT } from './config';
import ContextItem from '../Components/ContextifyItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AlignBottom, AlignHorizontal, AlignLeft, AlignRight, AlignTop, AlignVertical } from 'config/svgs';

const CanvasFrame = ({ style, setStyle }) => {
    const dispatch = useDispatch();
    const lcanvasRef = useRef(null);
    const canvasFrameRef = useRef(null);
    const { project, selectedSlide, selectedElement } = useSelector((state) => state.Canvas.present);
    const pastArray = useSelector((state) => state.Canvas.past);
    const futureArray = useSelector((state) => state.Canvas.future);

    useEffect(() => {
        let el = $('#canvasFrame');
        let hight = project.meta.size.height || '500';
        let width = project.meta.size.width || '1000';
        let top = (el.height() - (el.width() * 9) / 16) / 2;
        let left = (el.width() - (el.height() * 16) / 9) / 2;
        let elh = el.height();
        let elw = el.width();

        if (width > el.width()) {
            hight = (el.width() * 9) / 16;
            setStyle({
                height: hight + 'px',
                width: elw + 'px',
                top: top + 'px',
                left: '0px',
            });
        } else if (hight > el.height()) {
            width = (el.height() * 16) / 9;
            setStyle({
                height: elh + 'px',
                width: width + 'px',
                top: '0px',
                left: left,
            });
        } else {
            width = (el.height() * 16) / 9;
            setStyle({
                height: elh + 'px',
                width: width + 'px',
                top: '0px',
                left: left,
            });
        }


    }, [project]);
    const handleDeleteElement = (e) => {
        let element = e.props.element;

    };


    const handleContext = ({ id, event, props }) => {
        const { element } = props;
        let canvasRef = canvasFrameRef.current;
        let el = canvasRef.getBoundingClientRect();
        let elWidth = 0;
        if (typeof element.position.width === 'string') {
            if (element.position.width.includes('%')) {
                elWidth =
                    (parseFloat(element.position.width.replace('%', '')) * el.width) /
                    100;
            } else if (element.position.width.includes('px')) {
                elWidth = parseFloat(element.position.width.replace('px', ''));
            } else {
                elWidth = (parseFloat(element.position.width) * el.width) / 100;
            }
        } else {
            elWidth = (element.position.width * el.width) / 100;
        }
        let elHeight = 0;
        if (typeof element.position.height === 'string') {
            if (element.position.height.includes('%')) {
                elHeight =
                    (parseFloat(element.position.height.replace('%', '')) * el.height) /
                    100;
            } else if (element.position.height.includes('px')) {
                elHeight = parseFloat(element.position.height.replace('px', ''));
            } else {
                elHeight = (parseFloat(element.position.height) * el.height) / 100;
            }
        } else {
            elHeight = (element.position.height * el.height) / 100;
        }
        switch (id) {
            case 'copy':
                dispatch(copyElement(element));
                break;
            case 'paste':
                dispatch(pasteElement());
                break;
            case 'delete':
                dispatch(handleRemoveElement(props.element));
                break;
            case 'left':
                dispatch(elementUpdated(element, 0, 'left'));
                break;
            case 'center':
                let percentageWidth = ((el.width / 2 - elWidth / 2) * 100) / el.width;
                let percentageheight = ((el.height / 2 - elHeight / 2) * 100) / el.height;
                element.position.left = percentageWidth;
                element.position.top = percentageheight;
                dispatch(elementChanged(element));
                break;
            case 'right':
                let percentageRightWidth = ((el.width - elWidth) * 100) / el.width;
                dispatch(elementUpdated(element, percentageRightWidth, 'left'));
                break;
            case 'top':
                dispatch(elementUpdated(element, 0, 'top'));
                break;
            case 'middle':
                let percentageMiddleWidth = ((el.width / 2 - elWidth / 2) * 100) / el.width;
                dispatch(elementUpdated(element, percentageMiddleWidth, 'left'));
                break;
            case 'bottom':
                let percentageBottomHeight = ((el.height - elHeight) * 100) / el.height;
                dispatch(elementUpdated(element, percentageBottomHeight, 'top'));
                break;
            case 'bring_front':
            case 'bring_forward':
            case 'send_backward':
            case 'send_back':
                dispatch(elementUpdated(element, id, 'z-index'));
                break;
            default:
                break;
        }
    };

    const handleKeyPress = useCallback((event, props) => {
        const isMac = navigator.platform.toUpperCase().includes('MAC');
        const deleteKey = isMac ? 'Backspace' : 'Delete';

        let carretPosition = window.getSelection().rangeCount;
        const activeElement = document.activeElement;
        if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
            const caretPosition = activeElement.selectionStart;
            return;
        }

        if (['Backspace', 'Delete'].includes(event.key)) {
            if (event.key === deleteKey && selectedElement.id) {
                if (selectedElement.type === TEXT || selectedElement.type === COMPLEX_TEXT) {
                    let carretPosition = window.getSelection().anchorOffset;
                    if (carretPosition > 0) {
                        return;
                    } else {
                        dispatch(handleRemoveElement(selectedElement));
                    }
                } else {
                    dispatch(handleRemoveElement(selectedElement));
                }
            }
            return;
        }

        if (event.ctrlKey || event.metaKey) {
            switch (event.key) {
                case 'c':
                    dispatch(copyElement(selectedElement));
                    break;
                case 'v':
                    dispatch(pasteElement());
                    break;
                case 'x':
                    if (selectedElement?.id) {
                        dispatch(copyElement(selectedElement));
                        dispatch(handleRemoveElement(selectedElement));
                    }
                    break;
                case 'z':
                    if (pastArray.length > 1) {
                        dispatch(handleUndo());
                    }
                    break;
                case 'y':
                    if (futureArray.length > 0) {
                        dispatch(handleRedo());
                    }
                    break;
                default:
                    break;
            }
        }
    }, [selectedElement]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);


    return (
        <Container>
            <CanvasBox
                id="canvasFrame"
                ref={canvasFrameRef}
                onDoubleClick={(e) => {
                    let id = e.target.id;
                    if (id === 'lcanvas' || id === 'canvasFrame') {
                        dispatch(elementChanged({}));
                    }
                }}
                onClick={(e) => {
                    // if (e.target.offsetParent?.className === 'rsw-ce' || e.target.className.includes('resizer') || e.target.className.includes('resizable-draggable') || e.target.className.includes('rsw-editor')) {
                    //     return;
                    // }
                    // if (!(e?.target?.className === 'edit-textarea') && !(e?.target?.className === 'iframe-wrapper') && !(e?.target?.className === 'rsw-ce') && !(e?.target?.className === 'rsw-toolbar')) {
                    //     if (typeof e?.target?.className === 'string') {
                    //         if (!e?.target?.className?.includes('parent-marker') && !e?.target?.className?.includes('resize-handle')) {
                    //             dispatch(elementChanged({}));
                    //         }
                    //     }
                    // }
                }}>
                <Box
                    id="lcanvas"
                    className="lcanvas"
                    ref={lcanvasRef}
                    style={{
                        width: style.width,
                        height: style.height,
                        borderRadius: '10px',
                        position: 'absolute',
                        // maxWidth: 'calc(100vw - 110px)',
                        // maxHeight: 'calc(100vh)',
                        backgroundImage: selectedSlide.style ? selectedSlide.style['background-image'] : '',
                        backgroundColor: selectedSlide.style ? selectedSlide.style['background-color'] : '',
                        objectFit: 'cover',
                        backgroundSize: 'cover',
                    }}>
                    <LolMenu id={MENU_ID} className="custom-lolmenu">
                        <Item id="copy" className="custom-menu-item" onClick={handleContext}>
                            <ContextItem icon={<ContentCopyIcon />} text="Copy" shortcut='Ctrl+C' />
                        </Item>
                        {/* <Item id="copy_style" className="custom-menu-item" onClick={handleContext}>
                            <ContextItem icon={<CopyStyle />} text="Copy style" shortcut='Shift+Ctrl+C' />
                        </Item> */}
                        <Item id="paste" className="custom-menu-item" onClick={handleContext}>
                            <ContextItem icon={<ContentPasteIcon />} text="Paste" shortcut='Ctrl+V' />
                        </Item>
                        <Item id="delete" onClick={handleContext} className="custom-menu-item">
                            <ContextItem icon={<DeleteOutlineIcon />} text="Delete" shortcut='DELETE' />
                        </Item>
                        <Divider />
                        <Submenu label="Align" className="custom-menu-item">
                            <Item id="left" className="custom-menu-item" onClick={handleContext}>
                                <ContextItem icon={<AlignLeft />} text="Left" />
                            </Item>
                            <Item id="center" className="custom-menu-item" onClick={handleContext}>
                                <ContextItem icon={<AlignVertical />} text="Center" />
                            </Item>
                            <Item id="right" className="custom-menu-item" onClick={handleContext}>
                                <ContextItem icon={<AlignRight />} text="Right" />
                            </Item>
                            <Divider />
                            <Item id="top" className="custom-menu-item" onClick={handleContext}>
                                <ContextItem icon={<AlignTop />} text="Top" />
                            </Item>
                            <Item id="middle" className="custom-menu-item" onClick={handleContext}>
                                <ContextItem icon={<AlignHorizontal />} text="Middle" />
                            </Item>
                            <Item id="bottom" className="custom-menu-item" onClick={handleContext}>
                                <ContextItem icon={<AlignBottom />} text="Bottom" />
                            </Item>
                        </Submenu>
                        <Submenu label="Layers" className="custom-menu-item">
                            <Item id="bring_front" onClick={handleContext} className="custom-menu-item">
                                <ContextItem icon={<KeyboardDoubleArrowUpIcon />} text="Bring to front" shortcut='Shift+Ctrl+]' />
                            </Item>
                            <Item id="bring_forward" onClick={handleContext} className="custom-menu-item">
                                <ContextItem icon={<KeyboardArrowUpIcon />} text="Bring forward" shortcut='Ctrl+]' />
                            </Item>
                            <Item id="send_backward" onClick={handleContext} className="custom-menu-item">
                                <ContextItem icon={<KeyboardArrowDownIcon />} text="Send Backward" shortcut='Ctrl+[' />
                            </Item>
                            <Item id="send_back" onClick={handleContext} className="custom-menu-item">
                                <ContextItem icon={<KeyboardDoubleArrowDownIcon />} text="Send to back" shortcut='Shift+Ctrl+[' />
                            </Item>
                        </Submenu>
                    </LolMenu>
                    {/* Hello Canvas */}
                    {selectedSlide?.elements?.length > 0 && selectedSlide.elements.filter((el) => el).map((el, index) => (
                        <CanvasElements el={el} index={el.id} key={el.id} style={style} setStyle={setStyle} />
                    ))}
                </Box>
            </CanvasBox>
        </Container>
    );
};

export default CanvasFrame;
