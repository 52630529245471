import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TextareaAutosize } from '@mui/base';

import { StyledTextarea } from './styles';
import { FormHelperText, Box } from '@mui/material';

const propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool,
    minRows: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

const Textarea = forwardRef(
    ({ className, invalid, onChange, error, helperText, ...textareaProps }, ref) => (
        <StyledTextarea className={className} invalid={invalid}>
            <TextareaAutosize
                {...textareaProps}
                onChange={(event) => onChange(event.target.value, event)}
                inputref={ref || undefined}
                style={{
                    borderRadius: '4px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    padding: '16.5px 14px',
                    fontSize: '16px',
                    fontFamily: 'Roboto,sans-serif',
                    resize: 'none',
                    width: 'calc(100%)',
                    marginTop: '16px',
                }}
            />
            {helperText && helperText !== '' && (
                <Box sx={{ margin: '0px 14px' }}>
                    <FormHelperText error={error}>{helperText}</FormHelperText>
                </Box>
            )}
        </StyledTextarea>
    ),
);

Textarea.propTypes = propTypes;
Textarea.displayName = 'Textarea';

export default Textarea;
