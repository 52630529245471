import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Template } from './styles';
import SlidePreview from 'pages/studio/Components/SlidePreview';

const TemplateCard = ({ item, index, selected, setSelected }) => {
    return (
        <Grid item xs={4} onClick={() => setSelected(item)} key={index}>
            {console.log('selectedItem', selected, item.id)}
            <Template selected={selected?.id} index={item?.id}>
                <Box width="100%" height="100%" sx={{ background: item?.background || '#968F8F' }}>
                    {item?.slides?.length > 0 && <SlidePreview slide={item.slides[0]} />}
                </Box>
            </Template>
            <Typography variant="body2">{item.name}</Typography>
        </Grid>
    );
};

export default TemplateCard;
