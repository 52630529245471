import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoutes';


export const renderRoutes = (mainRoutes) => {
    const Routes = ({ isAuthorized }) => {
        const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
            return (
                <Route key={index} element={<Layout />}>
                    {routes.map(({ component: Component, path, name, isPublic }, id) => {
                        return (
                            <Route element={<ProtectedRoute isAuthorized={isAuthorized} isPublic={isPublic} />} key={id}>
                                {Component && path && <Route key={name} element={<Component />} path={path} />}
                            </Route>
                        );
                    })}
                </Route>
            );
        });
        return <ReactRoutes>{layouts}</ReactRoutes>;
    };
    return Routes;
};
