import React, { useState } from 'react';
import { BoxContainer } from './style';
import { Button, CircularProgress, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import MusicButton from './MusicButton';

const UploadedAudio = ({ title, subTitle, audioData, elementLoading, elementError, elementLoadMore, elementCount,elementType, setElementType }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const handleMusicButtonClick = (index) => {
    setSelectedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <React.Fragment>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
          {title}
        </Typography>
         <Button variant="seeAll" onClick={() => setElementType('AUDIOUPLOADS')}>{subTitle}</Button>
      </Stack>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {audioData.map((item, index) => {
                const isSelected = selectedItemIndex === index;
                return (
                  <Grid item key={index} xs={12}>
                    <MusicButton
                      isChecked={isSelected}
                      onClick={() => handleMusicButtonClick(index)}
                      label="Music Name"
                    />
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading && (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default UploadedAudio;
