import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { palette } from 'config/theme';

export const ShortcutText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.black,
  borderRadius: '4px',
  background: theme.palette.text.grey4,
  padding: '2px',
}));

export const FlexMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '0px',
  paddingLeft: '0px',
  height: '35px !important',
}));

export const ChildMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: '0 !important',
  ':hover': {
    backgroundColor: 'transparent !important',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent !important',
  },
  '&.MuiMenuItem-root.Mui-selected': {
    backgroundColor: 'transparent !important',
  },
  '&.MuiMenuItem-root.Mui-selected:hover': {
    backgroundColor: 'transparent !important',
  },
}));

export const TextInput = styled(TextField)`
  background: #fff;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;

  .MuiOutlinedInput-root {
    border-radius: 8px;
    border: 1px solid #e2e2e2;
  }

  .MuiInputBase-input {
    height: 30px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
`;

export const ModalContainer = styled('div')`
  height: 570px;
  width: 800px;
  position: relative;
  z-index: 1;
  padding: 1rem 0;
  background-color: ${palette.background.paper};
  overflow-x: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar {
    width: 6px; /* Thinner vertical scrollbar */
    height: 6px; /* Thinner horizontal scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const CreateLessonModal = styled('div')`
  ${'' /* height: 570px; */}
  width: 500px;
  ${'' /* padding: 16px; */}
  background-color: ${palette.background.paper};
  border-radius: 16px;
`;

export const PublishModalContainer = styled('div')`
  height: 570px;
  width: 800px;
  position: relative;
  z-index: 1;
  padding: 20px;
  background-color: ${palette.background.paper};
  overflow-x: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const MoreButton = styled('div')`
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 7px;
  width: 1.5rem;
  height: 1.2rem;
  display: ${({ type }) => (type === 'slide' ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: ${palette.background.dark};
  color: ${palette.background.paper};
  cursor: pointer;
`;

export const GridContainer = styled(Grid)`
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const CustomButton = styled(Button)`
  background-color: #e2e2e2;
  border-radius: 4px;
  color: #000000;
`;

export const CardBox = styled(Box)`
  position: relative;
  border: ${({ selected }) => (selected ? '2px solid #1ed4c1' : 'none')};
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  margin-left: 16px;
  ${'' /* margin-top: 10px; */}
  padding: ${({ selected }) => (selected ? '12px' : '2px')};

  &:hover .more-icon {
    display: flex;
  }
`;
