import React from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    FormGroup,
    FormControlLabel,
    FormControl,
    Box,
    FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 36,
    height: 18,
    padding: 0,
    display: 'flex',
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
        padding: 3,
        color: theme.palette.grey[500],
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 36 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        boxSizing: 'border-box',
    },
}));

function SwitchComponent({
    label,
    labelPlacement,
    color,
    onChange,
    error,
    value,
    helperText,
    sx,
    ...props
}) {
    const handleChange = (event) => {
        onChange(!value);
    };

    return (
        <Box mt={2} sx={sx}>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        value={value}
                        control={<AntSwitch color={color} checked={value} onChange={handleChange} />}
                        label={label}
                        labelPlacement={labelPlacement}
                        {...props}
                    />
                    {helperText && helperText !== '' ? <FormHelperText>{helperText}</FormHelperText> : null}
                </FormGroup>
            </FormControl>
        </Box>
    );
}

SwitchComponent.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelPlacement: PropTypes.string,
    onChange: PropTypes.func,
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'success', 'error']),
    size: PropTypes.oneOf(['medium', 'small']),
    disabled: PropTypes.bool,
    sx: PropTypes.object,
};

export default SwitchComponent;
