import React, { useState } from 'react';
import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import FileUploader from 'components/shared/FileUploader';
import { baseUrl, newAssets } from 'helpers/apiCalls';
import { Modal } from 'components/shared';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import Loader from 'pages/studio/Components/Loader';
import { BoxContainer, ErrorMessage, PhotoContainer, TextBoxButton } from './styles';
import { palette } from 'config/theme';
import { useDispatch } from 'react-redux';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { handleAddElement } from 'redux-layout/actions';
const API_CONFIGS = {
  UPLOADS: {
    upload: { api: 'assets', params: {} },
  },
};
const Photo = ({ elementType }) => {
  const dispatch = useDispatch();

  const { api: apiUpload, params: apiUploadParams } = API_CONFIGS[elementType]?.upload || { api: '', params: {} };
  const {
    data: PhotosData,
    error,
    loading,
    refreshData,
    count,
    loadMore,
  } = usePaginatedApi(apiUpload, 16, apiUploadParams);

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onModelOpen = () => setIsModelOpen(true);
  const onModelClose = () => setIsModelOpen(false);

  const HandleUploadAssets = async () => {
    setIsLoading(true);
    try {
      let makeApi = uploadedFile.map((file) => {
        let name = file.name.split('.')[0] || '';
        let extensionType = '';
        const regexImage = /\.(jpg|jpeg|png)$/i;
        const regexGif = /\.(gif)$/i;

        if (regexImage.test(file.name)) {
          extensionType = 'Image';
        } else if (regexGif.test(file.name)) {
          extensionType = 'Gif';
        }

        let data = {
          data: {
            asset: file.id,
            type: 'user',
            contenttype: 'user',
            name,
            assetType: extensionType,
          },
        };

        return newAssets(data)
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.log(err);
          });
      });

      await Promise.all(makeApi);

      refreshData();
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
      onModelClose();
    }
  };

  const handleUploadImgAdd = (item) => {
    const { width, height, url: imageUrl } = item?.attributes?.asset?.data[0]?.attributes || {};
    const defaultImg = { ...appConfig.elemImg };
    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        src: `${baseUrl}${imageUrl}`,
      },
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
    };
    dispatch(handleAddElement(element));
  };
  return (
    <PhotoContainer>
      <TextBoxButton variant="btnText4" onClick={onModelOpen}>
        Upload
      </TextBoxButton>
      <Modal open={isModelOpen} onClose={onModelClose} title={'Upload File'} size="md">
        <FileUploader setUploadedFile={setUploadedFile} />
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {isLoading ? (
            <Button variant="contained" color="secondary" disabled>
              Uploading...
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={HandleUploadAssets}
              disabled={uploadedFile.length === 0}>
              Done
            </Button>
          )}
        </Box>
      </Modal>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {error ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left', mt: 2 }}>
              Your uploads
            </Typography>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              {PhotosData.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    <BoxContainer>
                      <IconButton onClick={() => handleUploadImgAdd(item)}>
                        <img
                          className="img-contain"
                          src={`${baseUrl}${item?.attributes?.asset?.data[0]?.attributes?.url}`}
                          alt={`${baseUrl}${item?.attributes?.asset?.data[0]?.attributes?.url}`}
                        />
                      </IconButton>
                    </BoxContainer>
                  </Grid>
                );
              })}
            </Grid>
            {loading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {PhotosData?.length === count ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={loadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </PhotoContainer>
  );
};

export default Photo;
