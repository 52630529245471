import React, { useState } from 'react';
import { AddNewButton, Container, SlideCardContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, TextField, Typography, CircularProgress } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Modal } from 'components/shared';
import {newMediaUpload } from 'helpers/apiCalls';
import { palette } from 'config/theme';
import { slideSelectionChanged } from 'redux-layout/actions';

const AITemplate = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [aiModalOpen, setAIModalOpen] = useState(false);
  const [slideData, setSlideData] = useState({
    slides: [
      {
        reference_Image_for_Slide: '',
        instructions: '',
      },
    ],
  });
  const [instructionFields, setInstructionFields] = useState([{ id: Date.now(), value: '' }]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'reference_Image_for_Slide') {
      setSlideData((prevData) => ({
        slides: [
          {
            ...prevData.slides[0],
            [name]: value,
          },
        ],
      }));
    } else {
      const newInstructionFields = instructionFields.map((field, idx) => (idx === index ? { ...field, value } : field));
      setInstructionFields(newInstructionFields);

      const instructions = newInstructionFields.map((field) => field.value).join(', ');
      setSlideData((prevData) => ({
        slides: [
          {
            ...prevData.slides[0],
            instructions,
          },
        ],
      }));
    }
  };

  const handleFileUpload = async (e, field, index) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);

      try {
        const response = await newMediaUpload(formData);
        const fileName = response[0]?.url;

        if (field === 'instruction') {
          setInstructionFields((prevFields) => {
            const updatedFields = prevFields.map((field, idx) =>
              idx === index ? { ...field, value: `${field.value} https://apilw.html5.run${fileName}` } : field
            );

            const instructions = updatedFields.map((field) => field.value).join(', ');

            setSlideData((prevData) => ({
              slides: [
                {
                  ...prevData.slides[0],
                  instructions,
                },
              ],
            }));

            return updatedFields;
          });
        } else {
          setSlideData((prevData) => ({
            slides: [
              {
                ...prevData.slides[0],
                [field]: `https://apilw.html5.run${fileName}`,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const addInstructionField = () => {
    setInstructionFields([...instructionFields, { id: Date.now(), value: '' }]);
  };

  const handleGenerateSlide = async () => {
    setLoading(true);
    // const data = {
    //   json: {
    //     slides: [
    //       {
    //         reference_Image_for_Slide: slideData.slides[0].reference_Image_for_Slide,
    //         instructions: slideData.slides[0].instructions,
    //       },
    //     ],
    //   },
    // };

    // try {
    //   createAISlide(data)
    //     .then((response) => {
    //       console.log('Slide generated:', response.data);
    //       dispatch(slideSelectionChanged(response.data?.list[0]));
    //       setLoading(false);
    //       setAIModalOpen(false);
    //       setSlideData({
    //         slides: [
    //           {
    //             reference_Image_for_Slide: '',
    //             instructions: '',
    //           },
    //         ],
    //       });
    //       setInstructionFields([{ id: Date.now(), value: '' }]);
    //     })
    //     .catch((error) => {
    //       console.log('🚀 ~ createAISlide ~ error:', error);
    //     });
    // } catch (error) {
    //   console.error('Error generating slide:', error);
    //   setLoading(false);
    // }
  };

  const handleAIModalOpen = () => {
    setAIModalOpen(true);
  };

  const handleAIModalClose = () => {
    setOpen(false);
    setAIModalOpen(false);
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<LightbulbIcon />}
        sx={{ width: '100%' }}
        onClick={() => handleAIModalOpen()}>
        Create with AI
      </Button>
      <Modal
        open={aiModalOpen}
        onClose={() => handleAIModalClose()}
        title={'Generate AI Slide'}
        aria-labelledby="ai-modal"
        aria-describedby="ai-modal-description"
        size="xl">
        <Stack
          direction={'row'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'start'}
          sx={{ padding: '24px' }}>
          <Typography variant="body2">Upload Reference Image for Slide:</Typography>
          <TextField
            type="file"
            accept="image/*"
            onChange={(e) => handleFileUpload(e, 'reference_Image_for_Slide')}
            sx={{ my: 2, width: '100%' }}
          />
          <Typography variant="body2">Reference Image File Name:</Typography>
          <TextField
            name="reference_Image_for_Slide"
            value={slideData.slides[0].reference_Image_for_Slide}
            onChange={(e) => handleInputChange(e)}
            sx={{ my: 2, width: '100%' }}
            InputProps={{ readOnly: true }}
            placeholder="File name"
          />
          {instructionFields.map((field, index) => (
            <div key={field.id} style={{ marginBottom: '16px' }}>
              <TextField
                multiline
                name={`instruction_${index}`}
                value={field.value}
                onChange={(e) => handleInputChange(e, index)}
                sx={{ my: 2, width: '100%' }}
                placeholder="Instruction"
              />
              <Typography variant="body2">Upload File for Instruction:</Typography>

              <TextField
                type="file"
                accept="image/*,video/*"
                onChange={(e) => handleFileUpload(e, 'instruction', index)}
                sx={{ my: 2, width: '100%' }}
                label=""
              />
            </div>
          ))}
          <Button variant="contained" color="secondary" onClick={addInstructionField} sx={{ mb: 2 }}>
            Add Instruction
          </Button>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
            <Button variant="contained" color="secondary" onClick={() => handleGenerateSlide()}>
              {loading ? (
                <>
                  <CircularProgress size={24} sx={{ mr: 2, color: palette?.background?.default }} />
                  Generating
                </>
              ) : (
                'Generate Slide'
              )}
            </Button>
          </div>
        </Stack>
      </Modal>
    </Container>
  );
};

export default AITemplate;
