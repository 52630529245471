import React, { forwardRef } from 'react';

const ButtonBase = forwardRef(({ text, }, ref) => {
    return (
        <div>index</div>
    );
});

ButtonBase.displayName = 'ButtonBase';

export default ButtonBase;
