import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';

export const TextContainer = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;

export const TextButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #595d62;
  background: #fff;
  width: 100%;
  justify-content: flex-start;

  &:hover {
    background-color: #595d62;
    color: #fff;
  }

  & .MuiButton-label {
    text-align: left;
    width: 100%;
  }
`;

export const TextBoxButton = styled(Button)`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #009569;
  background: #009569;
  color: #fff;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: #009569;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const BoxContainer = styled(Box)`
  width: 100%;
  height: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D9D9D9; 
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;