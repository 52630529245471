import { Typography } from '@mui/material';
import { palette } from 'config/theme';
import React from 'react';

const Audio = () => {
  return (
    <Typography variant="body2" sx={{ color: palette?.background?.default }}>
      Coming Soon
    </Typography>
  );
};

export default Audio;
