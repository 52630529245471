import React from 'react';
import { BoxContainer } from './styles';
import { Button, CircularProgress, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';

const UploadImages = ({
  title,
  subTitle,
  PhotosData,
  elementLoading,
  elementError,
  elementLoadMore,
  elementCount,
  elementType,
  setElementType,
  handleBackgroundImgAdd,
}) => {
  return (
    <React.Fragment>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
          {title}
        </Typography>
        <Button variant="seeAll" onClick={() => setElementType('BACKGROUNDUPLOADS')}>
          {subTitle}
        </Button>
      </Stack>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {PhotosData.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    {/* <BoxContainer> */}
                    <IconButton onClick={() => handleBackgroundImgAdd(item)}>
                      <img className="img-contain" src={`${baseUrl}${item?.url}`} alt={`${baseUrl}${item?.url}`} />
                    </IconButton>
                    {/* </BoxContainer> */}
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading && (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default UploadImages;
