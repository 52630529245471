// import React, {Component} from 'react';
// import $ from 'jquery';
// // import configureStore from '../redux/store';
// // import { configureStore } from '../redux/store';
// import {elementChanged, selectionChanged, localChanged} from "../redux/studio/actions";

let JitsiMeetJS = window.JitsiMeetJS;

let connection = null;
let isJoined = false;
let room = {};
let mID = '';

let localTracks = [];
const remoteTracks = {};

window.rmt = remoteTracks;
window.lt = localTracks;

const confOptions = {
    openBridgeChannel: true,
    startSilent: true,
};

const options = {
    hosts: {
        domain: 'm2.lauk.in',
        // domain: '34.87.24.115',
        muc: 'conference.m2.lauk.in', // FIXME: use XEP-0030
    },
    externalConnectUrl: 'https://m2.lauk.in/http-pre-bind',
    // useStunTurn: true,
    // bosh: '//m2.lauk.in/http-bind', // FIXME: use xep-0156 for that

    // bosh: "https://m2.lauk.in/http-bind",
    // serviceUrl: "https://m2.lauk.in/http-bind",
    serviceUrl: 'https://m2.lauk.in/http-bind?room=',
    // websocket: "wss://m2.lauk.in/xmpp-websocket",

    // The name of client node advertised in XEP-0115 'c' stanza
    clientNode: 'http://m2.lauk.in',
    // channelLastN: -1,
};

const startCall = (meetingID, localCallBack, remoteCallBack) => {
    mID = meetingID;

    // const state = configureStore().getState();

    //
    let connConfig = options;
    connConfig.serviceUrl += meetingID;

    JitsiMeetJS.init();

    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    // JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);

    connection = new JitsiMeetJS.JitsiConnection(null, null, options);

    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, (meetingID) => {
        onConnectionSuccess({}, meetingID, remoteCallBack);
    });
    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
    connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, disconnect);

    connection.connect();

    JitsiMeetJS.createLocalTracks({ devices: ['audio', 'video'] })
        .then((loct) => onLocalTracks(loct, localCallBack))
        .catch((error) => {
            throw error;
        });
};

function onConnectionSuccess(m, meetingID, remoteCallBack) {

    room = connection.initJitsiConference(mID, confOptions);

    window.rm = room;

    room.on(JitsiMeetJS.events.conference.TRACK_ADDED, (track) => onRemoteTrack(track, remoteCallBack));
    room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, (track) => {
    });

    room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
    room.on(JitsiMeetJS.events.conference.USER_JOINED, (id) => {
        remoteTracks[id] = [];
    });
    // room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
    room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => {
        console.log(`${track.getType()} - ${track.isMuted()}`);
    });
    room.join();
}

function onConnectionFailed() {
    console.error('Connection Failed!');
}

function onConferenceJoined() {
    isJoined = true;
    for (let i = 0; i < localTracks.length; i++) {
        room.addTrack(localTracks[i]);
    }
}

function onLocalTracks(tracks, cb) {
    localTracks = tracks;
    if (typeof cb === 'function') {
        cb(tracks);
    }
    // localChanged(tracks);
    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel) => {
        });
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () =>
            console.log('local track muted')
        );
        localTracks[i].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () =>
            console.log('local track stoped')
        );
        localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, (deviceId) =>
            console.log(`track audio output device was changed to ${deviceId}`)
        );
        if (localTracks[i].getType() === 'video') {
            // $('#frmbdy').append(`<video autoplay='1' id='localVideo${i}' style="width: 20%"/>`);
            // // localTracks[i].attach($(`#pan-localVideo${i}`)[0]);
            // localTracks[i].attach($(`#localVideo${i}`)[0]);
        } else {
            // $('#frmbdy').append(
            //     `<audio autoplay='1' muted='true' id='localAudio${i}' />`);
            // // localTracks[i].attach($(`#pan-localAudio${i}`)[0]);
            // localTracks[i].attach($(`#localAudio${i}`)[0]);
        }
        if (isJoined) {
            room.addTrack(localTracks[i]);
        }
    }
}

function onRemoteTrack(track, remoteCallBack) {
    if (track.isLocal()) {
        return;
    }

    const participant = track.getParticipantId();

    if (!remoteTracks[participant]) {
        remoteTracks[participant] = [];
    }

    track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel) =>
        console.log(`Audio Level remote: ${audioLevel}`)
    );
    track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => console.log('remote track muted'));
    track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log('remote track stoped'));
    track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, (deviceId) =>
        console.log(`track audio output device was changed to ${deviceId}`)
    );

    if (track.getType() === 'video') {
        // $('#frmbdy').append(
        //     `<video autoplay='1' id='${participant}video${idx}' style="width: 20%" />`);
    } else {
        // $('#frmbdy').append(
        //     `<audio autoplay='1' id='${participant}audio${idx}' />`);
    }
    // track.attach($(`#${id}`)[0]);
    if (typeof remoteCallBack === 'function') {
        remoteCallBack(track);
    }
}

function disconnect() {
    connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
    connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
    connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, disconnect);
}

/**
 * const startCall = () => {
 */
const unload = () => {
    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].dispose();
    }
    room.leave();
    connection.disconnect();
};

/////// LWStudio
const newID = (type) => {
    let suffix = '';
    let id = Math.random().toString(36).substr(2, 9);
    if (type === 'element') {
        suffix = 'el_';
    } else if (type === 'slide') {
        suffix = 'sld_';
    }
    return suffix + id;
};

export { connection, isJoined, room, localTracks, remoteTracks, startCall, unload, newID };
