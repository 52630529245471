import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { AudioContainer } from './style';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LifewiseLibrary from './LifewiseLibrary';
import Upload from './Upload';
import UploadedAudio from './UploadedAudio';

const API_CONFIGS = {
  PHOTOS: {
    upload: { api: 'assets', params: {} },
    library: { api: 'assets', params: { type: { $eq: 'system' } } },
  },
};

const Audio = ({ elementType, setElementType }) => {
  const [buttonSearch, setButtonSearch] = useState('');



  return (
    <AudioContainer>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ gap: '10px', p: 0 }}>
        <LeftNavSearch
          searchValue={buttonSearch}
          setSearchValue={setButtonSearch}
          placeholder={'Search Audio'}
          searchKey={'name'}
        />
        <Upload />
        <UploadedAudio
          title={'Your uploads'}
          subTitle={'see all'}
          audioData={[1,2,3,4]}
          // elementLoadMore={uploadApi.loadMore}
          // elementLoading={uploadApi.loading}
          // elementError={uploadApi.error}
          // elementCount={uploadApi.count}
          elementType={elementType}
          setElementType={setElementType}
        />
        <LifewiseLibrary
          title={'LifeWise library'}
          audioData={[1,2,3,4,5,6,7,8,9,10]}
          // elementLoadMore={libraryApi.loadMore}
          // elementLoading={libraryApi.loading}
          // elementError={libraryApi.error}
          // elementCount={libraryApi.count}
        />
      </Stack>
    </AudioContainer>
  );
};

export default Audio;
