export const TEXT = 'text';
export const IMG = 'img';
export const VIDEO = 'VIDEO';
export const COMPLEX_TEXT = 'complex_text';
export const SVG = 'svg';
export const DIV = 'div';
export const ALL = 'all';
export const INDIV = 'indiv';
export const POS = 'pos';
export const RND = 'rnd';
export const CANVAS_FRAME_EL = '#canvasFrame';
export const CANVAS_EL = '#lcanvas';
export const MENU_ID = 'menu-id';
export const FILE = 'file';
export const FRAME = 'frame';

