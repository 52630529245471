import React, { useState } from 'react';
import { AddNewButton, Container, MoreButton, SlideCardContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import SlidePreview from '../Components/SlidePreview';
import AddIcon from '@mui/icons-material/Add';
import AddSlide from './AddSlide';
import { Modal } from 'components/shared';
import { slideadded, slideDeleted, slideSelectionChanged } from 'redux-layout/actions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AiIcon } from 'config/svgs';
import appConfig from 'config';
import { newID } from 'helpers/studioApi';
import { palette } from 'config/theme';

const Slides = ({ setElementType }) => {
  const dispatch = useDispatch();
  const { slides, selectedSlide } = useSelector((state) => state.Canvas.present);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchorEl to the clicked MoreButton
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddSlide = () => {
    setOpen(true);
  };

  const handleSlideChange = (slide) => {
    if (slide.id !== selectedSlide.id) {
      dispatch(slideSelectionChanged(slide));
    }
  };

  const handleAdd = (index) => {
    let newSlide = appConfig.slideColor;
    dispatch(slideadded({ ...newSlide, id: newID('slide') }, index));
    handleMenuClose();
  };

  const handleDuplicate = (index) => {
    dispatch(slideadded({ ...selectedSlide, id: newID('slide') }, index));
    handleMenuClose();
  };

  const handleRecreateAi = () => {
    setElementType('TOOLBAR_REGENERATESLIDE');
    handleMenuClose();
  };

  const handleDelete = () => {
    if (slides.length === 1) {
      return;
    }
    dispatch(slideDeleted());
    handleMenuClose();
  };

  const handleAddNewSlide = () => {
    let newSlide = appConfig.slideColor;
    dispatch(slideadded([{ ...newSlide, id: newID('slide') }], -1));
  };

  return (
    <Container>
      <Stack direction="row" spacing={2} padding="0 1.5rem">
        {slides.map((slide, index) => (
          <Stack key={index} direction="column" spacing={2}>
            <SlideCardContainer
              isselected={selectedSlide.id === slide.id ? true : undefined}
              onClick={() => handleSlideChange(slide)}>
              <SlidePreview slide={slide} />
              {selectedSlide.id === slide.id && (
                <MoreButton type="slide" onClick={handleMenuClick}>
                  <MoreHorizIcon />
                </MoreButton>
              )}

            </SlideCardContainer>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom', // Position the menu relative to the top of the anchor element
                horizontal: 'right', // Position the menu relative to the right of the anchor element
              }}
              transformOrigin={{
                vertical: 'bottom', // Align the top of the menu with the top of the anchor element
                horizontal: 'left', // Align the right side of the menu with the right side of the anchor element
              }}>
              <MenuItem onClick={() => handleAdd(index)}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText>Add page</ListItemText>
                {/* <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ background: palette.background.grey1, padding: '5px' }}>
                    ⌘N
                  </Typography> */}
              </MenuItem>
              <MenuItem onClick={() => handleDuplicate(index)}>
                <ListItemIcon>
                  <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText>Duplicate page</ListItemText>
                {/* <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ background: palette.background.grey1, padding: '5px' }}>
                    ⌘D
                  </Typography> */}
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <DeleteOutlineIcon />
                </ListItemIcon>
                <ListItemText>Delete page</ListItemText>
                {/* <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ background: palette.background.grey1, padding: '5px' }}>
                    DELETE
                  </Typography> */}
              </MenuItem>
              <MenuItem onClick={handleRecreateAi}>
                <ListItemIcon>
                  <AiIcon />
                </ListItemIcon>
                <ListItemText>Recreate with AI</ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        ))}
        <AddNewButton>
          <Box onClick={handleAddNewSlide}>
            <AddIcon />
          </Box>
          <MoreButton type="new" className="more-icon" onClick={handleAddSlide}>
            <MoreHorizIcon />
          </MoreButton>
        </AddNewButton>
      </Stack>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={'Add Slide'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl">
        <AddSlide onClose={() => setOpen(false)} setOpen={setOpen} />
      </Modal>
    </Container>
  );
};

export default Slides;
