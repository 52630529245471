import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const TextInput = styled(TextField)`
  border-radius: 8px;
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  .MuiInputBase-input {
    height: 40px;
    padding: 8px 12px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
`;
