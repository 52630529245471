import React, { useState } from 'react';
import ShapesElement from './ShapesElement';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import { ShapeContainer } from './styles';
import { Typography } from '@mui/material';
import { palette } from 'config/theme';

const Shapes = (props) => {
  const { elementType, setElementType } = props;
  const [buttonSearch, setButtonSearch] = useState('');
  const elementConfigs = {
    SHAPES: { api: 'svgs', params: {}, paginationType: 'default' },
  };

  const { api, params: apiParams, paginationType } = elementConfigs[elementType] || { api: '', params: {} };
  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 44, apiParams, paginationType);
  console.log('🚀 ~ Shapes ~ data:', data);

  const handleBack = () => {
    setElementType('ELEMENTS');
  };

  const clearSearch = () => {
    searchItems('', 'name');
    setButtonSearch('');
  };
  const handleSearch = (value) => {
    searchItems(value, 'name');
    setButtonSearch(value);
  };

  return (
    <ShapeContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <LeftNavSearch
        searchValue={buttonSearch}
        clearSearch={clearSearch}
        handleSearch={handleSearch}
        placeholder={'Search Shapes'}
      />
      {data?.length ? (
        <ShapesElement
          data={data && data?.map((item) => item?.attributes)}
          elementLoadMore={loadMore}
          elementLoading={loading}
          elementError={error}
          elementCount={count}
        />
      ) : (
        <Typography variant="large" sx={{ color: palette?.text.grey3 }}>
          No shapes found
        </Typography>
      )}
    </ShapeContainer>
  );
};

export default Shapes;
