import { Avatar } from '@mui/material';
import styled from '@emotion/styled';

import {
    deepOrange,
    deepPurple,
    blue,
    cyan,
    indigo,
    lightGreen,
    lime,
    teal,
} from '@mui/material/colors';

const colors = [
    deepOrange[400],
    deepPurple[400],
    cyan[400],
    blue[500],
    teal[500],
    indigo[500],
    lightGreen[500],
    lime[300],
];

const getColorFromName = (name) => {
    if (!name) { return '#bdbdbd'; } // Default color if name is invalid
    return colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];
};

export const MuiAvatar = styled(Avatar)`
  cursor: pointer;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  background-color: ${(props) => getColorFromName(props.name)};
  font-size: ${(props) => `${props.size / 2.2}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1; // Use 1 for normal line height, flexbox takes care of centering
`;
