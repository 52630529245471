import React, { useState } from 'react';
import Leftbar from './Leftbar';
import Topbar from './Topbar';
import { Box, Divider, Grid } from '@mui/material';
import Content from './Content';
import usePaginatedApi from 'components/hooks/usePaginationApi';

const Assets = () => {
    const { data, count, loading, error, loadMore, searchItems, refreshData, searchParams } = usePaginatedApi('assets', 15);
    const [addAssets, setAddAssets] = useState(false);

    return (
        <Box>
            <Topbar refreshData={refreshData} addAssets={addAssets} setAddAssets={setAddAssets} />
            <Divider />
            <Grid container direction="row" wrap="nowrap">
                <Grid item xs={2}>
                    <Leftbar searchParams={searchParams} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={10}>
                    <Content
                        data={data}
                        count={count}
                        loading={loading}
                        error={error}
                        loadMore={loadMore}
                        searchItems={searchItems}
                        refreshData={refreshData}
                        setAddAssets={setAddAssets}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Assets;
