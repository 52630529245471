import React, { useState, useEffect, forwardRef, useRef } from 'react';
import TextToolbar from './TextToolbar';
import { useDispatch } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const ResizableDraggable = forwardRef(({
    children,
    onDragStop,
    onResizeStop,
    position: initialPosition,
    size: initialSize,
    className,
    style,
    onMouseDownEvent,
    onContextMenu,
    key,
    id,
    isVisible,
    handleContextMenu,
    element
}, ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const [resizing, setResizing] = useState(null);
    const [position, setPosition] = useState(initialPosition || { x: 100, y: 100 });
    const [size, setSize] = useState({
        width: initialSize?.width || '200px',
        height: initialSize?.height || '200px'
    });
    const isRotated = useRef(false);
    const rotation = useRef({ x: 0, y: 0 });
    const [angle, setAngle] = useState(0);
    const dispatch = useDispatch();

    const handleMouseDownRotate = (e) => {
        const targetElement = e.target.classList.contains('text-rotate') ? e.target : e.target.closest('.text-rotate');
        if (e.target.classList.contains('text-rotate') || e.target.closest('.text-rotate')) {
            isRotated.current = true;
            const rect = targetElement.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            rotation.current = {
                x: centerX,
                y: centerY
            };
        }
    };

    const handleMouseDownDrag = (e) => {
        onMouseDownEvent();
        if (e.target.classList.contains('resizable-draggable') || e.target.classList.contains('rsw-editor') || e.target.classList.contains('text-move') || e.target.closest('.text-move')) {
            setIsDragging(true);
            ref.current = {
                offsetX: e.clientX - position.x,
                offsetY: e.clientY - position.y,
            };
        }
    };

    const handleMouseMove = (e) => {
        if (isRotated.current) {
            console.log('rect angle isRotated.current', isRotated.current, resizing, isDragging);
        }

        if (isDragging) {
            setPosition({
                x: e.clientX - ref.current.offsetX,
                y: e.clientY - ref.current.offsetY,
            });
        } else if (resizing) {
            const deltaX = e.clientX - ref.current.startX;
            const deltaY = e.clientY - ref.current.startY;

            let newSize = { ...size };
            let newPosition = { ...position };

            switch (resizing) {
                case 'right':
                    newSize.width = `${ref.current.startWidth + deltaX}px`;
                    break;
                case 'bottom':
                    newSize.height = `${ref.current.startHeight + deltaY}px`;
                    break;
                case 'bottom-right':
                    newSize.width = `${ref.current.startWidth + deltaX}px`;
                    newSize.height = `${ref.current.startHeight + deltaY}px`;
                    break;
                case 'left':
                    newSize.width = `${ref.current.startWidth - deltaX}px`;
                    newPosition.x = ref.current.startXPos + deltaX;
                    break;
                case 'top':
                    newSize.height = `${ref.current.startHeight - deltaY}px`;
                    newPosition.y = ref.current.startYPos + deltaY;
                    break;
                case 'top-left':
                    newSize.width = `${ref.current.startWidth - deltaX}px`;
                    newSize.height = `${ref.current.startHeight - deltaY}px`;
                    newPosition.x = ref.current.startXPos + deltaX;
                    newPosition.y = ref.current.startYPos + deltaY;
                    break;
                case 'top-right':
                    newSize.width = `${ref.current.startWidth + deltaX}px`;
                    newSize.height = `${ref.current.startHeight - deltaY}px`;
                    newPosition.y = ref.current.startYPos + deltaY;
                    break;
                case 'bottom-left':
                    newSize.width = `${ref.current.startWidth - deltaX}px`;
                    newSize.height = `${ref.current.startHeight + deltaY}px`;
                    newPosition.x = ref.current.startXPos + deltaX;
                    break;
                default:
                    break;
            }

            setSize(newSize);
            setPosition(newPosition);
        } else if (isRotated.current) {
            console.log('rect', e, e.clientX, e.clientY, rotation.current);
            const deltaX = e.clientX - rotation.current.x;
            const deltaY = e.clientY - rotation.current.y;
            console.log('Difference', deltaX, deltaY);
            const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
            setAngle(angle);
            // dispatch(elementUpdated(element, angle, 'transformRotate'));
            // console.log('rect angle', angle);
        }
    };

    const handleMouseUp = (e) => {
        if (isDragging) {
            setIsDragging(false);
            if (onDragStop) {
                onDragStop(e, position);
            }
        } else if (resizing) {
            setResizing(null);
            if (onResizeStop) {
                onResizeStop(e, resizing, ref, null, position);
            }
        } else if (isRotated.current) {
            isRotated.current = false;
        }
    };

    const handleMouseDownResize = (e, direction) => {
        // e.stopPropagation();
        setResizing(direction);
        ref.current = {
            startX: e.clientX,
            startY: e.clientY,
            startWidth: ref.current.width,
            startHeight: ref.current.height,
            startXPos: position.x,
            startYPos: position.y,
        };
    };

    // const handleMouseMoveResize = (e) => {

    // };

    const handleMouseUpResize = (e) => {

    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, resizing, position, size]);

    useEffect(() => {
        setPosition(initialPosition || { x: 100, y: 100 });
    }, [initialPosition]);

    useEffect(() => {
        setSize({
            width: initialSize?.width || '200px',
            height: initialSize?.height || '200px'
        });
    }, [initialSize]);

    useEffect(() => {
        const element = document.getElementById(id);
        ref.current.width = element.clientWidth;
        ref.current.height = element.clientHeight;
    }, [size]);

    return (
        <div
            className={`resizable-draggable edit-textarea ${className}`}
            style={{
                ...style,
                width: size.width,
                height: size.height,
                transform: `translate(${position.x}px, ${position.y}px) rotate(${angle}deg)`,
                position: 'absolute',
                border: '12px solid transparent',
            }}
            onMouseDown={handleMouseDownDrag}
            onContextMenu={onContextMenu}
            ref={ref}
            key={key}
            id={id}
        >
            {children}
            <div className="resizer top-left" onMouseDown={(e) => handleMouseDownResize(e, 'top-left')} />
            <div className="resizer top" onMouseDown={(e) => handleMouseDownResize(e, 'top')} />
            <div className="resizer top-right" onMouseDown={(e) => handleMouseDownResize(e, 'top-right')} />
            <div className="resizer right" onMouseDown={(e) => handleMouseDownResize(e, 'right')} />
            <div className="resizer bottom-right" onMouseDown={(e) => handleMouseDownResize(e, 'bottom-right')} />
            <div className="resizer bottom" onMouseDown={(e) => handleMouseDownResize(e, 'bottom')} />
            <div className="resizer bottom-left" onMouseDown={(e) => handleMouseDownResize(e, 'bottom-left')} />
            <div className="resizer left" onMouseDown={(e) => handleMouseDownResize(e, 'left')} />
            <TextToolbar
                isVisible={isVisible}
                element={element}
                handleContextMenu={handleContextMenu}
                handleMouseDownDrag={handleMouseDownDrag}
                handleMouseDownRotate={handleMouseDownRotate}
            />
        </div>
    );
});

ResizableDraggable.displayName = 'ResizableDraggable';

export default ResizableDraggable;
