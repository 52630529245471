import useResizeHeight from 'components/hooks/useResizeHeight';
import React from 'react';
import { ContentContainer } from './styles';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import SearchSection from 'components/App/SearchSection';
import CardContainer from './CardContainer';
import { Spacer } from 'components/shared';

const Content = (props) => {
    const { ref, height } = useResizeHeight();
    const { data, count, loading, error, loadMore, searchItems, refreshData } = props;


    if (height === 0) {
        return <ContentContainer ref={ref}></ContentContainer>;
    }
    return (
        <ContentContainer ref={ref} height={`${height}px`}>
            <Box sx={{ padding: '16px' }}>
                <SearchSection searchItems={searchItems} searchKey={'name'} />
                {error ? (
                    <Typography variant={'h2'} color="secondary">Error 404!</Typography>
                ) : (
                    <React.Fragment>
                        <Grid container spacing={2}>
                            {data.map((item, index) => {
                                return (
                                    <Grid item xs={4} key={index} md={2.4}>
                                        <CardContainer id={item.id} attributes={item.attributes} refreshData={refreshData} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {loading ? (
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <CircularProgress color="secondary" />
                            </Box>
                        ) : data.length === 0 ? (
                            <Box display={'grid'} sx={{ placeItems: 'center' }}>
                                <Spacer x={15} y={15} />
                                <Typography variant={'h2'} align={'center'}>Upload your first asset.</Typography>
                                <Spacer x={2} y={2} />
                                <Button variant="contained" color="secondary" onClick={() => props.setAddAssets(true)}>Upload</Button>
                            </Box>
                        ) : count === data.length ? null : (
                            <Box display="flex" justifyContent={'center'} alignItems={'center'}>
                                <Button variant={'loadMore'} onClick={loadMore}>Load More</Button>
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </Box>
        </ContentContainer>
    );
};

export default Content;
