import React, { useState, useEffect, useCallback } from 'react';
import { EmbedContainer, PreviewBox, TextBoxButton, TextInput } from './styled';
import { Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import appConfig from 'config';
import { handleAddElement } from 'redux-layout/actions';
import { newID } from 'helpers/studioApi';
import { useDispatch } from 'react-redux';

const Embed = () => {
  const dispatch = useDispatch();
  const [embedUrl, setEmbedUrl] = useState('');
  const [previewContent, setPreviewContent] = useState(null);

  const DEFAULT_WIDTH_FRAME = '100%';
  const DEFAULT_HEIGHT_FRAME = '100%';

  const extractYouTubeVideoId = useCallback((url) => {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  }, []);

  const isGoogleMapsUrl = useCallback((url) => {
    return url.includes('google.com/maps');
  }, []);

  const handleEmbedPreview = useCallback(() => {
    if (!embedUrl) {
      setPreviewContent(
        <Typography variant="body2" sx={{ color: palette?.text?.grey4 }}>
          Preview
        </Typography>
      );
      return;
    }

    if (embedUrl.includes('<iframe')) {
      setPreviewContent(
        <div
          dangerouslySetInnerHTML={{ __html: embedUrl }}
          style={{ width: '100%', height: '100%' }}
        />
      );
      return;
    }

    const youtubeVideoId = extractYouTubeVideoId(embedUrl);
    if (youtubeVideoId) {
      setPreviewContent(
        <iframe
          width={DEFAULT_WIDTH_FRAME}
          height={DEFAULT_HEIGHT_FRAME}
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
          style={{ width: '100%', height: '100%' }}
        />
      );
      return;
    }

    if (isGoogleMapsUrl(embedUrl)) {
      setPreviewContent(
        <iframe
          width={DEFAULT_WIDTH_FRAME}
          height={DEFAULT_HEIGHT_FRAME}
          src={embedUrl}
          frameBorder="0"
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
          title="Google Maps"
          style={{ width: '100%', height: '100%' }}
        />
      );
      return;
    }

    setPreviewContent(
      <Typography variant="body2" sx={{ color: palette?.text?.grey4 }}>
        Preview
      </Typography>
    );
  }, [embedUrl, extractYouTubeVideoId, isGoogleMapsUrl]);

  useEffect(() => {
    handleEmbedPreview();
  }, [embedUrl, handleEmbedPreview]);

  const handleAddEmbed = () => {
    const defaultImg = { ...appConfig.elemVideo };
    let iframeContent = embedUrl.includes('<iframe') ? embedUrl : '';
    let iframeAttributes = {};

    if (!iframeContent) {
      const youtubeVideoId = extractYouTubeVideoId(embedUrl);
      if (youtubeVideoId) {
        iframeContent = `<iframe width="${DEFAULT_WIDTH_FRAME}" height="${DEFAULT_HEIGHT_FRAME}" src="https://www.youtube.com/embed/${youtubeVideoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        iframeAttributes = {
          src: `https://www.youtube.com/embed/${youtubeVideoId}`,
          title: 'YouTube video player',
          frameBorder: '0',
          allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
          allowFullScreen: true,
          width: '100%',
          height: '100%',
        };
      } else if (isGoogleMapsUrl(embedUrl)) {
        iframeContent = `<iframe width="${DEFAULT_WIDTH_FRAME}" height="${DEFAULT_HEIGHT_FRAME}" src="${embedUrl}" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" title="Google Maps"></iframe>`;
        iframeAttributes = {
          src: embedUrl,
          width: '100%',
          height: '100%',
          frameBorder: '0',
          allowFullScreen: true,
          ariaHidden: 'false',
          tabIndex: '0',
          title: 'Google Maps',
        };
      }
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(iframeContent, 'text/html');
      const iframe = doc.querySelector('iframe');

      if (iframe) {
        iframeAttributes = {
          width: iframe.getAttribute('width') || '100%',
          height: iframe.getAttribute('height') || '100%',
          src: iframe.getAttribute('src') || '',
          title: iframe.getAttribute('title') || 'YouTube video player',
          frameBorder: iframe.getAttribute('frameborder') || '0',
          allow:
            iframe.getAttribute('allow') ||
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
          allowFullScreen: iframe.getAttribute('allowfullscreen') !== null,
        };
      }
    }

    const element = {
      ...defaultImg,
      id: newID('element'),
      attributes: iframeAttributes,
      content: {
        embed: iframeContent,
      },
      position: {
        ...defaultImg.position,
        height: '30%',
        width: '30%',
      },
    };

    dispatch(handleAddElement(element));
  };

  return (
    <EmbedContainer>
      <TextInput
        placeholder="Paste embed link here"
        variant="outlined"
        multiline
        value={embedUrl}
        onChange={(e) => setEmbedUrl(e.target.value)}
        sx={{ mt: 2 }}
      />
      <Stack direction="column" alignItems="start" justifyContent="center" sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ color: palette?.text?.grey5 }}>
          Preview
        </Typography>
        <PreviewBox style={{ width: '100%', height: '400px' }}>
          {previewContent || (
            <Typography variant="body2" sx={{ color: palette?.text?.grey4 }}>
              Preview
            </Typography>
          )}
        </PreviewBox>
      </Stack>
      <TextBoxButton variant="btnText4" sx={{ mt: 2 }} onClick={handleAddEmbed} disabled={embedUrl ? false : true}>
        Embed
      </TextBoxButton>
    </EmbedContainer>
  );
};

export default Embed;
