import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, FormHelperText, Button, Typography } from '@mui/material';
import { UploadWrapper, FilesWrapper, AttachmentButton } from './styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import File from './File';
import _ from 'lodash';
import FileUpload from './FileUpload';
import { AttachmentUploadIcon } from 'config/svgs';

const FileInput = ({ initialFile = [], value, multiple, uuid, buttonLabel, maxSize, onChange, description, ...props }) => {
  const [files, setFiles] = React.useState(initialFile);

  // useEffect(() => {
  //     onChange(files);
  // }, [files]);

  const hiddenFileInput = useRef(null);

  const handleChange = (event) => {
    event.persist();
    const newFiles = [];
    [...event.target.files].forEach((file) => {
      if (maxSize > 0) {
        const fileSize = Math.round(file.size / 1024);
        const allowedSize = maxSize * 1024;
        if (fileSize <= allowedSize) {
          newFiles.push(file);
        } else {
          console.log('File size exceeds the limit');
        }
      } else {
        newFiles.push(file);
      }
    });

    setFiles([...files, ...newFiles]);
  };

  const onUpload = async (file, res) => {
    await setFiles((curr) =>
      curr.map((fw) => {
        console.log('fw', fw, res);
        if (fw === file) {
          return {
            ...file,
            ...res[0],
          };
        }
        return fw;
      })
    );
    // getFiles();
  };

  const onDelete = (id) => {
    const newFiles = files.filter((file) => file?.id !== id);
    setFiles(newFiles);
    // getFiles();
  };

  useEffect(() => {
    if (value && !_.isEmpty(value) && !_.isNull(value) && JSON.stringify(value) !== JSON.stringify(files)) {
      setFiles(value);
    }
  }, [value]);

  return (
    <UploadWrapper>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple={multiple}
      />
      {/* <Box display="flex" flexDirection="column" alignItems={'flex-start'}> */}
      <AttachmentButton
        onClick={() => {
          hiddenFileInput.current.click();
        }}
      // color={props.color}
      >
        <AttachmentUploadIcon />
        {buttonLabel}
      </AttachmentButton>
      {description && (
        <Typography variant="caption" ml={1}>
          {description}
        </Typography>
      )}
      {/* </Box> */}
      {/* {maxSize > 0 && (
                <FormHelperText style={{ paddingLeft: 8 }}>
                    Maximum upload file size: {maxSize} MB.
                </FormHelperText>
            )} */}
      {files && files.length ? (
        <FilesWrapper>
          {console.log('files', files)}
          {files.map((file, index) => {
            return <FileUpload file={file} onUpload={onUpload} onDelete={onDelete} fieldId={props.id} />;
          })}
        </FilesWrapper>
      ) : null}
    </UploadWrapper>
  );
};

FileInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  multiple: PropTypes.bool,
  uuid: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string,
  maxSize: PropTypes.number,
  color: PropTypes.string,
};

FileInput.defaultProps = {
  className: undefined,
  value: undefined,
  multiple: false,
  uuid: '',
  description: '',
  buttonLabel: 'Upload Files',
  maxSize: 0,
  color: 'secondary',
};

export default FileInput;
