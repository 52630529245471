/**
 * Flatten the nested list of menu items
 */
let uuid = 1;

const assignIdAndParent = (menuItems, parentId) => {
    menuItems = menuItems || [];
    menuItems.forEach((item) => {
        const id = item.id || uuid;
        uuid += 1;
        item.id = id;
        item.parentId = item.parentId || parentId;
        item.active = false;

        if (typeof item.children !== 'undefined') {
            assignIdAndParent(item.children, id);
        }
    });
};

export const rgbaToHex = (r, g, b, a) => {
    var outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
        Math.round(a * 255)
            .toString(16)
            .substring(0, 2),
    ];

    outParts.forEach((part, i) => {
        if (part.length === 1) {
            outParts[i] = '0' + part;
        }
    });

    return '#' + outParts.join('');
};

export default (menuItems) => {
    assignIdAndParent(menuItems);
    return menuItems;
};
