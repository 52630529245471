import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const CommonSnackbar = ({
    open,
    handleClose,
    message,
    severity = 'success', // default value
    actionLabel = 'CLOSE', // default value
    autoHideDuration = 3000 // default value
}) => {
    const action = (
        <Button color="secondary" variant='backBtn' size="small" onClick={handleClose}>
            {actionLabel}
        </Button>
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            message={message}
            action={action}
            severity={severity}
            color="secondary"
        />
    );
};

CommonSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    actionLabel: PropTypes.string,
    autoHideDuration: PropTypes.number,
    severity: PropTypes.string,
};

export default CommonSnackbar;
