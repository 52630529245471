import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { palette } from 'config/theme';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;
    width:100%;
    height: 100%;
`;

export const Image = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid ${({ isselected }) => isselected ? palette.border.primary : palette.border.light};    
`;

export const GridBorder = styled.div`
    border: 1px solid ${({ isselected }) => isselected ? palette.border.primary : palette.border.light};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    padding: 1rem;
`;
