import React, { useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import Loader from 'pages/studio/Components/Loader';
import MusicButton from './MusicButton';

const LifewiseLibrary = ({
  title,
  subTitle,
  audioData,
  elementLoading,
  elementError,
  elementLoadMore,
  elementCount,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const handleMusicButtonClick = (index) => {
    setSelectedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <React.Fragment>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
          {title}
        </Typography>
        {subTitle ? <Button variant="seeAll">{subTitle}</Button> : null}
      </Stack>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {audioData.map((item, index) => {
                const isSelected = selectedItemIndex === index;
                return (
                  <Grid item key={index} xs={12}>
                    <MusicButton
                      isChecked={isSelected}
                      onClick={() => handleMusicButtonClick(index)}
                      label="Music Name"
                    />
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {audioData?.length === elementCount ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={elementLoadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default LifewiseLibrary;
