import images from 'config/images';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


export const animationList = [
    {
        id: 1,
        name: 'bounce',
        image: images.studio.animate?.bounce,
        type: 'bounce',
    },
    {
        id: 2,
        name: 'spin',
        image: images.studio.animate?.spin,
        type: 'spin',
    },
    {
        id: 3,
        name: 'swipe',
        image: images.studio.animate?.swipe,
        type: 'swipe',
    },
    {
        id: 4,
        name: 'zoom',
        image: images.studio.animate?.zoom,
        type: 'zoom',
    },
];

export const animateType = [
    {
        id: 1,
        name: 'On Enter',
        type: 'enter',
        call: 'addClass'
    },
    {
        id: 2,
        name: 'On Click',
        type: 'click',
        call: 'addClass',
    },
];

export const animateDirection = [
    {
        id: 1,
        name: 'Left',
        type: 'left',
        icon: <ArrowBackIcon />
    },
    {
        id: 2,
        name: 'Right',
        type: 'right',
        icon: <ArrowForwardIcon />
    },
    {
        id: 3,
        name: 'Top',
        type: 'top',
        icon: <ArrowUpwardIcon />
    },
    {
        id: 4,
        name: 'Bottom',
        type: 'bottom',
        icon: <ArrowDownwardIcon />
    },
];

