import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.text.grey6};
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 96%;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
    width: 10px;
  };
    &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  };

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  };
`;
