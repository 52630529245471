import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import Card from 'components/App/LibraryCard';
import images from 'config/images';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MoreButton } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { createData } from 'helpers/api.services';
import { useSelector } from 'react-redux';
import { Snackbar } from 'components/shared';

const CardContainer = ({ id, attributes, refreshData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    navigate(`/studio/${id}`);
    handleClose();
  };

  const handleLink = () => {
    navigator.clipboard.writeText(`https://stplr.superteach.org/?prj=${id}`);
    setSnackbarOpen(true);
    handleClose();
  };

  const handleCopy = async () => {
    let payload = {
      title: attributes?.title,
      project: attributes?.project,
      description: attributes?.description,
      username: attributes?.username,
    };
    await createData('projects', payload);
    refreshData();
    handleClose();
  };

  return (
    <Box
      position="relative"
      sx={{
        '&:hover .more-icon': {
          display: 'flex',
        },
      }}>
      <Card
        tag={attributes?.assetType || 'Draft'}
        image={images.library.cardImage}
        name={attributes?.title}
        date={'1 day'}
        ratio={'16/9'}
        bgColor={'#1ed4c1'}
        padding={''}
      />
      <MoreButton className="more-icon" onClick={handleMenuOpen}>
        <MoreHorizIcon />
      </MoreButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon sx={{ color: 'background.paper' }} />
          </ListItemIcon>
          <ListItemText>Continue Editing</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            <ContentCopyIcon sx={{ color: 'background.paper' }} />
          </ListItemIcon>
          <ListItemText>Make a copy</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLink}>
          <ListItemIcon>
            <ContentCopyIcon sx={{ color: 'background.paper' }} />
          </ListItemIcon>
          <ListItemText>Copy the link</ListItemText>
        </MenuItem>
      </Menu>
      <Snackbar open={snackbarOpen} handleClose={() => setSnackbarOpen(false)} message={'Link copied to clipboard!'} />
    </Box>
  );
};

export default CardContainer;
