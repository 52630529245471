import React, { useState } from 'react';
import GIFElement from './GIFElement';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import LeftNavSearch from 'pages/studio/Components/LeftNavSearch';
import { GIFContainer } from './styles';
import { palette } from 'config/theme';
import { Typography } from '@mui/material';

const GIFs = (props) => {
  const { elementType, setElementType } = props;
  const [buttonSearch, setButtonSearch] = useState('');

  const elementConfigs = {
    GIFS: { api: 'assets', params: { contenttype: { $eq: 'gif' } } },
  };

  const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };

  const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams);

  const handleBack = () => {
    setElementType('ELEMENTS');
  };

  const handleSearch = (value) => {
    searchItems(value, 'name');
    setButtonSearch(value);
  };
  const clearSearch = () => {
    setButtonSearch('');
    searchItems('', 'name');
  };

  return (
    <GIFContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <LeftNavSearch
        searchValue={buttonSearch}
        handleSearch={handleSearch}
        placeholder={'Search GIFs'}
        clearSearch={clearSearch}
      />
      {data?.length ? (
        <GIFElement
          data={data && data?.map((item) => item?.attributes?.asset?.data[0]?.attributes)}
          elementLoadMore={loadMore}
          elementLoading={loading}
          elementError={error}
          elementCount={count}
        />
      ) : (
        <Typography variant="large" sx={{ color: palette?.text.grey3 }}>
          No GIFs found
        </Typography>
      )}
    </GIFContainer>
  );
};

export default GIFs;
