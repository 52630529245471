import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import ButtonGroup from './ButtonGroup';
import ParagraphOptions from './ParagraphOptions';
import { Container } from './styles';
import { InsertIcon } from 'pages/studio/Components/SVGs';
import { Button, Snackbar } from '@mui/material';
import { useStore } from 'Socket';
import { elementUpdated } from 'redux-layout/actions';

const RecreateWithAI = ({ setElementType }) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.Canvas.present);
  const [previewText, setPreviewText] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState('');
  const [selectedButton, setSelectedButton] = useState(null);
  const { emit, events, setEvents } = useStore();

  useEffect(() => {
    setText(selectedElement?.content?.text);
  }, [selectedElement?.content?.text]);
  useEffect(() => {
    console.log('events', events);
    const successEvent = events.find((item) => item.event === 'pipeline:execute:success');
    const errorEvent = events.find((item) => item.event === 'pipeline:execute:error');

    if (successEvent?.event === 'pipeline:execute:success') {
      try {
        const response = JSON.parse(successEvent.payload);
        setPreviewText(response.text);
        setLoading(false);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else if (errorEvent?.event === 'pipeline:execute:error') {
      setError('AI generation failed to regenerate the text');
      setLoading(false);
      setOpenSnackbar(true);
      setEvents([]);
    }
  }, [events, setEvents]);

  const handleInsertText = () => {
    if (selectedElement) {
      dispatch(elementUpdated(selectedElement, previewText, 'text'));
      // setText(previewText);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleRegenerateText = () => {
    try {
      emit(
        'pipeline:execute',
        JSON.stringify({
          slug: 'improve-text',
          payload: {
            instructions: selectedButton,
            text: text,
          },
        })
      );
    } catch (e) {
      setError('server error');
      setOpenSnackbar(true);
      console.log('regeneration text ERROR::', e);
    }
  };

  return (
    <Container>
      <Header setElementType={setElementType} />
      <ButtonGroup
        emit={emit}
        text={text}
        loading={loading}
        setLoading={setLoading}
        setOpenSnackbar={setOpenSnackbar}
        setError={setError}
        setSelectedButton={setSelectedButton}
      />
      <ParagraphOptions
        previewText={previewText}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        text={text}
        setText={setText}
        handleRegenerateText={handleRegenerateText}
      />
      <Button
        onClick={handleInsertText}
        variant="contained"
        color="secondary"
        startIcon={<InsertIcon />}
        sx={{ width: '100%', p: 2, mt: 5 }}>
        Insert
      </Button>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={error}
        severity={'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Container>
  );
};

export default RecreateWithAI;
