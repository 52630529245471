import { PROJECT_LOADING } from './constants';

const INIT_STATE = {
    loading: false,
};


const Studio = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROJECT_LOADING:
            console.log('action.payload.value', action.payload);
            return { ...state, loading: action.payload };

        default:
            return state;
    }
};

export default Studio;

