export const leftbarOptions = {
    ['Asset type']: [
        {
            id: 1,
            name: 'Image',
            checked: false,
            value: 'Image',
        },
        {
            id: 2,
            name: 'Video',
            checked: false,
            value: 'Video',
        },
        {
            id: 3,
            name: 'Audio',
            checked: false,
            value: 'Audio',
        }
    ],
    ['File Type']: [
        {
            id: 1,
            name: 'SVG',
            checked: false,
            value: 'svg',
        },
        {
            id: 2,
            name: 'JPG',
            checked: false,
            value: 'jpg',
        },
        {
            id: 3,
            name: 'PNG',
            checked: false,
            value: 'png',
        },
        {
            id: 4,
            name: 'GIF',
            checked: false,
            value: 'gif',
        },
        {
            id: 5,
            name: 'MP4',
            checked: false,
            value: 'mp4',
        },
        {
            id: 6,
            name: 'MOV',
            checked: false,
            value: 'mov',
        },
        {
            id: 7,
            name: 'MP3',
            checked: false,
            value: 'mp3',
        }
    ],
};
