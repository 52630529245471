// @flow
import appConfig from 'config';
import axios from 'axios';
import { getLoggedInUser } from './authUtils';
import { fetchCount, fetchData } from './api.services';
import qs from 'qs';

export const baseUrl = 'https://apilw.html5.run';

const makeHeader = (jwt) => {
  let ujwt = jwt;
  if (!jwt) {
    let user = getLoggedInUser();
    ujwt = user?.jwt;
  }
  return {
    headers: {
      Authorization: 'Bearer ' + ujwt,
    },
  };
};

export const newAssets = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.assets, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const newMediaUpload = async (formData, onProgress = null) => {
  const header = makeHeader();
  try {
    return await axios
      .post(appConfig.upload, formData, {
        ...header,
        onUploadProgress: (e) => {
          if (onProgress) {
            onProgress({ percent: (e.loaded / e.total) * 100 });
          }
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);

        return [];
      });
  } catch (error) {
    console.log(error);
  }
};

export const createWithAI = async (data) => {
  try {
    return await axios.post(appConfig.createWithAi, data).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const createAISlide = async (data) => {
  // const header = makeHeader();
  try {
    return await axios.post('https://aibdev.gnx.sh/api/v1/pipeline/articulate-studio-slides', data).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const editProject = async (ID, data) => {
  const header = makeHeader();
  try {
    return await axios.put(appConfig.projects + '/' + ID, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const createProject = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.projects, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAssets = async (queryObject) => {
  const queryString = qs.stringify(queryObject, { encode: false });
  delete queryObject?.start;
  delete queryObject?.limit;
  delete queryObject?.sort;
  const countQuery = qs.stringify(queryObject, { encode: false });
  try {
    const response = await fetchData(`${appConfig.assets}?${queryString}`);
    const count = await fetchCount(`${appConfig.assets.slice(0, -1)}/count?${countQuery}`);
    return {
      items: response?.data || [],
      count: count?.entity || 0,
    };
  } catch (error) {
    throw new Error(error);
  }
};
