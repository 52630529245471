import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SimpleWysiwyg from './CustomEditor';
import { elementChanged, elementUpdated } from 'redux-layout/actions';

export const MultilineEdit = ({ value, style, elementObj }) => {
    const [editingValue, setEditingValue] = React.useState(value);
    const dispatch = useDispatch();
    const editorRef = React.useRef();
    const updateElement = (value) => {
        if (value) {
            dispatch(elementUpdated(elementObj, value, 'text'));
        } else {
            let editorData = editorRef.current.innerHTML;
            if (editorData !== elementObj.content.text) {
                dispatch(elementUpdated(elementObj, editorRef.current.innerHTML, 'text'));
            }
        }
    };



    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.innerHTML = value;
        }
    }, [style.fontSize, style.fontFamily, style.color, style.backgroundColor, style.textAlign, style.textTransform]);

    return (
        <SimpleWysiwyg
            className="edit-textarea"
            style={{ ...style, resize: 'none' }}
            rows={1}
            aria-label="Field name"
            value={editingValue}
            placeholder={elementObj?.content?.placeholder || ''}
            onMouseDown={(e) => dispatch(elementChanged(elementObj))}
            updateElement={updateElement}
            key={JSON.stringify(style)}
            ref={editorRef}

        />
    );
};
