import React, { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Box, Tooltip } from '@mui/material';
import Photo from './Photo';
import Video from './Video';
import Audio from './Audio';
import { CustomTabList, CustomTooltip } from './styles';
import { ComingSoonIcon } from 'config/svgs';

const Uploads = (props) => {
  const { elementType, setElementType } = props;
  const [tabValue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <TabContext value={tabValue}>
        <CustomTabList onChange={handleChange} aria-label="upload tabs">
          <Tab label="Photo" value="1" />
          <CustomTooltip title="Coming Soon" arrow>
            <Box sx={{ position: 'relative' }}>
              <Tab
                label="Video"
                value="2"
                disabled
                icon={
                  <Box position="absolute" right="0px" top="12px">
                    <ComingSoonIcon />
                  </Box>
                }
                sx={{
                  cursor: 'not-allowed',
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </CustomTooltip>
          <CustomTooltip title="Coming Soon" arrow>
            <Box sx={{ position: 'relative' }}>
              <Tab
                label="Audio"
                value="3"
                disabled
                icon={
                  <Box position="absolute" right="0px" top="12px">
                    <ComingSoonIcon />
                  </Box>
                }
                sx={{
                  cursor: 'not-allowed',
                  '&.Mui-disabled': {
                    opacity: 1,
                  },
                }}
              />
            </Box>
          </CustomTooltip>
        </CustomTabList>
        <TabPanel value="1" sx={{ width: '100%', padding: '15px' }}>
          <Photo elementType={elementType} setElementType={setElementType} />
        </TabPanel>
        <TabPanel value="2" sx={{ width: '100%' }}>
          <Video />
        </TabPanel>
        <TabPanel value="3" sx={{ width: '100%' }}>
          <Audio />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Uploads;
