import React, { useEffect, useState } from 'react';
import Header from './Header';
import { Container } from './styles';
import { InsertIcon } from 'pages/studio/Components/SVGs';
import { Button, Snackbar } from '@mui/material';
import Guidelines from './Guidelines';
import { useStore } from 'Socket';
import { useDispatch, useSelector } from 'react-redux';
import { slideReplace } from 'redux-layout/actions';
import { newID } from 'helpers/studioApi';

const RegenerateSlide = ({ setElementType }) => {
  const dispatch = useDispatch();
  const [previewText, setPreviewText] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState('');
  const { emit, events, setEvents } = useStore();
  const [newSlide, setNewSlide] = useState({});
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    console.log('events', events);
    const successEvent = events.find((item) => item.event === 'pipeline:execute:success');
    const errorEvent = events.find((item) => item.event === 'pipeline:execute:error');

    if (successEvent?.event === 'pipeline:execute:success') {
      try {
        const response = JSON.parse(successEvent.payload);
        let NewSlide = {
          ...response?.json?.project?.slides[0],
          id: newID('slide'),
        };
        if (response?.json?.title) {
          setNewSlide(NewSlide);
          setLoading(false);
          setOpenPreview(true);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else if (errorEvent?.event === 'pipeline:execute:error') {
      setError('AI generation failed to regenerate the slide');
      setLoading(false);
      setOpenSnackbar(true);
      setEvents([]);
    }
  }, [events, setEvents]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSlideInsert = () => {
    dispatch(slideReplace(newSlide));
    setOpenPreview(false);
  };

  return (
    <Container>
      <Header setElementType={setElementType} />
      <Guidelines
        previewText={previewText}
        setPreviewText={setPreviewText}
        emit={emit}
        setOpenSnackbar={setOpenSnackbar}
        loading={loading}
        setLoading={setLoading}
        setError={setError}
        handleSlideInsert={handleSlideInsert}
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        preview={newSlide}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={error}
        severity={'error'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Container>
  );
};

export default RegenerateSlide;
