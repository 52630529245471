import React, { useState, useEffect } from 'react';
import { BoxContainer } from './style';
import { Button, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import { handleAddElement } from 'redux-layout/actions';
import { newID } from 'helpers/studioApi';
import appConfig from 'config';

const TextBoxes = ({ title, textBoxData, elementLoadMore, elementLoading, elementError, elementCount, handleTextBoxImgAdd }) => {


  

  return (
    <React.Fragment>
      <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left', mt: 3, mb: 1 }}>
        {title}
      </Typography>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {textBoxData.map((item, index) => {
                return (
                  <Grid item key={index} xs={6}>
                    <BoxContainer>
                      <IconButton onClick={() => handleTextBoxImgAdd(item)}>
                        <img className="img-contain" src={`${baseUrl}${item?.url}`} alt={`${baseUrl}${item?.url}`} />
                      </IconButton>
                    </BoxContainer>
                  </Grid>
                );
              })}
            </Grid>
            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {textBoxData?.length === elementCount ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={elementLoadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default TextBoxes;
