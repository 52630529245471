import styled from '@emotion/styled';
import { Box, Tooltip } from '@mui/material';
import React from 'react';

export const TopbarTab = styled.div`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 4px;
`;

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
`;

export const LeftbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: ${({ height }) => height || 'auto'};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const MoreButton = styled.div`
  right: 20px;
  top: 20px;
  background-color: #262725;
  position: absolute;
  color: #fff;
  height: 23px;
  border-radius: 4px;
  display: none;
`;

export const CardRoot = styled.div`
  position: relative;
  &:hover .more-button {
    display: flex;
  }
`;

export const BoxAlign = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 82%;
`;


export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '4px',
    padding: '12px',
    boxShadow: theme.shadows[1],
  },
  '& .MuiTooltip-arrow': {
    color: '#fff',
  },
}));