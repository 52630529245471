import styled from '@emotion/styled';
import { Box, Button, Divider, IconButton, Menu, Stack, TextField, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const PhotoContainer = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;

export const CustomButton = styled(Button)`
  position: relative;
  border: ${({ selected }) => (selected ? '4px solid #F7B035' : 'none')};
  border-radius: 8px;
  padding: 0;
  min-width: unset;
`;

export const CustomIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 0px;
`;

export const CustomTypography = styled(Typography)`
  color: ${({ palette }) => palette?.background?.default};
  text-align: left;
`;

export const CustomStack = styled(Stack)`
  width: 100%;
`;

export const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    max-height: 48 * 4.5;
    width: 20ch;
  }
`;

export const TextInput = styled(TextField)`
  border-radius: 8px;
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
`;

export const TextBoxButton = styled(Button)`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #009569;
  background: #009569;
  color: #fff;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: #009569;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const BoxContainer = styled(Box)`
  width: 100%;
  height: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;

export const SwipeButtonRight = styled(ChevronRightIcon)`
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 8px var(--spacing-none, 0px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(270deg, #262725 0%, rgba(38, 39, 37, 0) 100%);
  min-height: 60px;
  margin-top: 12px;
`;
export const SwipeButtonLeft = styled(ChevronLeftIcon)`
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 8px var(--spacing-none, 0px);
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(270deg, #262725 0%, rgba(38, 39, 37, 0) 100%);
  min-height: 60px;
  margin-top: 12px;
`;

export const ColorLensIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  background: '#595D62',
  height: '50px',
  width: '1px',
}));

export const ColorPickerContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  top: '120px'
}));

export const CarouselContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  position: 'relative',
}));

export const ColorCircle = styled('div')(({ color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  width: 40,
  height: 40,
}));

export const NavButtonStyles = {
  backgroundColor: 'transparent',
  borderRadius: 0,
  marginRight: '-14px',
  marginLeft: '-14px',
  zIndex: 10,
};
