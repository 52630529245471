import React from 'react';
import { Rnd as ReactRndRotate } from 'react-rnd';
import { ResizeHandles } from './ResizeHandles';

export const Rnd = ({
    children,
    ...rest
}) => {
    return (
        <ReactRndRotate
            {...rest}
        >
            <ResizeHandles />
            {children}
        </ReactRndRotate>
    );
};
