import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import Input from './Input';
import Select from './Select';
import Chechbox from './Checkbox';
import Textarea from './Textarea';
import Switch from './Switch';

import { useRadFormikContext } from './useFormik';
import AutoComplete from './AutoComplete';
import File from './File';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  tip: PropTypes.string,
  error: PropTypes.string,
  errorMsg: PropTypes.string,
  name: PropTypes.string,
};

const generateField = (FormComponent) => {
  const FieldComponent = ({
    className = undefined,
    tip = undefined,
    error = undefined,
    errorMsg = undefined,
    name = undefined,
    helperText,
    label,
    required = false,
    placeholder,
    ...otherProps
  }) => {
    const fieldId = uniqueId('form-field-');
    let classes = 'from-field';
    const errorText = helperText ? helperText : getErrorText(error, errorMsg); // ...helperText overridden by otherProps
    const isError = !!(error || errorMsg);

    const validationSchema = useRadFormikContext();

    const validation = useMemo(() => {
      if (validationSchema && validationSchema?.fields) {
        return validationSchema.fields[name] ? validationSchema.fields[name] : undefined;
      }
      return undefined;
    }, [name, validationSchema]);

    const isRequired = useMemo(() => {
      return !!validation?.exclusiveTests?.required || required;
    }, [validation]);

    const fieldLabel = useMemo(() => {
      return label && label !== ''
        ? isRequired && !label.includes('*')
          ? `${label} *`
          : label
        : '';
    }, [isRequired, label]);

    const fieldPlaceholder = useMemo(() => {
      return placeholder && placeholder !== ''
        ? isRequired
          ? `${placeholder} *`
          : placeholder
        : '';
    }, [isRequired, placeholder]);

    return (
      <FormComponent
        margin="normal"
        id={fieldId}
        name={name}
        helperText={errorText ? errorText : tip}
        error={isError}
        className={className}
        validation={validation}
        label={fieldLabel}
        placeholder={fieldPlaceholder}
        {...otherProps}
      />
    );
  };

  FieldComponent.propTypes = propTypes;

  return FieldComponent;
};

export default {
  Input: generateField(Input),
  Checkbox: generateField(Chechbox),
  Select: generateField(Select),
  Textarea: generateField(Textarea),
  Switch: generateField(Switch),
  AutoComplete: generateField(AutoComplete),
  File: generateField(File),
};

const getErrorText = (error, errorMsg) => {
  if (error) { return error; }
  else if (errorMsg) { return errorMsg; }
  else { false; }
};
