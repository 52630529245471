import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import styled from '@emotion/styled';
import theme, { palette } from 'config/theme';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { handleRedo, handleUndo, logoutUser, ProjectUpdate } from 'redux-layout/actions';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { ChildMenuItem, FlexMenuItem, ModalContainer, ShortcutText, TextInput } from './styles';
import { FileIcon, OpenFileIcon, PublishIcon, RegenerateLessonIcon } from 'config/svgs';
import { Modal } from 'components/shared';
import { createData } from 'helpers/api.services';
import appConfig from 'config';
import Open from './Component/Open';
import { createProject, editProject } from 'helpers/apiCalls';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Publish from './Component/Publish';
import { set } from 'lodash';
import CreateLesson from './Component/CreateLesson';
import RegenerateLesson from './Component/RegenerateLesson';
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.background.bg1};
  height: 56px;
  padding: 0 16px;
`;

const LibraryBody = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`;

const StudioLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);
  const { project, title, projectId } = useSelector((state) => state.Canvas.present);
  const { loading } = useSelector((state) => state.Studio);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileEl, setFileEl] = useState(null);
  const [isProjectEdit, setIsProjectEdit] = useState(false);
  const [publishEl, setPublishEl] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [projectFileName, setProjectFileName] = useState('');
  const [isFileModelOpen, setIsFileModelOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });
  const [isPublishModelOpen, setIsPublishModelOpen] = useState(false);
  const [createLessonOpen, setCreateLessonOpen] = useState(false);
  const [regenerateLessonOpen, setRegenerateLessonOpen] = useState(false);

  useEffect(() => {
    if (title) {
      setProjectName(title);
      setProjectFileName(title);
    }
  }, [title]);

  const handlePublishOpen = (event) => {
    event.stopPropagation();
    setPublishEl(event.currentTarget);
  };

  const onFileModelOpen = () => {
    setModalType('open');
    setIsFileModelOpen(true);
  };

  const onFileModelClose = () => {
    setIsFileModelOpen(false);
    setModalType('');
    setFileEl(null);
  };

  const handleCreateLessonClose = () => {
    setCreateLessonOpen(false);
    setModalType('');
    setFileEl(null);
  };

  const handleRegenerateLessonClose = () => {
    setRegenerateLessonOpen(false);
    setModalType('');
    setFileEl(null);
  };

  const onPublishModelClose = () => {
    setFileEl(null);
    setIsPublishModelOpen(false);
    setModalType('');
  };
  const open = Boolean(anchorEl);
  const fileOpen = Boolean(fileEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFileClick = (event) => {
    event.stopPropagation();
    setFileEl(event.currentTarget);
  };

  const handleFileClose = () => {
    setFileEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser(null));
    setAnchorEl(null);
    navigate('/account/logout');
  };

  const handleProjectEdit = () => {
    setIsProjectEdit(true);
  };

  const saveProjectName = () => {
    dispatch(ProjectUpdate(projectName));
    setIsProjectEdit(false);
  };

  const saveProjectFileName = () => {
    dispatch(ProjectUpdate(projectFileName));
  };

  const handleCreateNewFile = async () => {
    let payload = {
      title: 'No template project',
      description: '',
      slides: [],
      template: {},
      project: appConfig.initailProject,
      username: user.user.username,
      theme: undefined,
    };
    let response = await createData('projects', payload);
    setFileEl(null);
    if (response?.data?.id) {
      window.open(`/studio/${response.data.id}`, '_blank');
    }
  };

  const handlePublishClose = () => {
    // handlePublish();
    setPublishEl(null);
  };

  const handleCreateLesson = () => {
    setModalType('create-lesson');
    setCreateLessonOpen(true);
  };

  const handleRegenerateLesson = () => {
    setModalType('regenerate-lesson');
    setRegenerateLessonOpen(true);
  };

  const modalView = useMemo(() => {
    switch (modalType) {
      case 'open':
        return (
          <Modal open={isFileModelOpen} onClose={onFileModelClose} title={'Library'} size="md">
            <Open onFileModelClose={onFileModelClose} setFileEl={setFileEl} />
          </Modal>
        );
      case 'publish':
        return (
          <Modal open={isPublishModelOpen} onClose={onPublishModelClose} title={'Publish'} size="md">
            <Publish onPublishModelClose={onPublishModelClose} />
          </Modal>
        );
      case 'create-lesson':
        return (
          <Modal
            open={createLessonOpen}
            onClose={handleCreateLessonClose}
            title={'Regenerate lesson using AI'}
            size="sm">
            <CreateLesson
              handleCreateLessonClose={handleCreateLessonClose}
              handleRegenerateLesson={handleRegenerateLesson}
            />
          </Modal>
        );
      case 'regenerate-lesson':
        return (
          <Modal
            open={regenerateLessonOpen}
            onClose={handleRegenerateLessonClose}
            title={'Regenerate lesson using AI'}
            size="md">
            <RegenerateLesson handleRegenerateLessonClose={handleRegenerateLessonClose} />
          </Modal>
        );
      default:
        return null;
    }
  }, [modalType]);

  const handlePublish = (event) => {
    setFileEl(event?.currentTarget);
    setModalType('publish');
    setIsPublishModelOpen(true);
  };

  const handlePreview = () => {
    window.open(`https://stplr.superteach.org/?prj=${projectId}`, '_blank');
  };

  return (
    <LibraryBody>
      <Container>
        <Box sx={{ width: '100%' }}>
          <Button variant="backBtn" onClick={() => navigate('/library')}>
            Home
          </Button>
          <Button variant="backBtn" onClick={handleFileClick}>
            File
          </Button>
          <Menu
            id="basic-menu"
            sx={{ borderRadius: '12px' }}
            anchorEl={fileEl}
            open={fileOpen}
            onClose={handleFileClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              sx: {
                width: '350px',
                borderRadius: '12px',
              },
            }}>
            <MenuItem>
              <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                Project Name
              </Typography>
            </MenuItem>
            <MenuItem>
              <TextInput
                value={projectFileName}
                onChange={(e) => setProjectFileName(e.target.value)}
                onBlur={saveProjectFileName}
                placeholder={title}
                variant="outlined"
                fullWidth
              />
            </MenuItem>
            <Divider sx={{ backgroundColor: palette?.text?.grey4 }} />
            <FlexMenuItem onClick={handleCreateNewFile}>
              <ChildMenuItem>
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  New File
                </Typography>
              </ChildMenuItem>

              <ShortcutText variant="body4">⇧⌘N</ShortcutText>
            </FlexMenuItem>
            <FlexMenuItem onClick={() => onFileModelOpen()}>
              <ChildMenuItem>
                <ListItemIcon>
                  <OpenFileIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  Open
                </Typography>
              </ChildMenuItem>
              <ShortcutText variant="body4">⇧⌘O</ShortcutText>
            </FlexMenuItem>
            {modalView}
            <FlexMenuItem onClick={handleCreateLesson}>
              <ChildMenuItem>
                <ListItemIcon>
                  <RegenerateLessonIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  Regenerate Lesson
                </Typography>
              </ChildMenuItem>
            </FlexMenuItem>
            <FlexMenuItem onMouseEnter={handlePublishOpen}>
              <ChildMenuItem>
                <ListItemIcon>
                  <PublishIcon />
                </ListItemIcon>
                <Typography variant="body4" sx={{ color: palette?.text?.black }}>
                  Publish
                </Typography>
              </ChildMenuItem>
              <ChevronRightOutlinedIcon sx={{ color: palette?.text?.black }} />
            </FlexMenuItem>
            <Popover
              anchorEl={publishEl}
              open={Boolean(publishEl)}
              onClose={handlePublishClose}
              PaperProps={{
                sx: {
                  padding: '5px',
                  marginLeft: '10px',
                  borderRadius: '10px',
                },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              disableRestoreFocus>
              <MenuItem onClick={handlePublishClose}>
                <Typography variant="body3" sx={{ color: palette?.text?.black }}>
                  Publish to School
                </Typography>
              </MenuItem>
              <MenuItem onClick={handlePublishClose}>
                <Typography variant="body3" sx={{ color: palette?.text?.black }}>
                  Publish to LifeWise
                </Typography>
              </MenuItem>
            </Popover>
          </Menu>

          {isProjectEdit ? (
            <TextInput
              variant="outlined"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              onBlur={saveProjectName}
              placeholder={title}
              sx={{ width: '30%', border: 'none' }}
            />
          ) : (
            <Button variant="backBtn" endIcon={<EditOutlinedIcon />} onClick={() => handleProjectEdit()}>
              {title}
            </Button>
          )}

          <Button variant="backBtn" onClick={() => dispatch(handleUndo())}>
            <UndoOutlinedIcon />
          </Button>
          <Button variant="backBtn" onClick={() => dispatch(handleRedo())}>
            <RedoOutlinedIcon />
          </Button>
          <Button variant="backBtn" startIcon={<CloudDoneOutlinedIcon />}>
            {loading ? 'Saving...' : 'Saved'}
          </Button>
        </Box>
        <Box display="flex" gap="1rem">
          <Button variant="preview" startIcon={<RemoveRedEyeOutlinedIcon />} onClick={handlePreview}>
            Preview
          </Button>
          <Button variant="preview" startIcon={<FileUploadOutlinedIcon />} onClick={(e) => handlePublish(e)}>
            Publish
          </Button>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <PersonIcon sx={{ color: 'background.paper' }} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonIcon sx={{ color: 'background.paper' }} />
              </ListItemIcon>
              <ListItemText>profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Container>
      <Outlet />
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </LibraryBody>
  );
};

export default StudioLayout;
