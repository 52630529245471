import appConfig from 'config';

export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) { return '0 Byte'; }
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' (' + sizes[i] + ')';
};

export const cookieUtils = {
    get: function (name) {
        const cookieName = encodeURIComponent(name) + '=';
        const cookieStart = document.cookie.indexOf(cookieName);
        let cookieValue = null;

        if (cookieStart > -1) {
            let cookieEnd = document.cookie.indexOf(';', cookieStart);
            if (cookieEnd === -1) {
                cookieEnd = document.cookie.length;
            }
            cookieValue = decodeURIComponent(
                document.cookie.substring(cookieStart + cookieName.length, cookieEnd)
            );
        }
        return cookieValue;
    },

    set: function (name, value, days) {
        let expires = '';
        if (days && days > 0) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }

        const cookieDomain = appConfig.cookiesDoamin || 'localhost'; // Add fallback
        document.cookie =
            encodeURIComponent(name) +
            '=' +
            encodeURIComponent(value || '') +
            expires +
            `; domain=${cookieDomain}; path=/`;
    },

    unset: function (name) {
        this.set(name, '', -1); // Set expiry to the past
    },
};


export const getRange = (plans) => {
    let numbers = [];

    // Extract numbers from the name field and add to the numbers array
    plans.forEach(plan => {
        const [start, end] = plan.name.split(' - ').map(Number);
        numbers.push(start, end);
    });

    // Find the minimum and maximum values
    const min = Math.min(...numbers);
    const max = Math.max(...numbers);

    return `${min} - ${max}`;
};
