import React, { useState } from 'react';
import { TextBoxButton } from './style';
import { Box, Button, Divider } from '@mui/material';
import FileUploader from 'components/shared/FileUploader';
import { newAssets } from 'helpers/apiCalls';
import { Modal } from 'components/shared';
import { useSelector } from 'react-redux';

const Upload = ({ refreshData }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onModelOpen = () => setIsModelOpen(true);
  const onModelClose = () => setIsModelOpen(false);
  const { user } = useSelector((state) => state.Auth);

  const HandleUploadAssets = async () => {
    setIsLoading(true);
    try {
      let makeApi = uploadedFile.map((file) => {
        let name = file.name.split('.')[0] || '';
        let extensionType = '';
        const regexImage = /\.(jpg|jpeg)$/i;
        const regexGif = /\.(gif)$/i;
        const regexPng = /\.(png)$/i;
        const regexSvg = /\.(svg)$/i;
        if (regexImage.test(file.name)) {
          extensionType = 'jpg';
        } else if (regexGif.test(file.name)) {
          extensionType = 'Gif';
        } else if (regexPng.test(file.name)) {
          extensionType = 'png';
        } else if (regexSvg.test(file.name)) {
          extensionType = 'svg';
        }
        let data = {
          data: {
            asset: file.id,
            type: 'user',
            contenttype: 'user',
            name,
            assetType: extensionType,
            username: user?.user?.email
          },
        };
        newAssets(data)
          .then((res) => {
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      });
      let response = await Promise.all(makeApi);
      refreshData();
    } catch (error) {
      console.log('error', error);
    }
    onModelClose();
  };
  return (
    <>
      <TextBoxButton variant="btnText4" onClick={onModelOpen}>
        Upload
      </TextBoxButton>
      <Modal open={isModelOpen} onClose={onModelClose} title={'Upload File'} size="md">
        <FileUploader setUploadedFile={setUploadedFile} />
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {isLoading ? (
            <Button variant="contained" color="secondary" disabled>
              Uploading...
            </Button>
          ) : (
            <Button variant="contained" color="secondary" onClick={HandleUploadAssets} disabled={uploadedFile.length === 0}>
              Done
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Upload;
