import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ScrollContainer, ErrorMessage } from './styles';
import { palette } from 'config/theme';
import Loader from 'pages/studio/Components/Loader';
import { useDispatch } from 'react-redux';
import { handleAddElement } from 'redux-layout/actions';
import { newID } from 'helpers/studioApi';
import appConfig from 'config';

const ShapesElement = ({ data, elementError, elementLoading }) => {
  const dispatch = useDispatch();

  const [showAllLines, setShowAllLines] = useState(false);
  const [showAllShapes, setShowAllShapes] = useState(false);

  const handleSeeAllLines = () => setShowAllLines(!showAllLines);
  const handleSeeAllShapes = () => setShowAllShapes(!showAllShapes);
  const filterItems = (type) => data.filter((item) => item.iconType === type);
  const displayItems = (items, showAll) => (showAll ? items : items.filter((item) => !item.is_see_all));

  const handleSVGAdd = (item) => {
    const { code } = item || {};
    const defaultImg = { ...appConfig.elemSvg };

    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(code, 'image/svg+xml');

    const svgElement = svgDoc.querySelector('svg');
    const attributes = {};
    let width = 0,
      height = 0;
    if (svgElement) {
      Array.from(svgElement.attributes).forEach((attr) => {
        attributes[attr.name] = attr.value;
        if (attr.name === 'width') width = parseFloat(attr.value);
        if (attr.name === 'height') height = parseFloat(attr.value);
      });
    }

    const children = [];
    svgDoc.querySelectorAll('path').forEach((path) => {
      const d = path.getAttribute('d');
      const fill = path.getAttribute('fill') || 'none';
      children.push({ fill, d });
    });

    const element = {
      ...defaultImg,
      id: newID('element'),
      content: {
        d: children.length > 0 ? children[0].d : '',
      },
      attributes,
      childrens: children,
      position: {
        ...defaultImg.position,
        height: (height / width) * 20,
        width: 20,
      },
    };

    dispatch(handleAddElement(element));
  };
  return (
    <ScrollContainer>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            {elementLoading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <Stack direction="column" alignItems="flex-start" justifyContent="center" sx={{ width: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%', ml: 2 }}>
                  <Typography variant="body2" color={palette.background.paper}>
                    Lines
                  </Typography>
                  <Button variant="seeAll" onClick={handleSeeAllLines}>
                    {showAllLines ? 'Show Less' : 'See All'}
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  {displayItems(filterItems('Lines'), showAllLines).map((item, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
                      <IconButton onClick={() => handleSVGAdd(item)}>
                        <div dangerouslySetInnerHTML={{ __html: item?.code || '' }} />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%', ml: 2, mt: 3 }}>
                  <Typography variant="body2" color={palette.background.paper}>
                    Shapes
                  </Typography>
                  <Button variant="seeAll" onClick={handleSeeAllShapes}>
                    {showAllShapes ? 'Show Less' : 'See All'}
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  {displayItems(filterItems('Basic Shapes'), showAllShapes).map((item, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={2.4}>
                      <IconButton onClick={() => handleSVGAdd(item)}>
                        <div dangerouslySetInnerHTML={{ __html: item?.code || '' }} />
                      </IconButton>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            )}
          </React.Fragment>
        )}
      </Grid>
    </ScrollContainer>
  );
};

export default ShapesElement;
