import React, { useState } from 'react';
import { Container } from './styles';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import { Box, Slider } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CompressIcon } from 'config/svgs';

const Footer = () => {
    const [value, setValue] = useState(0);


    const handleFullscreen = () => {
        let elem = document.documentElement;
        if (document.webkitIsFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
        } else {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
        }
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="flex-end" alignItems="center" gap="1rem">
                {/* <Box>
                    <GridOnIcon />
                </Box> */}
                <Box width="10%">
                    <Slider size="small" color="secondary" aria-label="Zoom" value={value} onChange={handleChange} />
                </Box>
                <Box onClick={handleFullscreen}>
                    {document.webkitIsFullScreen ? <CompressIcon /> : <OpenInFullIcon color="secondary" />}

                </Box>
                <Box>
                    <HelpOutlineIcon color="secondary" />
                </Box>
            </Box>
        </Container>
    );
};

export default Footer;
