import React from 'react';
import { LeftbarContainer } from './styles';
import { Divider, Typography } from '@mui/material';
import { leftbarOptions } from './config';
import Spacer from 'components/shared/Spacer';
import { CheckBox } from 'components/shared';

const Leftbar = () => {
    const onChange = () => {

    };

    return (
        <LeftbarContainer>
            {Object.keys(leftbarOptions).map((option, index, arr) => (
                <React.Fragment key={index}>
                    <Typography variant="body1">{option}</Typography>
                    {leftbarOptions[option].map((item) => (
                        <CheckBox key={item.id} label={item.name} onChange={onChange} />
                    ))}
                    {arr.length - 1 !== index && (
                        <React.Fragment>
                            <Spacer x={1} y={1} />
                            <Divider />
                            <Spacer x={1} y={1} />
                        </React.Fragment>

                    )}
                </React.Fragment>
            ))}
        </LeftbarContainer>
    );
};

export default Leftbar;
