import React from 'react';
import { BoxContainer } from './style';
import { Button, CircularProgress, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';

const Video = ({ title, subTitle, videosData, elementLoading, elementError }) => {
  return (
    <React.Fragment>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Typography variant="body2" sx={{ color: palette?.background?.default, textAlign: 'left' }}>
          {title}
        </Typography>
        {subTitle ? <Button variant="seeAll">{subTitle}</Button> : null}
      </Stack>
      <Grid container spacing={2}>
        {elementLoading && !uploadPhotoData ? (
          Array.from(new Array(16)).map((_, index) => (
            <Grid item xs={6} key={index}>
              <BoxContainer>
                <Skeleton variant="rectangular" width="100%" height="100%" />
              </BoxContainer>
            </Grid>
          ))
        ) : elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          videosData &&
          videosData.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <BoxContainer>
                  {/* <img className="img-contain" src={`${baseUrl}${item}`} alt={`${baseUrl}${item}`} /> */}
                </BoxContainer>
              </Grid>
            </React.Fragment>
          ))
        )}
      </Grid>
    </React.Fragment>
  );
};

export default Video;
