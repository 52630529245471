import React, { forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { IconButton, Menu, MenuItem, Fade, ButtonBase, Typography } from '@mui/material';
import { InputAdornment, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import NestedMenuItem from './nestedMenu';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filter: PropTypes.instanceOf(RegExp),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  loading: PropTypes.bool,
  showPicker: PropTypes.bool,
  pickerIcon: PropTypes.any,
  options: PropTypes.array,
  optLabel: PropTypes.string,
  optValue: PropTypes.string,
  color: PropTypes.string,
};

const InputComponent = forwardRef(
  (
    {
      className,
      filter,
      onChange,
      variant,
      helperText,
      error,
      id,
      loading,
      type,
      InputProps,
      disabled,
      showPicker,
      options,
      optLabel,
      optValue,
      pickerIcon,
      color,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [cursorPos, setCursorPos] = React.useState(0);
    const positionRef = useRef();
    positionRef.current = cursorPos;

    const inputRef = useRef(null);

    const handleChange = (event) => {
      if (!filter || filter.test(event.target.value)) {
        onChange(event.target.value, event);
      }
    };

    const inputProps = {
      ...InputProps,
      endAdornment: (
        <>
          {showPicker && (
            <InputAdornment position="end">
              <ButtonBase
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                {pickerIcon}
              </ButtonBase>
            </InputAdornment>
          )}
          {loading && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          )}
          {type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                size="large"
              >
                {showPassword ? <VisibilityOff size={20} /> : <Visibility size={20} />}
              </IconButton>
            </InputAdornment>
          )}

          {InputProps?.endAdornment ? InputProps?.endAdornment : null}
        </>
      ),
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOptionClick = (option) => {
      changeWithCursor(option[optValue]);
      setCursorPos((cursorPos) => cursorPos + option[optValue].length);
    };

    const changeWithCursor = (contentToCopy) => {
      const cursorPos = positionRef.current;
      const currentValue = inputRef.current.value;
      let newValue = '';
      if (cursorPos > 0) {
        newValue = `${currentValue.slice(0, cursorPos)}${contentToCopy}${currentValue.slice(
          cursorPos,
        )}`;
      } else {
        newValue = `${contentToCopy}${currentValue}`;
      }
      onChange(newValue, null);
      setAnchorEl(null);
    };

    const handleCursorChange = (event) => {
      if (typeof event.target.selectionStart !== 'undefined') {
        setCursorPos(event.target.selectionStart);
      }
    };

    const onKeyUp = (event) => {
      handleCursorChange(event);
    };

    const handleBlur = (event) => { };

    const handleFocus = (event) => { };

    const contactVariables = useMemo(() => {
      if (options && options.length) {
        return options.filter((item) => item.text.includes('Contact'));
      }
      return [];
    }, [options]);
    const senderVariables = useMemo(() => {
      if (options && options.length) {
        return options.filter((item) => item.text.includes('Sender'));
      }
      return [];
    }, [options]);
    const otherVariables = useMemo(() => {
      if (options && options.length) {
        return options.filter(
          (item) => !item.text.includes('Sender') && !item.text.includes('Contact'),
        );
      }
      return [];
    }, [options]);

    const filteredOptions = [
      {
        type: 'nestedMenuItem',
        text: 'Contact',
        getSubmenuItems: () =>
          contactVariables.map((item) => ({
            ...item,
            type: 'menuItem',
          })),
      },
      {
        type: 'nestedMenuItem',
        text: 'Sender',
        getSubmenuItems: () =>
          senderVariables.map((item) => ({
            ...item,
            type: 'menuItem',
          })),
      },
      ...otherVariables.map((item) => ({
        ...item,
        type: 'menuItem',
      })),
    ];

    return (
      <>
        <TextField
          className={className}
          type={type === 'password' && showPassword ? 'text' : type}
          onChange={handleChange}
          inputref={inputRef}
          variant={variant}
          helperText={helperText}
          error={error}
          id={id}
          InputProps={inputProps}
          disabled={loading || disabled}
          color={color}
          onKeyUp={onKeyUp}
          onClick={onKeyUp}

          {...props}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        {showPicker ? (
          <Menu
            id={`${id}-picker`}
            anchorEl={anchorEl}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {filteredOptions.length ? (
              filteredOptions.map((option, index) => {
                if (option?.type === 'menuItem') {
                  return (
                    <MenuItem
                      key={`${id}-picker-option-${index}`}
                      value={option[optValue]}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option[optLabel]}
                    </MenuItem>
                  );
                }
                else if (option?.type === 'nestedMenuItem') {
                  return (
                    <NestedMenuItem
                      label={option[optLabel]}
                      rightAnchored={true}
                      parentMenuOpen={Boolean(anchorEl)}
                    >
                      {option?.getSubmenuItems()?.map((nestedOption, index) => (
                        <MenuItem
                          key={`${id}-picker-option-${index}`}
                          value={nestedOption[optValue]}
                          onClick={() => handleOptionClick(nestedOption)}
                        >
                          <Typography>{nestedOption[optLabel]}</Typography>
                        </MenuItem>
                      ))}
                    </NestedMenuItem>
                  );
                }
              })
            ) : (
              <MenuItem disabled>
                <Typography>No options.</Typography>
              </MenuItem>
            )}
          </Menu>
        ) : null}
      </>
    );
  },
);

InputComponent.displayName = 'InputComponent';
InputComponent.propTypes = propTypes;

export default InputComponent;
