import { fetchData } from 'helpers/api.services';
import {
  COPY_ELEMENT,
  ELEMENT_ADD,
  ELEMENT_CHANGED,
  ELEMENT_DELETED,
  ELEMENT_UPDATED,
  PROJECT_ERROR,
  PROJECT_TITLE,
  PROJECT_UPDATED,
  REDO,
  SLIDE_ADDED,
  SLIDE_DELETED,
  SLIDE_SELECTION_CHANGED,
  SLIDE_UPDATED,
  SLIDE_REPLACE,
  UNDO,
  NEW_SLIDE_ADD,
} from './constants';
import _ from 'lodash';
import { newID } from 'helpers/studioApi';

export const projectInit = (projectid, projectName) => async (dispatch) => {
  let response;
  let project;
  try {
    if (projectid) {
      switch (projectName) {
        case 'template':
          response = await fetchData(`/templates/${projectid}`);
          project = response.data?.attributes.template;
          break;
        case 'theme':
          response = await fetchData(`/themes/${projectid}`);
          project = response.data?.attributes.theme;
          break;
        default:
          response = await fetchData(`/projects/${projectid}`);
          project = response.data?.attributes.project;
          break;
      }
    }
  } catch (error) {
    dispatch({
      type: PROJECT_ERROR,
      payload: error,
    });
  }
  let newProject = _.cloneDeep(project);
  let newSlide = _.cloneDeep(project?.slides[0]);
  dispatch({
    type: PROJECT_UPDATED,
    payload: {
      project: newProject,
      slides: newProject?.slides,
      title: response?.data?.attributes?.title || '',
      elements: [],
      selectedSlide: newSlide,
      projectId: parseInt(projectid)
    },
  });
};

export const slideadded = (slide, index) => async (dispatch) => {
  dispatch({
    type: SLIDE_ADDED,
    payload: { slide, index },
  });
};

export const slideDeleted = () => async (dispatch) => {
  dispatch({
    type: SLIDE_DELETED,
  });
};

export const slideSelectionChanged = (selectedSlide) => async (dispatch) => {
  dispatch({
    type: SLIDE_SELECTION_CHANGED,
    payload: {
      selectedSlide,
    },
  });
};

export const elementChanged = (show) => async (dispatch) => {
  dispatch({
    type: ELEMENT_CHANGED,
    payload: { show },
  });
};

export const elementUpdated = (element, value, propName, position = 0) => async (dispatch, getState) => {
  const swapElements = (index1, index2) => {
    [slide.elements[index1], slide.elements[index2]] = [slide.elements[index2], slide.elements[index1]];
  };
  const { selectedSlide } = getState().Canvas.present;
  console.log('propname', selectedSlide.elements);
  const slide = _.cloneDeep(selectedSlide);
  const elementIndex = _.findIndex(slide.elements, { id: element.id });
  console.log('propname', propName, value, element);
  switch (propName) {
    case 'text':
      slide.elements[elementIndex].content.text = value;
      break;
    case 'zindex-src':
      slide.elements[elementIndex].content.src = value;
      break;
    case 'z-index':
      switch (value) {
        case 'bring_forward':
          slide.elements.push(...slide.elements.splice(elementIndex, 1));
          break;
        case 'send_backward':
          slide.elements.unshift(...slide.elements.splice(elementIndex, 1));
          break;
        case 'bring_front':
          if (elementIndex + 1 < slide.elements.length) {
            swapElements(elementIndex, elementIndex + 1);
          }
          break;
        case 'send_back':
          if (elementIndex > 0) {
            swapElements(elementIndex, elementIndex - 1);
          }
          break;
        default:
          break;
      }

      slide.elements = slide.elements.map((item, id) => ({
        ...item,
        style: {
          ...item.style,
          'z-index': id + 1,
        },
      }));
      break;
    case 'font-family':
    case 'font-size':
    case 'text-align':
    case 'text-transform':
    case 'color':
    case 'backgroundColor':
    case 'transformRotate':
      slide.elements[elementIndex].style[propName] = value;
      break;
    case 'top':
    case 'left':
    case 'right':
    case 'bottom':
    case 'middle':
      slide.elements[elementIndex].position[propName] = value;
      break;
    case 'svgcolor':
      slide.elements[elementIndex].childrens[0]['fill'] = value;
      slide.elements[elementIndex].style['stroke'] = value;
      slide.elements[elementIndex].style['color'] = value;
      break;
    case 'add_animation':
      slide.elements[elementIndex].event[3].call = 'addClass';
      slide.elements[elementIndex].event[3].type = 'click';
      slide.elements[elementIndex].styleClasses = ['animate__animated', `animate__${value}`];
      break;
    case 'callBehaviour':
      slide.elements[elementIndex].event[position].call = value;
      break;
    case 'typeBehaviour':
      slide.elements[elementIndex].event[position].type = value;
      break;
    case 'paramBehaviour':
      slide.elements[elementIndex].event[position].param = value;
      break;
    default:
      break;
  }
  dispatch({
    type: ELEMENT_UPDATED,
    payload: { slide },
  });
};

export const slideUpdated = (key, value) => async (dispatch, getState) => {
  const { selectedSlide } = getState().Canvas.present;
  const slide = _.cloneDeep(selectedSlide);
  switch (key) {
    case 'background-image':
      slide.style[key] = value;
      slide.style['background-color'] = '';
      slide.style['object-fit'] = 'cover';
      break;
    case 'background-color':
      slide.style['background-image'] = '';
      slide.style[key] = value;
      slide.style['object-fit'] = 'cover';
      break;
    default:
      break;
  }

  dispatch({
    type: SLIDE_UPDATED,
    payload: { slide },
  });
};

export const slideReplace = (getSlide) => async (dispatch, getState) => {
  const slide = _.cloneDeep(getSlide);
  // slide?.id = newID('slide');
  dispatch({
    type: SLIDE_REPLACE,
    payload: { slide },
  });
};

export const addNewSlide = (getSlide) => async (dispatch, getState) => {
  const slide = _.cloneDeep(getSlide);
  // slide?.id = newID('slide');
  dispatch({
    type: NEW_SLIDE_ADD,
    payload: { slide },
  });
};

export const handleAddElement = (element) => async (dispatch, getState) => {
  const { selectedSlide } = getState().Canvas.present;
  const slide = _.cloneDeep(selectedSlide);
  slide.elements.push(element);
  dispatch({
    type: ELEMENT_ADD,
    payload: { slide, element },
  });
};

export const handleRemoveElement = (element) => async (dispatch) => {
  dispatch({
    type: ELEMENT_DELETED,
    payload: { element },
  });
};

export const ProjectUpdate = (value) => async (dispatch) => {
  dispatch({
    type: PROJECT_TITLE,
    payload: { value },
  });
};

export const handleUndo = () => ({
  type: UNDO
});

export const handleRedo = () => ({
  type: REDO
});


export const copyElement = (element) => async (dispatch) => {
  dispatch({
    type: COPY_ELEMENT,
    payload: { element },
  });
};

export const pasteElement = () => async (dispatch, getState) => {
  const { selectedSlide } = getState().Canvas.present;
  const slide = _.cloneDeep(selectedSlide);
  const { copiedElement } = getState().Canvas.present;
  const element = _.cloneDeep(copiedElement);
  element.id = newID('element');
  slide.elements.push(element);
  dispatch({
    type: ELEMENT_ADD,
    payload: { slide, element },
  });
};
