import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography, FormGroup, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

const CheckboxInput = forwardRef(
  (
    { checked, className, label, onChange, variant, color, size, value, helperText, error, disabled = false, ...props },
    ref
  ) => {
    return (
      <React.Fragment>
        {label && label !== '' ? (
          <FormControl error={error}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    classes={className}
                    className={className}
                    variant={variant}
                    color={color}
                    size={size}
                    checked={value || false}
                    onChange={(e) => {
                      onChange(e.target.checked, e);
                    }}
                    {...props}
                  />
                }
                label={
                  <Typography color="textPrimary" variant="body2">
                    {label}
                  </Typography>
                }
              />
            </FormGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        ) : (
          <Checkbox
            disabled={disabled}
            classes={className}
            variant={variant}
            color={color}
            size={size}
            checked={value || false}
            onChange={(e) => {
              onChange(e.target.checked, e);
            }}
            {...props}
          />
        )}
      </React.Fragment>
    );
  }
);

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  size: PropTypes.oneOf(['medium', 'small']),
};

CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;
