

export const leftbarOptions = {
    ['Created by']: [
        {
            id: 1,
            name: 'Me'
        },
        {
            id: 2,
            name: 'My School'
        },
        {
            id: 3,
            name: 'LifeWise'
        }
    ]
};

export const contentFilter = [
    {
        value: 'recent',
        label: 'Recent'
    }
];
