import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import Card from 'components/App/LibraryCard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CardRoot, MoreButton } from './styles';
import appConfig from 'config';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteData } from 'helpers/api.services';

const CardContainer = ({ id, attributes, refreshData }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDownload = async () => {
        try {
            const imgUrl = attributes?.asset?.data[0]?.attributes?.url.replace('/', '');
            const response = await fetch(`${appConfig.socketURL}${imgUrl}`);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'image.png';
            link.click();
            URL.revokeObjectURL(link.href);
            handleClose();
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteData(`assets/${id}`);
            refreshData();
            handleClose();
        } catch (error) {
            console.log('error', error);
        }
    };

    function timeAgo(dateString) {
        const now = new Date();
        const createdAt = new Date(dateString);
        const timeDifference = Math.floor((now - createdAt) / 1000);
      
        if (timeDifference < 60) {
          return `${timeDifference} second${timeDifference !== 1 ? 's' : ''} ago`;
        } else if (timeDifference < 3600) {
          const minutes = Math.floor(timeDifference / 60);
          return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (timeDifference < 86400) {
          const hours = Math.floor(timeDifference / 3600);
          return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else {
          const days = Math.floor(timeDifference / 86400);
          return `${days} day${days !== 1 ? 's' : ''} ago`;
        }
      }

    return (
        <CardRoot>
            <Card
                tag={attributes?.assetType || 'Image'}
                image={appConfig.socketURL + attributes?.asset?.data[0]?.attributes?.url.replace('/', '')}
                name={attributes?.name}
                date={'1 day'}
                ratio={'1/1'}
                bgColor={'#484848'}
                isDate={true}
                timeAgo={timeAgo(attributes?.createdAt)}
            />
            <MoreButton onClick={handleMenuOpen} className="more-button">
                <MoreHorizIcon />
            </MoreButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleDownload}>
                    <ListItemIcon>
                        <DownloadIcon sx={{ color: 'background.paper' }} />
                    </ListItemIcon>
                    <ListItemText>Download asset</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon sx={{ color: 'background.paper' }} />
                    </ListItemIcon>
                    <ListItemText>Delete asset</ListItemText>
                </MenuItem>
            </Menu>
        </CardRoot >
    );
};

export default CardContainer;
