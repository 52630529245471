import React from 'react';
import { TextInput } from './styles';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { palette } from 'config/theme';

const LeftNavSearch = ({
  searchValue = '',
  handleSearch = () => { },
  placeholder = '',
  clearSearch = () => { },
}) => {
  return (
    <TextInput
      value={searchValue}
      onChange={(e) => handleSearch(e.target.value)}
      placeholder={placeholder}
      variant="outlined"
      sx={{ m: 1 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: palette?.background?.dark }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <Typography variant="body2" sx={{ color: palette?.text?.grey3 }}>
                Clear
              </Typography>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LeftNavSearch;
