import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { elementUpdated } from 'redux-layout/actions';

const ColorPicker = ({ children, elemId, type = 'color' }) => {
    const dispatch = useDispatch();
    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const [colorPicker, setColorPicker] = React.useState(type === 'svgcolor' ? elemId.childrens[0].fill : type === 'color' ? elemId.style.color : elemId.style.backgroundColor);

    useEffect(() => {
        setColorPicker(type === 'svgcolor' ? elemId.childrens[0].fill : type === 'color' ? elemId.style.color : elemId.style.backgroundColor);
    }, [elemId]);

    const styles = {
        color: {
            width: '20px',
            height: '14px',
            borderRadius: '2px',
            background: colorPicker,
        },
        swatch: {
            padding: '5px',
            cursor: 'pointer'
        },
        popover: {
            position: 'absolute',
            zIndex: '1000',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    };
    const colorChangeComplete = (color) => {
        if (elemId.id) {
            dispatch(elementUpdated(elemId, color.hex, type));
        }
    };
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        setColorPicker(color.hex);
    };
    return (
        <Box>
            <Box sx={styles.swatch} onClick={handleClick}>
                {children}
            </Box>
            {displayColorPicker ? (
                <Box sx={styles.popover}>
                    <Box sx={styles.cover} onClick={handleClose} />
                    <SketchPicker color={colorPicker} onChange={handleChange} onChangeComplete={colorChangeComplete} />
                </Box>
            ) : null}
        </Box>
    );
};

export default ColorPicker;
