import styled from '@emotion/styled';


export const Container = styled.div`
    background: rgba(0, 149, 105, 0.12);
    padding: 0 0.5rem;
    height: 1.8rem;
    width: 100%;
    position: relative;
    z-index: 1;
`;
