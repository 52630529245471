import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { CustomButton, TextInput } from './styles';
import { palette } from 'config/theme';
import { InsertIcon } from 'pages/studio/Components/SVGs';
import { Modal } from 'components/shared';
import { useSelector } from 'react-redux';

const Guidelines = ({
  previewText,
  setPreviewText,
  emit,
  setOpenSnackbar,
  loading,
  setLoading,
  setError,
  handleSlideInsert,
  openPreview,
  setOpenPreview,
  preview,
}) => {
  const { selectedSlide } = useSelector((state) => state.Canvas.present);

  const handleSlideModal = () => setOpenPreview(true);

  const handleSliderGenerate = () => {
    setLoading(true);
    try {
      emit(
        'pipeline:execute',
        JSON.stringify({
          slug: 'regenrate-slide',
          payload: {
            instructions: previewText,
            json: selectedSlide,
          },
        })
      );
      if (loading) {
        handleSlideModal();
      }
    } catch (e) {
      setLoading(false);
      setError('server error');
      setOpenSnackbar(true);
      console.log('regeneration text ERROR::', e);
    }
  };

  const renderSlideElements = () => {
    return preview?.elements?.map((element) => {
      switch (element.type) {
        case 'text':
          return (
            <Typography
              key={element.id}
              variant="h6"
              sx={{
                fontSize: element.style['font-size'],
                color: element.style.color,
                textAlign: element.style['text-align'],
                position: 'absolute',
                top: `${element.position.top}px`,
                left: `${element.position.left}px`,
                width: `${element.position.width}%`,
                height: `${element.position.height}px`,
              }}>
              {element.content.text}
            </Typography>
          );
        case 'img':
          return (
            <Box
              key={element.id}
              component="img"
              src={element.content.src}
              sx={{
                position: 'absolute',
                top: `${element.position.top}px`,
                left: `${element.position.left}px`,
                width: element.style.width,
                height: element.style.height,
              }}
              alt={element.name}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <>
      <Typography variant="body2" sx={{ color: palette?.text?.grey3, mt: 3, mb: 1 }}>
        Slide guidelines
      </Typography>
      <TextInput
        placeholder="Example: Recreate the slide with appropriate imagery"
        variant="outlined"
        multiline
        value={previewText}
        onChange={(e) => setPreviewText(e.target.value)}
        sx={{ mt: 2 }}
      />
      <CustomButton onClick={handleSliderGenerate}>{loading ? 'Loading...' : 'Preview'}</CustomButton>
      <Modal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        title="Slide Preview"
        aria-labelledby="slide-modal-title"
        aria-describedby="slide-modal-description"
        size="xl">
        <Box
          sx={{
            position: 'relative',
            width: '752px',
            height: '411px',
            backgroundColor: preview?.Meta?.Background,
            margin: '20px',
          }}>
          {renderSlideElements()}
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: '100%', mt: 2, p: 2 }}>
          <Button onClick={() => setOpenPreview(false)} color="secondary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" startIcon={<InsertIcon />} onClick={handleSlideInsert}>
            Insert
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default Guidelines;
