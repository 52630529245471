import axios from 'axios';
import appConfig from 'config';
import { cookieUtils } from './utils';

const http = axios.create({
    baseURL: appConfig.collection,
    timeout: 60 * 1000,
});

http.interceptors.request.use(
    (config) => {
        const user = cookieUtils.get('user');
        const userObj = user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
        if (userObj?.jwt) {
            config.headers['Authorization'] = 'Bearer ' + userObj.jwt;

        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    async (response) => {
        return response.data;
    },
    async (errorObj) => {
        const errorData = errorObj.response && errorObj.response.data;
        let { error, errors } = errorData;
        if (error) {
            console.debug(error);
            // message.error(error.message);
        }
        if (errors) {
            error = errors[0];
            console.debug('Multiple errors:');
            errors.forEach((err) => console.debug(err));
            // message.error(error.message);
        }
        // message.error(error.message);
        return Promise.reject(error);
    }
);

export default http;
