import styled from '@emotion/styled';
import { Box, Button, Switch, TextField } from '@mui/material';
import { palette } from 'config/theme';

export const Container = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  background: #ffffff
    ${
      '' /* &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #FFFFFF;
  } */
    };
`;

export const CustomButton = styled(Button)`
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8.1066C12px;
  border: 1px solid #595d62;
  background: transparent;
  color: #000;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
  &:hover {
    background-color: transparent;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const TextInput = styled(TextField)`
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;

  .MuiInputBase-input {
    height: 0px;
    min-height: 180px;
    padding: 8px 12px;
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
`;
