import React from 'react';
import Leftbar from './Leftbar';
import Topbar from './Topbar';
import { Box, Divider, Grid } from '@mui/material';
import Content from './Content';
import { useSelector } from 'react-redux';
import usePaginatedApi from 'components/hooks/usePaginationApi';

const Library = () => {
    const { user } = useSelector(state => state.Auth);
    const { data, count, loading, error, loadMore, searchItems, refreshData } = usePaginatedApi('projects', 15, {
        username: {
            $eq: user?.user?.username
        }
    });
    return (
        <Box>
            <Topbar />
            <Divider />
            <Grid container direction="row" wrap="nowrap">
                <Grid item xs={2}>
                    <Leftbar />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={10}>
                    <Content
                        user={user}
                        projects={data}
                        count={count}
                        loading={loading}
                        error={error}
                        loadMore={loadMore}
                        searchItems={searchItems}
                        refreshData={refreshData}
                    />
                </Grid>
            </Grid>

        </Box>
    );
};

export default Library;
