import styled from '@emotion/styled';


export const Container = styled.div`
    width: 100%;
    display:flex;
    flex-grow: 1;
    position: relative;
`;

export const CanvasBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    overflow: auto;
    position: relative;
`;
