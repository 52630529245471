import React, { useState } from 'react';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  CustomButton,
  CustomIconButton,
  CustomTypography,
  CustomStack,
  CustomMenu,
  CustomErrorMessage,
} from './styles';
import { ErrorMessage } from './styles';
import { palette } from 'config/theme';

const LifewiseLibrary = ({
  title,
  subTitle,
  PhotosData,
  elementLoading,
  elementError,
  elementLoadMore,
  elementCount,
  handleBackgroundImgAdd,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMenu, setIsMenu] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImage(index);
    setIsMenu(null);
  };

  const handleMoreClick = (event, index) => {
    event.stopPropagation();
    setIsMenu(event.currentTarget);
  };

  const handleClose = () => {
    setIsMenu(null);
  };

  const handleBackgroundSelection = (item, index) => {
    handleBackgroundImgAdd(item, index);
    handleImageClick(index);
  };

  return (
    <React.Fragment>
      <CustomStack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="body2" sx={{ color: palette?.background?.default }}>
          {title}
        </Typography>
        {subTitle ? <Button variant="seeAll">{subTitle}</Button> : null}
      </CustomStack>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {elementError ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {PhotosData.map((item, index) => (
                <Grid item key={index} xs={6}>
                  <CustomButton
                    onClick={() => handleBackgroundSelection(item, index)}
                    selected={selectedImage === index}>
                    {selectedImage === index && (
                      <CustomIconButton size="small" onClick={(event) => handleMoreClick(event, index)}>
                        <MoreHorizIcon />
                      </CustomIconButton>
                    )}
                    <img className="img-contain" src={`${baseUrl}${item?.url}`} alt={`${baseUrl}${item?.url}`} />
                  </CustomButton>
                </Grid>
              ))}
            </Grid>
            <CustomMenu anchorEl={isMenu} open={Boolean(isMenu)} onClose={handleClose}>
              <MenuItem onClick={handleClose}>Apply to All Slides</MenuItem>
              <MenuItem onClick={handleClose}>Download Asset</MenuItem>
              <MenuItem onClick={handleClose}>Delete Asset</MenuItem>
            </CustomMenu>
            {elementLoading ? (
              <CustomStack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1 }}>
                <Loader />
              </CustomStack>
            ) : PhotosData?.length === elementCount ? null : (
              <CustomStack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1 }}>
                <Button variant="backBtn" onClick={elementLoadMore}>
                  {'Load More'}
                </Button>
              </CustomStack>
            )}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default LifewiseLibrary;
