import styled from '@emotion/styled';
import { Box, Button, Checkbox, TextField } from '@mui/material';

export const AudioContainer = styled.div`
  padding: 12px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
`;

export const TextInput = styled(TextField)`
  border-radius: 8px;
  background: #fff;
  width: 100%;
  color: #595d62;
  font-size: 14px;
  font-weight: 400;
`;

export const TextBoxButton = styled(Button)`
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #009569;
  background: #009569;
  color: #fff;
  width: 100%;
  justify-content: center;

  &:hover {
    background-color: #009569;
  }

  & .MuiButton-label {
    text-align: center;
    width: 100%;
  }
`;

export const BoxContainer = styled(Box)`
  width: 100%;
  height: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  width: 100%;
  margin-top: 20px;
`;

export const CustomMusicButton = styled(Button)`
  background-color: #595d62;
  border-radius: 8px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  &:hover {
    background-color: #595d62;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  & .MuiCheckbox-root {
    padding: 0;
  }
`;

