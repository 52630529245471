import { updateData } from 'helpers/api.services';
import { PROJECT_LOADING } from './studio/constants';


const loggerMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type) {
        console.log('Dispatching:', action);
    }
    let result = next(action);
    const state = getState();
    if (action.type) {
        console.log('Next state:', state);
        if (action.type.includes('SLIDE') || action.type.includes('ELEMENT')) {
            updateProject(state, dispatch);
        } else if (action.type.includes('PROJECT_TITLE')) {
            updateProject(state, dispatch, 'title');
        }
        return result;
    }
};

async function updateProject(state, dispatch, type) {
    console.log('Project updated', state);
    dispatch({
        type: PROJECT_LOADING,
        payload: true,
    });
    if (type === 'title') {
        await updateData(`/projects/${state.Canvas.present.projectId}`, {
            title: state.Canvas.present.title
        });
    } else {
        await updateData(`/projects/${state.Canvas.present.projectId}`, {
            project: state.Canvas.present.project
        });
    }

    dispatch({
        type: PROJECT_LOADING,
        payload: false,
    });
}

export default loggerMiddleware;


