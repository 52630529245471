import styled from '@emotion/styled';
import { Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

export const Img = styled.img`
  ${'' /* width: 40px; */}
  aspect-ratio: 1;
  object-fit: contain;
`;

export const CustomStack = styled(Stack)`
  & > :not(style) ~ :not(style) {
    margin-top: 0;
  }
  background-color: ${({ isselected }) => (isselected ? '#262725' : '#000')};
  margin-top: 0;
  cursor: pointer;
`;

export const Trapezium = styled.div`
  width: 12px;
  height: 94px;
  background-color: #3498db;
  clip-path: polygon(0% 0%, 100% 20%, 100% 80%, 0% 100%);
  transform: translate(100%, -50%);
  position: absolute;
  right: 1%;
  top: 50%;
  background-color: #262725;
  color: #fff;
  svg {
    position: absolute;
    top: 37%;
    right: -50%;
  }
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '4px',
    padding: '12px',
    boxShadow: theme.shadows[1],
  },
  '& .MuiTooltip-arrow': {
    color: '#fff',
  },
}));

export const LeftElements = styled(Stack)`
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-top: 10px;
   &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #191b18;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #191b18;
  }
  `;
