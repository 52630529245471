import { IconButton, Stack, Typography } from '@mui/material';
import { palette } from 'config/theme';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const LeftNavHeader = ({ elementType = '', setElementType = () => {}, handleBack = () => {} }) => {
  function capitalizeFirstWord(text) {
    if (typeof text !== 'string' || text.length === 0) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  const getDisplayText = (text) => {
    if (text === 'YOURUPLOADS' || text === 'BACKGROUNDUPLOADS') {
      return 'Your Uploads';
    }
    return capitalizeFirstWord(text);
  };
  

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton onClick={() => handleBack()} sx={{ padding: '5px' }}>
          <ArrowBackIcon sx={{ color: palette?.background?.default }} />
        </IconButton>
        <Typography variant="body2" sx={{ color: palette?.background?.default }}>
          {`${getDisplayText(elementType)}`}
        </Typography>
      </Stack>
      <IconButton onClick={() => setElementType('')}>
        <CloseIcon sx={{ color: palette?.background?.default }} />
      </IconButton>
    </Stack>
  );
};

export default LeftNavHeader;
