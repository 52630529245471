import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #000;
`;


export const ManualContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height:75%;
    overflow-y:auto;
    overflow-x:hidden;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E2E2E2;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    } 
`;

export const GridContainer = styled(Grid)`
    height:90%;
    overflow-y:auto;
    overflow-x:hidden;
    padding: 1rem;
     &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #E2E2E2;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    } 
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 50vw;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
`;
