import React, { useState } from 'react';
import { AudioContainer } from './style';
import { Button, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ErrorMessage } from 'formik';
import { baseUrl } from 'helpers/apiCalls';
import Loader from 'pages/studio/Components/Loader';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import LeftNavHeader from 'pages/studio/Components/LeftNavHeader';
import MusicButton from './MusicButton';

const AllUploadedAudio = ({ elementType, setElementType, error, loading, data, count }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  const handleMusicButtonClick = (index) => {
    setSelectedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // const elementConfigs = {
  //   PHOTOUPLOADS: { api: 'assets', params: {} },
  // };
  // const { api, params: apiParams } = elementConfigs[elementType] || { api: '', params: {} };
  // const { data, loading, error, loadMore, searchItems, count } = usePaginatedApi(api, 16, apiParams);
  const handleBack = () => {
    setElementType('AUDIO');
  };
  return (
    <AudioContainer>
      <LeftNavHeader elementType={elementType} setElementType={setElementType} handleBack={handleBack} />
      <Grid container spacing={2} sx={{ p: 2 }}>
        {error ? (
          <Grid item xs={12}>
            <ErrorMessage>Failed to load elements. Please try again.</ErrorMessage>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              {[1,2,3,4,5,6].map((item, index) => {
                const isSelected = selectedItemIndex === index;
                return (
                  <Grid item key={index} xs={12}>
                    <MusicButton
                      isChecked={isSelected}
                      onClick={() => handleMusicButtonClick(index)}
                      label="Music Name"
                    />
                  </Grid>
                );
              })}
            </Grid>
            {loading ? (
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1, width: '100%' }}>
                <Loader />
              </Stack>
            ) : (
              <>
                {data?.length === count ? null : (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 1, width: '100%' }}>
                    <Button variant="backBtn" onClick={loadMore}>
                      {'Load More'}
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </React.Fragment>
        )}
      </Grid>
    </AudioContainer>
  );
};

export default AllUploadedAudio;
