import { Box, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 108px;
    height: 104px;
    border: 1px dashed #595D62;
    border-radius: 8px;
    background: #E2E2E2;
    cursor: pointer;
    color: #595D62;
    font-size: 12px;
    font-weight: 700;
`;

const UploadBox = () => {
    return (
        <Container>
            <AddIcon />
            <Typography>Upload</Typography>
        </Container>
    );
};

export default UploadBox;
