import styled from '@emotion/styled';
import { Button } from '@mui/material';



export const CustomButton = styled(Button)`
  background-color: #e2e2e2;
  border-radius: 4px;
  color: #000000;
  margin-right: 10px;
  padding: 10px;
`;