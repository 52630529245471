import React from 'react';
import { Stack } from '@mui/material';
import { SvgIcon } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { CustomCheckbox, CustomMusicButton } from './style';

const CircleUnchecked = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="2" fill="none" />
  </SvgIcon>
);

const CircleChecked = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10" stroke="green" strokeWidth="2" fill="green" />
    <path d="M9 12.5l2 2 4-4" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);
const MusicButton = ({ isChecked, onClick, label }) => {
  return (
    <CustomMusicButton onClick={onClick}>
      <Stack direction={'row'} alignItems={'center'}>
        <CustomCheckbox icon={<CircleUnchecked />} checkedIcon={<CircleChecked />} checked={isChecked} />
        <span>{label}</span>
      </Stack>
      {isChecked ? (
        <PauseIcon sx={{ color: 'palette.background.default' }} />
      ) : (
        <PlayArrowIcon sx={{ color: 'palette.background.default' }} />
      )}
    </CustomMusicButton>
  );
};

export default MusicButton;
