import React, { useState, useEffect, forwardRef, useRef } from 'react';
import TextToolbar from './TextToolbar';

const ResizableDraggable =
  ({
    children,
    onDragStop,
    onResizeStop,
    position: initialPosition,
    size: initialSize,
    className,
    style,
    onMouseDownEvent,
    onContextMenu,
    id,
    isVisible,
    handleContextMenu,
    element,
    disableToolbar = false,
    textRef,

  }) => {
    console.log('initialSize', initialSize, element.content.text);
    const [isDragging, setIsDragging] = useState(false);
    const [resizing, setResizing] = useState(null);
    const [position, setPosition] = useState(initialPosition || { x: 100, y: 100 });
    const [size, setSize] = useState({
      width: initialSize?.width || '200px',
      height: initialSize?.height || '200px',
    });
    const isRotated = useRef(false);
    const rotation = useRef({ x: 0, y: 0 });
    const [angle, setAngle] = useState(0);
    const ref = useRef({});

    const handleMouseDownRotate = (e) => {
      const targetElement = e.target.classList.contains('text-rotate') ? e.target : e.target.closest('.text-rotate');
      if (e.target.classList.contains('text-rotate') || e.target.closest('.text-rotate')) {
        isRotated.current = true;
        const rect = targetElement.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        rotation.current = {
          x: centerX,
          y: centerY,
        };
      }
    };

    const handleMouseDownDrag = (e) => {
      onMouseDownEvent();
      if (
        e.target.classList.contains('resizable-draggable') ||
        e.target.classList.contains('rsw-editor') ||
        e.target.classList.contains('text-move') ||
        e.target.closest('.text-move')
      ) {
        setIsDragging(true);
        ref.current = {
          offsetX: e.clientX - position.x,
          offsetY: e.clientY - position.y,
        };
      }
    };

    const handleMouseMove = (e) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - ref.current.offsetX,
          y: e.clientY - ref.current.offsetY,
        });
      }
      if (resizing) {
        const deltaX = e.clientX - ref.current.startX;
        const deltaY = e.clientY - ref.current.startY;

        let newSize = { ...size };
        let newPosition = { ...position };
        switch (resizing) {
          case 'right':
            // console.log('refCurrentScroll', dragResizingActive);
            // console.log('refCurrentScroll height', dragResizingActive.offsetHeight, dragResizingActive.scrollHeight);
            // console.log('refCurrentScroll width', dragResizingActive.offsetWidth, dragResizingActive.scrollWidth);
            newSize.width = `${ref.current.startWidth + deltaX}px`;
            // let contentRight = textRef.current.innerHTML;
            // contentRight = contentRight.replace(/\n/g, '<br>');
            // textRef.current.innerHTML = contentRight;
            newSize.height = `${textRef.current.offsetHeight + 24}px`;
            break;
          // case 'bottom':
          //   newSize.height = `${ref.current.startHeight + deltaY}px`;
          //   break;
          case 'bottom-right':
            let newFsBottomRight = ((ref.current.startHeight + deltaY) / ref.current.startHeight) * ref.current.startFontSize;
            textRef.current.style.fontSize = `${newFsBottomRight}px`;
            newSize.width = `${textRef.current.offsetWidth + 24}px`;
            newSize.height = `${textRef.current.offsetHeight + 24}px`;
            ref.current.finalFontSize = newFsBottomRight;
            break;
          case 'left':
            newSize.width = `${ref.current.startWidth - deltaX}px`;
            // let contentLeft = textRef.current.innerHTML;
            // contentLeft = contentLeft.replace(/\n/g, '<br>');
            // textRef.current.innerHTML = contentLeft;
            newSize.height = `${textRef.current.offsetHeight + 24}px`;
            newPosition.x = ref.current.startXPos + deltaX;
            break;
          // case 'top':
          //   newSize.height = `${ref.current.startHeight - deltaY}px`;
          //   newPosition.y = ref.current.startYPos + deltaY;
          //   break;
          case 'top-left':
            let newFsTopLeft = ((ref.current.startHeight) / (ref.current.startHeight + deltaY)) * ref.current.startFontSize;
            textRef.current.style.fontSize = `${newFsTopLeft}px`;
            newSize.width = `${textRef.current.offsetWidth + 24}px`;
            newSize.height = `${textRef.current.offsetHeight + 24}px`;
            let textTLDeltaX = ref.current.startTextOffsetWidth - textRef.current.offsetWidth;
            let textTLDeltaY = ref.current.startTextOffsetHeight - textRef.current.offsetHeight;
            newPosition.x = ref.current.startXPos + textTLDeltaX;
            newPosition.y = ref.current.startYPos + textTLDeltaY;
            ref.current.finalFontSize = newFsTopLeft;
            break;
          case 'top-right':
            let newFsTopRight = ((ref.current.startHeight) / (ref.current.startHeight + deltaY)) * ref.current.startFontSize;
            textRef.current.style.fontSize = `${newFsTopRight}px`;
            newSize.width = `${textRef.current.offsetWidth + 24}px`;
            newSize.height = `${textRef.current.offsetHeight + 24}px`;
            let textTRDeltaY = ref.current.startTextOffsetHeight - textRef.current.offsetHeight;
            newPosition.y = ref.current.startYPos + textTRDeltaY;
            ref.current.finalFontSize = newFsTopRight;
            break;
          case 'bottom-left':
            let newFsBottomLeft = ((ref.current.startHeight + deltaY) / ref.current.startHeight) * ref.current.startFontSize;
            textRef.current.style.fontSize = `${newFsBottomLeft}px`;
            newSize.width = `${textRef.current.offsetWidth + 24}px`;
            newSize.height = `${textRef.current.offsetHeight + 24}px`;
            let textBLDeltaX = ref.current.startTextOffsetWidth - textRef.current.offsetWidth;
            newPosition.x = ref.current.startXPos + textBLDeltaX;
            ref.current.finalFontSize = newFsBottomLeft;
            break;
          default:
            break;
        }
        setSize(newSize);
        setPosition(newPosition);
      }
      if (isRotated.current) {
        const deltaX = e.clientX - rotation.current.x;
        const deltaY = e.clientY - rotation.current.y;
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
        setAngle(angle);
      }
    };

    const handleMouseUp = (e) => {
      if (isDragging) {
        setIsDragging(false);
        if (onDragStop) {
          onDragStop(e, position);
        }

      } else if (resizing) {
        setResizing(null);
        if (onResizeStop) {
          onResizeStop(e, resizing, ref, null, position);
        }

      } else if (isRotated.current) {
        isRotated.current = false;
      }
    };

    const handleMouseDownResize = (e, direction) => {
      // e.stopPropagation();
      setResizing(direction);
      ref.current = {
        startX: e.clientX,
        startY: e.clientY,
        startWidth: ref.current.width,
        startHeight: ref.current.height,
        startXPos: position.x,
        startYPos: position.y,
        startFontSize: parseFloat(textRef.current.style.fontSize.replace('px', '')),
        // startFontSize: parseFloat(element?.style?.['font-size']),
        startTextOffsetWidth: textRef.current.offsetWidth,
        startTextOffsetHeight: textRef.current.offsetHeight,
      };
    };

    useEffect(() => {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isDragging, resizing, position, size]);

    useEffect(() => {
      setSize({
        width: initialSize?.width || '200px',
        height: initialSize?.height || '200px',
      });
    }, [initialSize]);

    useEffect(() => {
      const element = document.getElementById(id);
      ref.current.width = element.clientWidth;
      ref.current.height = element.clientHeight;
    }, [size]);

    return (
      <div
        className={`resizable-draggable edit-textarea ${className} ${resizing === 'bottom-left' || resizing === 'top-right' || resizing === 'bottom-right' || resizing === 'top-left' ? 'text-wrap' : ''}`}
        style={{
          ...style,
          width: size.width,
          height: size.height,
          transform: `translate(${position.x}px, ${position.y}px) rotate(${angle}deg)`,
          position: 'absolute',
          border: '12px solid transparent',
          // whiteSpace: 'normal',
          // wordBreak: 'normal',
        }}
        onMouseDown={handleMouseDownDrag}
        onContextMenu={onContextMenu}
        ref={ref}
        id={id}>
        {children}
        <div className="resizer top-left" onMouseDown={(e) => handleMouseDownResize(e, 'top-left')} />
        {/* <div className="resizer top" onMouseDown={(e) => handleMouseDownResize(e, 'top')} /> */}
        <div className="resizer top-right" onMouseDown={(e) => handleMouseDownResize(e, 'top-right')} />
        <div className="resizer right" onMouseDown={(e) => handleMouseDownResize(e, 'right')} />
        <div className="resizer bottom-right" onMouseDown={(e) => handleMouseDownResize(e, 'bottom-right')} />
        {/* <div className="resizer bottom" onMouseDown={(e) => handleMouseDownResize(e, 'bottom')} /> */}
        <div className="resizer bottom-left" onMouseDown={(e) => handleMouseDownResize(e, 'bottom-left')} />
        <div className="resizer left" onMouseDown={(e) => handleMouseDownResize(e, 'left')} />
        {disableToolbar ? null : (
          <TextToolbar
            isVisible={isVisible}
            element={element}
            handleContextMenu={handleContextMenu}
            handleMouseDownDrag={handleMouseDownDrag}
            handleMouseDownRotate={handleMouseDownRotate}
          />
        )}
      </div>
    );
  };


export default ResizableDraggable;
