import { Box, Button, Stack, Typography } from '@mui/material';
import usePaginatedApi from 'components/hooks/usePaginationApi';
import React, { useContext, useEffect } from 'react';
import ElementShapes from './elementShapes';
import { StudioContext } from 'pages/studio';
import { palette } from 'config/theme';

const elementConfigs = {
    SHAPES: { api: 'svgs', params: {}, paginationType: 'default' },
    BUTTONS: { api: 'assets', params: { contenttype: { $eq: 'button' } }, paginationType: 'custom' },
    ICONS: { api: 'assets', params: { contenttype: { $eq: 'icon' } }, paginationType: 'custom' },
    STICKERS: { api: 'assets', params: { contenttype: { $eq: 'image' } }, paginationType: 'custom' },
    GIFS: { api: 'assets', params: { contenttype: { $eq: 'gif' } }, paginationType: 'custom' },
    FRAMES: { api: 'frames', params: {}, paginationType: 'manual' },
};

function capitalizeFirstWord(text) {
    if (typeof text !== 'string' || text.length === 0) { return text; }
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

const Contents = ({ elementsSearch }) => {
    const { setElementType } = useContext(StudioContext);

    // useEffect(() => {
    //     setElementType('');
    // }, [elementsSearch]);

    const renderElementSection = (title, elementType, elements, boxWidth, boxHeight, loading, error, loadMore, searchItems, elementsSearch) => (
        <div style={{ marginBottom: '16px' }}>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                justifyContent="space-between"
                spacing={1}>
                <Typography variant="body2" color={palette.background.paper}>
                    {capitalizeFirstWord(title)}
                </Typography>
                <Button variant="seeAll" onClick={() => { setElementType(elementType); }}>
                    See all
                </Button>
            </Stack>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                spacing={1}
                sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                <ElementShapes
                    elements={elements}
                    boxWidth={boxWidth}
                    boxHeight={boxHeight}
                    loading={loading}
                    loadMore={loadMore}
                    elementType={elementType}
                    setElementType={setElementType}
                    searchItems={searchItems}
                    elementsSearch={elementsSearch}
                />
            </Stack>
            {error && (
                <Typography variant="body2" color="error">
                    Error loading elements
                </Typography>
            )}
        </div>
    );
    return (
        <Box>
            {Object.keys(elementConfigs).map((key) => {
                const { api, params, paginationType } = elementConfigs[key];
                const { data, loading, error, loadMore, searchItems } = usePaginatedApi(api, 12, {
                    ...params,
                    name: { $contains: elementsSearch }
                }, paginationType);

                const formattedData = data.map((item) => {
                    if (item?.attributes?.code) {
                        return item.attributes;
                    } else if (item?.attributes?.asset?.data?.[0]?.attributes) {
                        return item.attributes.asset.data[0].attributes;
                    } else {
                        return item.attributes;
                    }
                });

                return (
                    <React.Fragment key={`${key}-${elementsSearch}`}>
                        {formattedData?.length !== 0 && renderElementSection(
                            key, // title
                            key, // elementType
                            formattedData,
                            68, // Adjust this based on your requirements
                            68, // Adjust this based on your requirements
                            loading,
                            error,
                            loadMore,
                            searchItems,
                            elementsSearch
                        )}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

export default Contents;
